import React from 'react';
import { Button, Form, Input, Col, Row, DatePicker } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { serviceUpdate } from 'providers/ServiceProvider/actions';

const SalonCloseDateAddForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  
  return (
    <Form
        form={form}
        layout="vertical"
        name="serviceUpdateForm"
        onFinish={values => 
          console.log(values)
          // dispatch(serviceUpdate(
          //   {
          //     id: service.id, 
          //     values,
          //   })).then(()=> onCancel())
        }
        initialValues={{}}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Name" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Service Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Button type={'primary'} block ghost>One day</Button>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Button type={'primary'} block>Several days</Button>
          </Col>
        </Row>
        <Row gutter={24} type="flex" style={{marginTop: 24}}>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="fromDate" 
              label="Frome Date"
              rules={[
                {
                  required: true,
                  message: 'Please input the booking Date!'
                }
              ]}>
              <DatePicker style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="toDate" 
              label="To Date"
              rules={[
                {
                  required: true,
                  message: 'Please input the booking Date!'
                }
              ]}>
              <DatePicker style={{ width: 200 }} />
            </Form.Item>
          </Col>
        </Row>
        
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default SalonCloseDateAddForm;