import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import './styles.less';
import logo from 'assets/images/logo-c2c.png';
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  
  return (
    <div className="login-page">
      <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="login-page-row" >
        <Col xs={{ span: 24 }}>
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 46 }} />
            <h3 className="login-title">Controller Board</h3>
          </div>
          <div>
            <Button type="primary" onClick={() => {
              loginWithRedirect()
            }} className="login-form-button">
              Log in
            </Button>
            <Form className="login-form"> 
              <Form.Item className="login-form-request" style={{marginTop: 10}}>
                <a href="/request-account">
                  Request account
                    </a>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;