import React, { useEffect, useState } from 'react';
import { NavBar, TabBar, Icon, SearchBar, Button, WhiteSpace, WingBlank, List, Tabs, Badge  } from 'antd-mobile';
import { Avatar } from 'antd';
import './styles.less';
import logo from 'assets/images/logo-c2c.png';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import 'antd-mobile/dist/antd-mobile.less'

import { MenuOutlined, ScheduleOutlined, PayCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const Item = List.Item;
const Brief = Item.Brief;

const MySalonApp = () => {
  let navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('bookingTab')
  
  const tabs = [
    { title: <Badge text={'3'}>First Tab</Badge> },
    { title: <Badge text={'今日(20)'}>Second Tab</Badge> },
    { title: <Badge dot>Third Tab</Badge> },
  ];

  return (
    <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0}}>
      <TabBar
          unselectedTintColor="#949494"
          tintColor="#33A3F4"
          barTintColor="white"
          tabBarPosition="bottom"
        >
          <TabBar.Item
            title="Booking"
            key="Booking"
            icon={<ScheduleOutlined style={{fontSize: 22}} />}
            selectedIcon={<ScheduleOutlined style={{fontSize: 22, color: '#06adef'}} />}
            selected={selectedTab === 'bookingTab'}
            badge={5}
            onPress={() => setSelectedTab('bookingTab')}
          >
            <NavBar 
              mode="light"
              icon={<MenuOutlined />}
              onLeftClick={() => console.log('onLeftClick')}
              rightContent={[
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              ]}>
                <div className="login-logo">
                  <img src={logo} alt="logo" style={{height: 26}} />
                </div>
            </NavBar>
            <SearchBar placeholder="Search" maxLength={8} cancelText="Cancel"  />
            <WhiteSpace />
          </TabBar.Item>
          <TabBar.Item
            title="Payment"
            key="Payment"
            icon={<PayCircleOutlined style={{fontSize: 22}} />}
            selectedIcon={<PayCircleOutlined style={{fontSize: 22, color: '#06adef'}} />}
            selected={selectedTab === 'paymentTab'}
            onPress={() => setSelectedTab('paymentTab')}
          >
            Payment
          </TabBar.Item>
          <TabBar.Item
            title="Staff"
            key="staff"
            icon={<UsergroupAddOutlined style={{fontSize: 22}} />}
            selectedIcon={<UsergroupAddOutlined style={{fontSize: 22, color: '#06adef'}} />}
            selected={selectedTab === 'staffTab'}
            onPress={() => setSelectedTab('staffTab')}
          >
            <NavBar 
              mode="light"
              icon={<MenuOutlined />}
              onLeftClick={() => console.log('onLeftClick')}
              rightContent={[
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              ]}>
                <div className="login-logo">
                  <img src={logo} alt="logo" style={{height: 26}} />
                </div>
            </NavBar>
            <Tabs tabs={tabs}
              initialPage={0}
              onChange={(tab, index) => { console.log('onChange', index, tab); }}
              onTabClick={(tab, index) => { console.log('onTabClick', index, tab); }}
            >
              <div>
              <SearchBar placeholder="Search" maxLength={8} cancelText="Cancel"  />
            <List renderHeader={() => 'Total: 20 staffs'}>
              <Item
                thumb="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                arrow="horizontal"
                multipleLine
                onClick={() => {}}
              >Nailist 3<Brief>Nailie <br /> staff1@gmail.com <br /> 0976123423</Brief>
              </Item>
              <Item
                thumb="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                arrow="horizontal"
                multipleLine
                onClick={() => {}}
              >Nailist 2<Brief>Nailie <br /> staff1@gmail.com <br /> 0976123423</Brief>
              </Item>
            </List>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px', backgroundColor: '#fff' }}>
                Content of second tab
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px', backgroundColor: '#fff' }}>
                Content of third tab
              </div>
            </Tabs>

            

          </TabBar.Item>
          <TabBar.Item
            icon={
              <div style={{
                width: '22px',
                height: '22px',
                background: 'url(https://gw.alipayobjects.com/zos/rmsportal/BTSsmHkPsQSPTktcXyTV.svg) center center /  21px 21px no-repeat' }}
              />
            }
            selectedIcon={
              <div style={{
                width: '22px',
                height: '22px',
                background: 'url(https://gw.alipayobjects.com/zos/rmsportal/ekLecvKBnRazVLXbWOnE.svg) center center /  21px 21px no-repeat' }}
              />
            }
            title="Notification"
            key="notification"
            badge={'99+'}
            selected={selectedTab === 'notificationTab'}
            onPress={() => setSelectedTab('notificationTab')}
          >
            Notification
          </TabBar.Item>
          
        </TabBar> 
    
    </div>
  );
};

export default MySalonApp;