import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryList, categoryDelete, categoryDetail, categoryOrder } from 'providers/CategoryProvider/actions';

import { serviceDetail, serviceDelete, serviceOrder } from 'providers/ServiceProvider/actions';
import { Layout, Button, Breadcrumb, Table, Card, Row, Col, Modal, Spin, Badge} from 'antd';
import { MenuOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import CategoryCreateForm from 'components/Desktop/Category/CategoryCreateForm';
import CategoryUpdateForm from 'components/Desktop/Category/CategoryUpdateForm';

import ServiceAddForm from 'components/Desktop/Services/ServiceAddForm';
import ServiceUpdateForm from 'components/Desktop/Services/ServiceUpdateForm';

import MenuSliderLeft from 'components/Desktop/Salon/MenuSliderLeft';

import './styles.less';
import { formatYen } from 'utils/stringFormat';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));


const SortableCategoryItem = sortableElement(({value, confirmCategoryDelete, openUpdateCategoryModal, setIsCreateServiceModal, openUpdateServiceModal, confirmServiceDelete}) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState(()=> {
    return value?.services.map((item, index) => {
      const itemNew = {...item, index, key: item.id}
      return itemNew
    })
  });
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <div style={{paddingLeft: 45}}><DragHandle /></div>,
    },
    {
      title: 'Name',
      className: 'drag-visible',
      render: (record) => (
        <div>
          { record.active === true ? 
            <span>{record.name}</span>
            :
            <span>{record.name} <Badge count={'Inactive'} style={{backgroundColor: 'gray'}} /></span>
          } 
        </div>
      )
    },
    {
      title: 'Duration',
      width: '20%',
      render: (record) => (
        <div>
          <span>{record.duration} min</span>
        </div>
      )
    },
    {
      title: 'Price',
      width: '25%',
      render: (record) => (
        <div>
          <span>{formatYen(record.price)}</span>
        </div>
      )
    },
    {
      title: '',
      width: '3%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button danger type="link" onClick={() => confirmServiceDelete(record.key)}>Delete</Button>
          <Button type="link" 
            onClick={()=> 
              openUpdateServiceModal(record)
            }>Edit</Button>
        </div>
      )
    }      
  ];
  
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);
  const DragableBodyRow = ({ index, className, style, ...restProps }) => {
    return (
      <SortableItem index={restProps['data-row-key']} {...restProps} />
    )
  };
  
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      dispatch(serviceOrder({oldItem: dataSource[oldIndex], newItem: dataSource[newIndex]}))
      .then(()=> console.log('Order'))
    }
    
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  
  return (
    <div>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{background: '#fafafa', marginTop: 1, marginLeft: 1, marginRight: 28}}>
      <Col className="gutter-row" span={1}>
        <MenuOutlined style={{ cursor: 'pointer', color: '#999', marginTop: 10 }} />
      </Col>
      <Col className="gutter-row" span={11}>
        { value.active === true ? 
            <div style={{ marginTop: 4, fontWeight: 'bold' }}>{value.name}</div>
            :
            <div style={{ marginTop: 4, fontWeight: 'bold' }}>{value.name} <Badge count={'Inactive'} style={{backgroundColor: 'gray'}} /></div>
        } 
        
      </Col>
      <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
        <Button danger type="link" onClick={()=> confirmCategoryDelete(value.id)}><strong>Delete</strong></Button>
        <Button 
          type="link" 
          onClick={()=> {
            openUpdateCategoryModal(value.id)
          }}
        >
          <strong>Edit</strong>
        </Button>
        <Button type="link" icon={<PlusOutlined />} onClick={() => setIsCreateServiceModal(true, value.id)} ><strong> Add Service</strong></Button>
      </Col>
    </Row>
    {
      dataSource.length > 0 && 
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{marginLeft: -45}}>
      <Col className="gutter-row" span={24} style={{paddingLeft: 45, paddingRight: 45}}>
        <Table
          pagination={false}
          showHeader={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DragableBodyRow,
            },
          }}
        />
      </Col>
    </Row>
    }
    </div>
  )}
);

const SortableCategory = sortableContainer(({items, confirmCategoryDelete, openUpdateCategoryModal, setIsCreateServiceModal, openUpdateServiceModal, confirmServiceDelete}) => {
  return (
    <ul>
      {items && items.map((value, index) => (
        <SortableCategoryItem 
          key={`service-${value.id}`} 
          index={index} 
          value={value} 
          confirmCategoryDelete={confirmCategoryDelete}
          openUpdateCategoryModal={openUpdateCategoryModal}
          setIsCreateServiceModal={setIsCreateServiceModal} 
          openUpdateServiceModal={openUpdateServiceModal} 
          confirmServiceDelete={confirmServiceDelete}
        />
      ))}
    </ul>
  );
});

const Services = () => {
  const dispatch = useDispatch();
  
  const [categoryId, setCategoryId] = useState();
  
  const {
    categories = [],
    loading
  } = useSelector((state) => state.category);

  const { salon } = useSelector(state => state.salon);

  useEffect(() => {
    dispatch(categoryList());
  }, []);

  //Category
  const [isCreateCategoryModal, setIsCreateCategoryModal] = useState(false);
  const [isUpdateCategoryModal, setIsUpdateCategoryModal] = useState(false);
  const openUpdateCategoryModal = (categoryId) => {
    const category = categories.find(item => item.id === categoryId);
    dispatch(categoryDetail(category));
    setIsUpdateCategoryModal(true);
  };

  const confirmCategoryDelete = (id) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete the category?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(categoryDelete(id));
        console.log('categoryDelete')
      },
      onCancel: () =>console.log('Close')
    });
  }

  //Service
  const [isCreateServiceModal, setIsCreateServiceModal] = useState(false);
  const [isUpdateServiceModal, setIsUpdateServiceModal] = useState(false);
  const openUpdateServiceModal = (service) => {
    dispatch(serviceDetail(service));
    setIsUpdateServiceModal(true);
  };
  const confirmServiceDelete = (id) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete the service?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(serviceDelete(id));
      },
      onCancel: () => console.log('Close')
    });
  }

  const onSortEndCategory = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      dispatch(categoryOrder({oldItem: categories[oldIndex], newItem: categories[newIndex]}))
      .then(()=> console.log('Order'))
    }
  };
  
  return (
    <Layout className="service-page">

      <MenuSliderLeft salon={salon} menuItem="2" />
      
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon">My Salon</a></Breadcrumb.Item>
          <Breadcrumb.Item>Services</Breadcrumb.Item>
        </Breadcrumb>
        <div className='service-page'>
          <Card style={{ marginBottom: 18 }}>
            <Card style={{ marginBottom: 24, backgroundColor: '#F8F8F8' }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                <Col className="gutter-row" span={12}>
                  <h2>Services</h2>
                </Col>
                <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
                  <Button 
                    type="primary" 
                    icon={<PlusOutlined />} 
                    onClick={()=> {
                      setIsCreateCategoryModal(true)
                    }} 
                  >Add Category</Button>
                </Col>
              </Row>
            </Card>
            {loading === true? 
              <div style={{textAlign: 'center'}}><Spin /></div>
              :
              <SortableCategory 
                items={categories} 
                onSortEnd={onSortEndCategory}  
                openUpdateCategoryModal={openUpdateCategoryModal}
                confirmCategoryDelete={confirmCategoryDelete}

                setIsCreateServiceModal={(visible, categoryId) => {
                  setCategoryId(categoryId)
                  setIsCreateServiceModal(visible)
                }} 
                openUpdateServiceModal={openUpdateServiceModal} 
                confirmServiceDelete={confirmServiceDelete}
              />
            }
          </Card>
          
          <Modal
              visible={isCreateCategoryModal}
              title="Add Category"
              onCancel={() => setIsCreateCategoryModal(false)}
              destroyOnClose={true}
              width={980}
              centered
              footer={null}
            >  
            <CategoryCreateForm 
              onCancel={() => setIsCreateCategoryModal(false)}
            />
          </Modal>
          <Modal
            visible={isUpdateCategoryModal}
            title="Update Category"
            onCancel={() => setIsUpdateCategoryModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
            <CategoryUpdateForm onCancel={()=> setIsUpdateCategoryModal(false)}  />
          </Modal>
          <Modal
              visible={isCreateServiceModal}
              title="Add Service"
              onCancel={() => setIsCreateServiceModal(false)}
              destroyOnClose={true}
              width={980}
              centered
              footer={null}
            >  
            <ServiceAddForm onCancel={() => setIsCreateServiceModal(false)} categoryId={categoryId} />
          </Modal>
          <Modal
            visible={isUpdateServiceModal}
            title="Update Service"
            onCancel={() => setIsUpdateServiceModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
            <ServiceUpdateForm onCancel={()=> setIsUpdateServiceModal(false)}  />
          </Modal>
        </div>
      </Layout>
    </Layout>
  )
};

export default Services;