//FETCH STAFF LIST
export const FETCH_STAFF_LIST = 'FETCH_STAFF_LIST';
export const FETCH_STAFF_LIST_SUCCESS = 'FETCH_STAFF_LIST_SUCCESS';
export const FETCH_STAFF_LIST_FAIL = 'FETCH_STAFF_LIST_FAIL';

//FETCH STAFF DETAIL
export const FETCH_STAFF_DETAIL = 'FETCH_STAFF_DETAIL';
export const FETCH_STAFF_DETAIL_SUCCESS = 'FETCH_STAFF_DETAIL_SUCCESS';
export const FETCH_STAFF_DETAIL_FAIL = 'FETCH_STAFF_DETAIL_FAIL';

//ADD STAFF
export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'ADD_STAFF_FAIL';

//UPDATE STAFF PROFILE
export const UPDATE_STAFF_PROFILE = 'UPDATE_STAFF_PROFILE';
export const UPDATE_STAFF_PROFILE_SUCCESS = 'UPDATE_STAFF_PROFILE_SUCCESS';
export const UPDATE_STAFF_PROFILE_FAIL = 'UPDATE_STAFF_PROFILE_FAIL';

//FETCH BANK NAME
export const FETCH_LIST_BANK_NAME = 'FETCH_LIST_BANK_NAME';
export const FETCH_LIST_BANK_NAME_SUCCESS = 'FETCH_LIST_BANK_NAME_SUCCESS';
export const FETCH_LIST_BANK_NAME_FAIL = 'FETCH_LIST_BANK_NAME_FAIL';

//FETCH BANK BRANCH 
export const FETCH_LIST_BANK_BRANCH = 'FETCH_LIST_BANK_BRANCH';
export const FETCH_LIST_BANK_BRANCH_SUCCESS = 'FETCH_LIST_BANK_BRANCH_SUCCESS';
export const FETCH_LIST_BANK_BRANCH_FAIL = 'FETCH_LIST_BANK_BRANCH_FAIL';

//STAFF_DETAIL
export const STAFF_DETAIL_CATEGORIES_SERVICES = 'STAFF_DETAIL_CATEGORIES_SERVICES';
export const STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS = 'STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS';
export const STAFF_DETAIL_CATEGORIES_SERVICES_FAIL = 'STAFF_DETAIL_CATEGORIES_SERVICES_FAIL';

export const STAFF_DETAIL_ADD_SERVICES = 'STAFF_DETAIL_ADD_SERVICES';
export const STAFF_DETAIL_ADD_SERVICES_SUCCESS = 'STAFF_DETAIL_ADD_SERVICES_SUCCESS';
export const STAFF_DETAIL_ADD_SERVICES_FAIL = 'STAFF_DETAIL_ADD_SERVICES_FAIL';

//STAFF INVITATION MANAGEMENT
export const STAFF_SEND_INVITE = 'STAFF_SEND_INVITE';
export const STAFF_SEND_INVITE_SUCCESS = 'STAFF_SEND_INVITE_SUCCESS';
export const STAFF_SEND_INVITE_FAIL = 'STAFF_SEND_INVITE_FAIL';

export const STAFF_CANCEL_INVITE = 'STAFF_CANCEL_INVITE';
export const STAFF_CANCEL_INVITE_SUCCESS = 'STAFF_CANCEL_INVITE_SUCCESS';
export const STAFF_CANCEL_INVITE_FAIL = 'STAFF_CANCEL_INVITE_FAIL';

export const STAFF_DETAIL = 'STAFF_DETAIL';
export const STAFF_DETAIL_SUCCESS = 'STAFF_DETAIL_SUCCESS';
export const STAFF_DETAIL_FAIL = 'STAFF_DETAIL_FAIL';

export const STAFF_SCHEDULE_LIST = 'STAFF_SCHEDULE_LIST';
export const STAFF_SCHEDULE_LIST_SUCCESS = 'STAFF_SCHEDULE_LIST_SUCCESS';
export const STAFF_SCHEDULE_LIST_FAIL = 'STAFF_SCHEDULE_LIST_FAIL';

export const STAFF_SCHEDULE_UPDATE = 'STAFF_SCHEDULE_UPDATE';
export const STAFF_SCHEDULE_UPDATE_SUCCESS = 'STAFF_SCHEDULE_UPDATE_SUCCESS';
export const STAFF_SCHEDULE_UPDATE_FAIL = 'STAFF_SCHEDULE_UPDATE_FAIL';