import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Divider, Select, Avatar, Checkbox, Spin, Modal } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { completedProfileSalon, uploadImage, getAddressByPostCode } from 'providers/AuthProvider/actions';

import moment from 'moment';

import logoC2C from 'assets/images/logo-c2c.png';
import './styles.less';

const { Option } = Select;

const CompleteProfile = () => {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [logo, setLogo] = useState()
  const [loading, setLoading] = useState(false)

  const { user } = useSelector(state => state.auth);

  const slots = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 30,
      label: '00:30'
    },
    {
      value: 100,
      label: '01:00'
    },
    {
      value: 130,
      label: '01:30'
    },
    {
      value: 200,
      label: '02:00'
    },
    {
      value: 230,
      label: '02:30'
    },
    {
      value: 300,
      label: '03:00'
    },
    {
      value: 330,
      label: '03:30'
    },
    {
      value: 400,
      label: '04:00'
    },
    {
      value: 430,
      label: '04:30'
    },
    {
      value: 500,
      label: '05:00'
    },
    {
      value: 530,
      label: '05:30'
    },
    {
      value: 600,
      label: '06:00'
    },
    {
      value: 630,
      label: '06:30'
    },
    {
      value: 700,
      label: '07:00'
    },
    {
      value: 730,
      label: '07:30'
    },
    {
      value: 800,
      label: '08:00'
    },
    {
      value: 830,
      label: '08:30'
    },
    {
      value: 900,
      label: '09:00'
    },
    {
      value: 930,
      label: '09:30'
    },
    {
      value: 1000,
      label: '10:00'
    },
    {
      value: 1030,
      label: '10:30'
    },
    {
      value: 1100,
      label: '11:00'
    },
    {
      value: 1130,
      label: '11:30'
    },
    {
      value: 1200,
      label: '12:00'
    },
    {
      value: 1230,
      label: '12:30'
    },
    {
      value: 1300,
      label: '13:00'
    },
    {
      value: 1330,
      label: '13:30'
    },
    {
      value: 1400,
      label: '14:00'
    },
    {
      value: 1430,
      label: '14:30'
    },
    {
      value: 1500,
      label: '15:00'
    },
    {
      value: 1530,
      label: '15:30'
    },
    {
      value: 1600,
      label: '16:00'
    },
    {
      value: 1630,
      label: '16:30'
    },
    {
      value: 1700,
      label: '17:00'
    },
    {
      value: 1730,
      label: '17:30'
    },
    {
      value: 1800,
      label: '18:00'
    },
    {
      value: 1830,
      label: '18:30'
    },
    {
      value: 1900,
      label: '19:00'
    },
    {
      value: 1930,
      label: '19:30'
    },
    {
      value: 2000,
      label: '20:00'
    },
    {
      value: 2030,
      label: '20:30'
    },
    {
      value: 2100,
      label: '21:00'
    },
    {
      value: 2130,
      label: '21:30'
    },
    {
      value: 2200,
      label: '22:00'
    },
    {
      value: 2230,
      label: '22:30'
    },
    {
      value: 2300,
      label: '23:00'
    },
    {
      value: 2330,
      label: '23:30'
    },
  ]


  const handleUploadImg = () => e => {
    //Upload Image
    setLoading(true)
    dispatch(uploadImage({ file: e.target.files[0] }))
    .then(result => {
      setLogo(result)
      setLoading(false)
    })
    .catch(err => setLoading(false))
  };
  
  return (
    <div className="complete-profile-page">
        <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="request-page-row">
          <Col xs={{ span: 24 }}>
            <div className="request-logo">
              <img src={logoC2C} alt="logo" style={{ height: 46 }} />
              <h3 className="request-title">Complete your business info</h3>
            </div>
            <div>
                <Form
                  form={form}
                  name="completeForm"
                  onFinish={values => {
                    if (values.postCode !== '' && values.postCode !== undefined) {
                      dispatch(getAddressByPostCode({postCode: values.postCode}))
                      .then(result => console.log('Passed validate'))
                      .catch(error => {
                        if (error.data.length > 0) {
                          let msg = '';
                          error.data.forEach((item) => {
                            msg = `${msg} ${item.message}. `
                          })
                          form.setFields([
                            {
                              name: 'postCode',
                              errors: [msg],
                            },
                          ]);
                        } else {
                          form.setFields([
                            {
                              name: 'postCode',
                              errors: [error.message],
                            },
                          ]);
                        }

                        return;
                      })
                    }
                    

                    if (values.business_hour_starttime && values.business_hour_endtime && values.business_hour_starttime >= values.business_hour_endtime) {
                      Modal.warning({
                        content: 'Business Hour - End Time must be greater than Start Time!!',
                      });
                      return;
                    }

                    if (values.working_days_is_mon === false && values.working_days_is_tue === false && values.working_days_is_wed === false && values.working_days_is_thu === false && values.working_days_is_fri === false && values.working_days_is_sat === false && values.working_days_is_sun === false) {
                      Modal.warning({
                        content: 'Please select at least one working day!',
                      });
                      return;
                    }

                    //schedules
                    let schedules = []
                    let currentDate = new moment();
                    let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(values.business_hour_starttime / 100)}:${values.business_hour_starttime % 100}`, "YYYY/MM/DD HH:mm").toISOString()
                    let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(values.business_hour_endtime / 100)}:${values.business_hour_endtime % 100}`, "YYYY/MM/DD HH:mm").toISOString()

                    if (values.working_days_is_sun === true) {
                      let item = {
                        dayOfWeek: 0,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }

                    if (values.working_days_is_mon === true) {
                      let item = {
                        dayOfWeek: 1,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }

                    if (values.working_days_is_tue === true) {
                      let item = {
                        dayOfWeek: 2,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }
                    if (values.working_days_is_wed === true) {
                      let item = {
                        dayOfWeek: 3,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }
                    if (values.working_days_is_thu === true) {
                      let item = {
                        dayOfWeek: 4,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }
                    if (values.working_days_is_fri === true) {
                      let item = {
                        dayOfWeek: 5,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }
                    if (values.working_days_is_sat === true) {
                      let item = {
                        dayOfWeek: 6,
                        startAt,
                        endAt
                      }
                      schedules.push(item)
                    }

                    console.log('disptach.schedules: ', schedules)
                    
                    dispatch(completedProfileSalon({
                      ...values,
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                      schedules,
                      salonId: user['https://user_metadata']?.salon_id,
                      logoUrl: logo,
                    }))
                    .then((result) => {
                      console.log('result: ', result)
                      navigate(`/mysalon`)
                    }).catch((error) => {
                      console.log('Oops!', error)
                    })
                  }}
                  onValuesChange={(_, values) => {
                    if (values.business_hour_starttime && values.business_hour_endtime && values.business_hour_starttime >= values.business_hour_endtime) {
                      Modal.warning({
                        content: 'Business Hour - End Time must be greater than Start Time!!',
                      });
                    }
                  }}
                  initialValues={{
                    businessName: user['https://user_metadata'].bussiness_name,
                    email: user['https://email'],
                    phone: user['https://user_metadata'].phone_number,
                    postCode: '',
                    prefecture: '',
                    cityOrTown: '',
                    address: '',
                    building_types_is_nail: false,
                    building_types_is_hair: false,
                    building_types_is_massage: false,
                    building_types_is_spa: false,
                    working_days_is_mon: true,
                    working_days_is_tue: true,
                    working_days_is_wed: true,
                    working_days_is_thu: true,
                    working_days_is_fri: true,
                    working_days_is_sat: true,
                    working_days_is_sun: true,
                    business_hour_starttime: 800,
                    business_hour_endtime: 1700,
                  }}
                >
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Logo: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    {logo === undefined? 
                      <Avatar icon={<PictureOutlined />} size={'large'} src={logo} />
                      :
                      <Avatar size={'large'} src={logo} />
                    }
                    <label htmlFor="logo" className="ant-btn ant-btn-link">
                      {loading === false? 
                      `Change`
                      :
                      <Spin />
                      }
                    </label>
                    <Input
                    id='logo'
                    name="logo"
                    accept="image/*"
                    type="file"
                    hidden
                    onChange={handleUploadImg()}
                  />
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business name: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item 
                      name="businessName"
                      validateTrigger={'onBlur'}
                      onKeyPress={(e) => {
                        if (e.target.value.length >= 50) {
                          e.preventDefault();
                        }
                      }}
                      rules={[
                        { 
                          required: true, 
                          message: 'Please input your Business Name!' 
                        },
                        {
                          min: 2,
                          message: "Please input more than 1 characters"
                        },
                        {
                          max: 50,
                          message: "Please input less than 50 characters"
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Email: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name="email">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Phone: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item name="phone">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Post Code: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item 
                      name="postCode"
                      validateTrigger={'onBlur'}
                      rules={[
                        { 
                          required: true, 
                          message: 'Please input a Post Code!' 
                        },
                        {
                          min: 1,
                          message: "Please input more than 1 characters"
                        },
                        {
                          max: 7,
                          message: "Please input less than 7 characters"
                        }
                      ]}
                    >
                      <Input 
                        onKeyPress={(e) => {
                          const key = e.which || e.key;
                          if (e.target.value.length < 7) {
                            if (key < 48 || key > 57)
                              e.preventDefault();
                          } else e.preventDefault();
                        }}
                        onBlur={e => {
                          if (e.target.value !== '' && e.target.value !== undefined) {
                            dispatch(getAddressByPostCode({postCode: e.target.value}))
                            .then(result => {
                              if (result.data.cityOrTown) {
                                form.setFieldsValue({ cityOrTown: result.data.cityOrTown })
                              }

                              if (result.data.prefecture) {
                                form.setFieldsValue({ prefecture: result.data.prefecture })
                              }
                            })
                            .catch(error => {
                              form.setFieldsValue({ cityOrTown: '' })
                              form.setFieldsValue({ prefecture: '' })
                              if (error.data.length > 0) {
                                let msg = '';
                                error.data.forEach((item) => {
                                  msg = `${msg} ${item.message}. `
                                })
                                form.setFields([
                                  {
                                    name: 'postCode',
                                    errors: [msg],
                                  },
                                ]);
                                
                              } else {
                                form.setFields([
                                  {
                                    name: 'postCode',
                                    errors: [error.message],
                                  },
                                ]);
                              }
                            })
                          }
                          else if (e.target.value === '') {
                            form.setFieldsValue({ cityOrTown: '' })
                            form.setFieldsValue({ prefecture: '' })
                          }
                      }} />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Prefectures: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item 
                      name="prefecture"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>City and Town: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item 
                      name="cityOrTown"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{marginTop: -4}}>Building Name/ Room number: </Col>
                  <Col lg={{ span: 8 }} xs={{ span: 24 }}>
                    <Form.Item 
                      name="address"
                      rules={[{ required: true, message: 'Please input a Building Name/ Room number!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business Hour: </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    {/* <Form.Item 
                      name="business_hour_starttime"
                      rules={[{ required: true, message: 'Please input business hour Start Time!' }]}
                    >
                      <TimePicker
                        style={{ width: 120 }}
                        format={'HH:mm'}
                        placeholder=""
                      />
                    </Form.Item> */}
                    <Form.Item 
                      name="business_hour_starttime"
                      rules={[{ required: true, message: 'Please input business hour Start Time!' }]}
                    >
                      <Select
                          placeholder=""
                          style={{width: 120, marginLeft: 24}}
                        >
                          {slots.map((item) => {
                            return (
                              <Option value={item.value}>{item.label}</Option>
                            )
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    {/* <Form.Item 
                      name="business_hour_endtime"
                      rules={[{ required: true, message: 'Please input business hour End Time!' }]}
                    >
                      <TimePicker
                        style={{ width: 120 }}
                        format={'HH:mm'}
                        placeholder=""
                      />
                    </Form.Item> */}
                    <Form.Item 
                      name="business_hour_endtime"
                      rules={[{ required: true, message: 'Please input business hour End Time!' }]}
                    >
                      <Select
                          placeholder=""
                          style={{width: 120, marginLeft: 20}}
                        >
                          {slots.map((item) => {
                            return (
                              <Option value={item.value}>{item.label}</Option>
                            )
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business Type: </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="building_types_is_nail"
                        label="Nail"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="building_types_is_hair"
                        label="Hair"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="building_types_is_massage"
                        label="Massage"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="building_types_is_spa"
                        label="Spa"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={24} style={{paddingRight: 0, paddingLeft: 10}}>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Working days: </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_mon"
                        label="Mon"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_tue"
                        label="Tue"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_wed"
                        label="Wed"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_thu"
                        label="Thu"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_fri"
                        label="Fri"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3 }} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_sat"
                        label="Sat"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 3}} xs={{ span: 24 }}>
                    <Form.Item
                        name="working_days_is_sun"
                        label="Sun"
                        valuePropName="checked"
                      >
                        <Checkbox />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="ant-modal-footer"> 
                  <Row gutter={24} type="flex" style={{textAlign: 'right', marginBottom: 30, marginTop: 10}}>
                    <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
                      <Button type="primary" htmlType="submit">
                          Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
  




            </div>
          </Col>
        </Row>
    </div>
  );
};

export default CompleteProfile;