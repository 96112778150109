import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { formatYen } from 'utils/stringFormat';
import { useDispatch } from 'react-redux';

import { Layout, Breadcrumb, Card, Row, Col, DatePicker } from 'antd';
import { paymentSummary } from 'providers/PaymentProvider/actions';
import { useSelector } from 'react-redux';
import MenuSliderLeft from 'components/Desktop/Salon/MenuSliderLeft'
import './styles.less';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const MySalon = () => {
  const dispatch = useDispatch();
  const { salon } = useSelector(state => state.salon);
  const [rangeDate, setRangeDate] = useState([]);
  const [sumaryPayments, setSumarryPayments] = useState({});
  useEffect(() => {
    let params = {};
    params['from'] = moment(rangeDate[0]).startOf('day').toISOString();
    params['to'] = moment(rangeDate[1]).endOf('day').toISOString();

    dispatch(paymentSummary(params))
      .then(result => {
        const { data } = result;
        setSumarryPayments(data);
      })
      .catch((error) => {
        console.log('Oops!', error)
      })

  }, [rangeDate]);


  console.log('salon: ', salon)
  
  return (
    <Layout className="mysalon-page">

      <MenuSliderLeft salon={salon} menuItem="1" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>My Salon</Breadcrumb.Item>
          <Breadcrumb.Item>Reports</Breadcrumb.Item>
        </Breadcrumb>
        <Card style={{ marginBottom: 18 }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>Reports</h2>
              </Col>
            </Row>
            <Content>
              <Card style={{ backgroundColor: '#F8F8F8' }}>
                <div style={{ marginBottom: 8, fontSize: 16, color: 'black' }}><label>Payment report: </label>
                  <RangePicker
                    placeholder={['From date', 'To date']}
                    defaultValue={[moment(), moment()]}
                    ranges={{
                      Today: [moment(), moment()],
                      'Last 7 days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
                      'Last 30 days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
                    }}
                    onChange={value => {
                      if (value) {
                        setRangeDate(value);
                      }
                      else {
                        setRangeDate([moment().add(1, 'days'), moment().add(1, 'days')])
                      }
                    }}
                  />
                </div>
              </Card >
              <Row style={{ color: 'black', marginTop: 16 }} >
                <Col span={5} >
                  <div style={{ fontSize: 18 }}>{_.get(sumaryPayments, 'total', '0')}</div>
                  <div>Billing count</div>
                </Col>
                <Col lg={{ span: 1 }} xs={{ span: 24 }}>
                  <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '108%' }}></div>
                </Col>
                <Col span={5} >
                  <div style={{ fontSize: 18 }}> {formatYen(_.get(sumaryPayments, 'totalCash', ''))}</div>
                  <div>Cash payment</div>
                </Col>
                <Col lg={{ span: 1 }} xs={{ span: 24 }}>
                  <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '108%' }}></div>
                </Col>
                <Col span={5}>
                  <div style={{ fontSize: 18 }}> {formatYen(_.get(sumaryPayments, 'totalOnline', ''))}</div>
                  <div>Online payment</div>
                </Col>
                <Col lg={{ span: 1 }} xs={{ span: 24 }}>
                  <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '108%' }}></div>
                </Col>
                <Col span={5}>
                  <div style={{ fontSize: 18 }}> {formatYen(_.get(sumaryPayments, 'totalAmount', ''))}</div>
                  <div>Total amount</div>
                </Col>
              </Row>
            </Content>
          </Card>
        
      </Layout>
    </Layout>
  )
};

export default MySalon;