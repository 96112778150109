import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { bookingList, bookingDetail, getApps, getPaymentByBookingId } from 'providers/BookingProvider/actions';
import { categoryList } from 'providers/CategoryProvider/actions';

import { Layout, Button, Input, Breadcrumb, Popover, Table, Card, Row, Col, Modal, Tabs, DatePicker, Radio, Avatar, Form, Menu, Dropdown } from 'antd';
import { DownOutlined, UserOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CaretLeftOutlined, CaretRightOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import BookingCreateForm from 'components/Desktop/Booking/BookingCreateForm';
import BookingViewForm from 'components/Desktop/Booking/BookingViewForm';
import BookingCancelForm from 'components/Desktop/Booking/BookingCancelForm';

import './styles.less';
import { formatYen } from 'utils/stringFormat';
import { BOOKING_STATUS } from 'utils/constants';
import { smartTrim } from 'utils/common';

const nailists = [
  {
    key: "J93kqpVSB3", 
    photo: "https://d19m6mcdg8opxd.cloudfront.net/b30a5575a844d69dc1c805c894c3672d_avatar.jpg", 
    nailistId: "J93kqpVSB3", 
    nailistUsername: "test004", 
    system: "Nailie",
    slot1: "",
    slot2: "",
    slot3: "",
    slot4: "",
    slot5: "",
    slot6: "",
    slot7: "",
    slot8: "",
    slot9: "",
    slot10: "",
    slot11: "",
    slot12: "",
    slot13: "",
    slot14: "",
    slot15: "",
    slot16: "",
    slot17: "",
    slot18: "",
    slot19: "",
    slot20: "",
    slot21: "",
    slot22: "",
    slot23: "",
    slot24: "",
    slot25: "",
    slot26: "",
    slot27: "",
    slot28: "",
    slot29: "",
    slot30: "",
    slot31: "",
    slot32: "",
    slot33: "",
    slot34: "",
    slot35: "",
    slot36: "",
    slot37: "",
    slot38: "",
    slot39: "",
    slot40: "",
    slot41: "",
    slot42: "",
    slot43: "",
    slot44: "",
    slot45: "",
    slot46: "",
    slot47: "",
    slot48: "",
  },
  {
    key: "sVOIhU2EKl", 
    photo: "https://d19m6mcdg8opxd.cloudfront.net/1ecccb8d950290275185f707c25c54fc_1586419898.jpg", 
    nailistId: "sVOIhU2EKl", 
    nailistUsername: "test102", 
    system: "Nailie",
    slot1: "",
    slot2: "",
    slot3: "",
    slot4: "",
    slot5: "",
    slot6: "",
    slot7: "",
    slot8: "",
    slot9: "",
    slot10: "",
    slot11: "",
    slot12: "",
    slot13: "",
    slot14: "",
    slot15: "",
    slot16: "",
    slot17: "",
    slot18: "",
    slot19: "",
    slot20: "",
    slot21: "",
    slot22: "",
    slot23: "",
    slot24: "",
    slot25: "",
    slot26: "",
    slot27: "",
    slot28: "",
    slot29: "",
    slot30: "",
    slot31: "",
    slot32: "",
    slot33: "",
    slot34: "",
    slot35: "",
    slot36: "",
    slot37: "",
    slot38: "",
    slot39: "",
    slot40: "",
    slot41: "",
    slot42: "",
    slot43: "",
    slot44: "",
    slot45: "",
    slot46: "",
    slot47: "",
    slot48: "",
  }
];

const bookingsSchedule = [
  {
    nailistId: "J93kqpVSB3",
    bookings: [
      {
        bookingDate: "2020-06-30",
        bookingId: "TWMd31gKnA",
        client: "kkkm",
        duration: 20,
        price: 1000,
        slot: 830,
        status: "DONE",
        system: "Nailie"
      },
      {
        bookingDate: "2020-06-30",
        bookingId: "i2gL0ylJqu",
        client: "246",
        duration: 120,
        price: 1000,
        slot: 930,
        status: "DONE",
        system: "Nailie"
      }
    ]
  }
]

const { Content } = Layout;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};

const Bookings = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [defaultValue, setDefaultValue] = useState(() => {
    return moment();
  });

  const [currentDate, setCurrentDate] = useState(() => {
    return moment();
  });
  
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [booking, setBooking] = useState({});

  const [isSearch, setIssearch] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [filterStatus, setFiterStatus] = useState();
  const [filterSystem, setFilterSystem] = useState();
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [systems, setSystems] = useState([]);
  const [payment, setPayment] = useState([]);
  
  const {
    bookings = {},
    loading
  } = useSelector((state) => state.booking);

  const dataSource = _.get(bookings, 'data');
  const totalPage = _.get(bookings, 'pagination.total');

  //console.log('dataSource: ', dataSource)

  const menu = (bookingId, status) => (
    <Menu>
      <Menu.Item>
        <a onClickCapture={e => {
          e.stopPropagation(); 
          openCreateCopyModal(bookingId)
        }}>
          Copy
        </a>
      </Menu.Item>
      {status !== 1 && (
      <Menu.Item>
        <a target="_blank" onClick={e=> {
          e.stopPropagation(); 
          cancelBooking(bookingId)
        }}>
          Cancel
        </a>
      </Menu.Item>
      )}
    </Menu>
  )
  
  useEffect(() => {
    dispatch(categoryList());
    dispatch(getApps())
    .then((result) => {
      setSystems(result.data)
    })
    .catch(err => console.log(err))
  }, []);

  useEffect(() => {
    let params = {};
    params['keyword'] = _.trim(keyword);
    if (dates && dates[0]) {
      params['from'] = moment(dates[0]).startOf('day').toISOString()
    }
    if (dates && dates[1]) {
      params['to'] = moment(dates[1]).endOf('day').toISOString()
    }

    params['page'] = currentPage;
    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    params['status'] = filterStatus;
    params['systemId'] = filterSystem;

    dispatch(bookingList(params));
  }, [filterStatus, filterSystem ,currentPage, sortedInfo.columnKey, sortedInfo.order, pageSize]);

  const openUpdateModal = (bookingId) => {
    dispatch(bookingDetail({bookingId}))
    .then(result => {
      console.log('result: ', result)
      
      setBooking({
        systemId: result.data.systemId,
        systemName: (result.data.systemId !== undefined && result.data.systemId !== null)? systems.find(item => item.id === result.data.systemId)?.name : '',
        customer: result.data.customer,
        discount: result.data.discount,
        totalPrice: result.data.totalPrice,
        totalDuration: result.data.totalDuration,
        finalPrice: result.data.finalPrice,
        charge: result.data.charge,
        cancellationFee: result.data.cancellationFee,
        status: result.data.status,
        bookingId: result.data.id,
        bookingDate: moment(result.data.startTime, "YYYY/MM/DD HH:mm"),
        updatedAt: moment(result.data.updatedAt, "YYYY/MM/DD HH:mm"),
        bookingDateDisplay: moment(result.data.startTime).format('HH:mm YYYY-MM-DD'),
        updatedAtDisplay: moment(result.data.updatedAt).format('HH:mm YYYY-MM-DD'),
        
        customerId: result.data.customer.id,
        services: result.data.orders.map(item => {
          return {
            startTime: moment(item.startTime),
            serviceId: item.service.id,
            serviceName: item.service.name,
            staffId: item.staff.id,
            staffName: item.staff.name,
          }
        })
      });
      setIsUpdateModal(true);
    });
  };

  const openCreateCopyModal = (bookingId) => {
    dispatch(bookingDetail({bookingId}))
    .then(result => {
      setBooking({
        systemId: result.data.systemId,
        customer: result.data.customer,
        discount: result.data.discount,
        totalPrice: result.data.totalPrice,
        totalDuration: result.data.totalDuration,
        finalPrice: result.data.finalPrice,
        status: result.data.status,
        bookingId: result.data.id,
        bookingDate: moment(result.data.startTime, "YYYY/MM/DD HH:mm"),
        customerId: result.data.customer.id,
        services: result.data.orders.filter(item => item.service.active !== false).map(item => {
          return {
            startTime: moment(item.startTime),
            serviceId: item.service.id,
            staffId: ''
          }
        })
      });
      setIsCreateModal(true);
    });
  };

  const cancelBooking = (bookingId) => {
    dispatch(bookingDetail({bookingId}))
    .then(result => {
      setBooking({
        systemId: result.data.systemId,
        customer: result.data.customer,
        discount: result.data.discount,
        totalPrice: result.data.totalPrice,
        totalDuration: result.data.totalDuration,
        finalPrice: result.data.finalPrice,
        charge: result.data.charge,
        status: result.data.status,
        bookingId: result.data.id,
        bookingDate: moment(result.data.startTime, "YYYY/MM/DD HH:mm"),
        customerId: result.data.customer.id,
        services: result.data.orders.map(item => {
          return {
            startTime: moment(item.startTime),
            serviceId: item.service.id,
            serviceName: item.service.name,
            staffId: item.staff.id,
            staffName: item.staff.name,
          }
        })
      });
      setIsCancelModal(true);
    });
  };

  const onSubmitSearch = () => {
    let params = {};
    params['keyword'] = _.trim(keyword);
    if (dates && dates[0]) {
      //params['from'] = moment(dates[0]).toISOString();
      params['from'] = moment(dates[0]).startOf('day').toISOString()
    }

    if (dates && dates[1]) {
      //params['to'] = moment(dates[1]).toISOString();
      params['to'] = moment(dates[1]).endOf('day').toISOString()
    }
    
    params['page'] = 1;
    params['status'] = filterStatus;
    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    dispatch(bookingList(params));
    setCurrentPage(1)
    setIssearch(true);
  };

  
  const renderSystem = (system) => {
    switch (system) {
      case 'Nailie':
        return (<span className="system nailie"></span>);

      default:
        return (<Radio style={{ color: '#52c41a' }} />);
    }
  };

  const onChangeRangeDate = (date) => {
    setDefaultValue(date);
    setCurrentDate(date);
  };

  const handelChangeRangeDate = (numberDay) => {
    const newDate = moment(currentDate.toString()).add(numberDay, 'day');
    setDefaultValue(newDate);
    setCurrentDate(newDate);
  };

  const handleOnChangeTab = (activeKey) => {
    let day = moment(activeKey);
    setCurrentDate(day);
  };

  const createTab = (days) => {
    let result = [];
    let currentDay = moment(defaultValue);

    for (let i = 0; i < 7; i++) {
      let current = currentDay;

      result.push(
        <TabPane
          tab={(
            <div className="title-tab">
              <span>
                {
                  current.format('DDMMMYYYY') === moment().format('DDMMMYYYY') && 'Today'
                }
                {
                  current.format('DDMMMYYYY') !== moment().format('DDMMMYYYY') && current.format('dddd').toString()
                }
              </span>
              <span>{current.format('DDMMMYYYY').toString()}</span>
            </div>
          )}
          key={current.format('YYYY-MM-DD').toString()}
          disabled={days[current.format('ddd').toString()]}
        >
        </TabPane>
      );

      current = current.add(1, 'days');
    }

    return result;
  };

  const renderCell = (row, slot) => {
    let nailistBookings = bookingsSchedule.find(i => i.nailistId === row['nailistId']);
    const obj = {
      props: {}
    };

    if (nailistBookings) {
      let slotsOffset = 0;

      for (let i = 0; i < nailistBookings.bookings.length; i++) {
        //Find slot example: 00:00
        if (nailistBookings.bookings[i].slot === slot) {
          switch (nailistBookings.bookings[i].status) {
            case BOOKING_STATUS.REQUESTED:
              obj.props.className = 'cell booking-requested';
              obj.props.status = 'Requested';
              break;
            case BOOKING_STATUS.CONFIRMED:
              obj.props.className = 'cell booking-comfirmed';
              obj.props.status = 'Confirmed';
              break;
            case BOOKING_STATUS.WAITING_DONE:
              obj.props.className = 'cell booking-waittingDone';
              obj.props.status = 'Wating Done';
              break;
            case BOOKING_STATUS.DONE:
              obj.props.className = 'cell booking-done';
              obj.props.status = 'Done';
              break;
            default:
              obj.props.className = 'cell booking-requested';
              obj.props.status = nailistBookings.bookings[i].status;
              break;
          }
          //dispatch(fetchBookingDetail(nailistBookings.bookings[i].bookingId))
          obj.children = <div style={{ cursor: 'pointer', textAlign: 'left', fontSize: 10, lineHeight: 1.2 }} onClick={() => console.log('Detail')} >
            {renderSystem(nailistBookings.bookings[i].system)}
            {nailistBookings.bookings[i].bookingId} <br /> <strong>{smartTrim(nailistBookings.bookings[i].client, 12, ' ', '...')}</strong> <br /> {formatYen(nailistBookings.bookings[i].price)} <br />
            {obj.props.status}
          </div>;

          let colSpan = Math.floor(nailistBookings.bookings[i].duration / 30);
          slotsOffset = slotsOffset + colSpan;

          if (nailistBookings.bookings[i].duration % 30 === 0) {
            obj.props.colSpan = colSpan;
          } else {
            obj.props.colSpan = colSpan + 1;
          }
        } else {
          //offset ? => obj.props.colSpan = 0
          let isOffset = false;
          for (let j = 0; j < nailistBookings.bookings.length; j++) {
            let durationHour = Math.floor(nailistBookings.bookings[j].duration / 60);
            let durationMinute = 0;
            if (nailistBookings.bookings[i].duration % 30 === 0) {
              durationMinute = 0;
            } else {
              durationMinute = 30;
            }

            if (slot > nailistBookings.bookings[j].slot && slot < (nailistBookings.bookings[j].slot + durationHour * 100 + durationMinute)) {
              isOffset = true;
            }
          }

          if (isOffset === true) {
            obj.props.colSpan = 0;
          }

          // if (row['nailistId'] === 'sVOIhU2EKl') {
          //   //find offset
          //   if (slot > 0 && slot < 800) {
          //     obj.props.colSpan = 0;
          //   }
          //   if (slot > 800 && slot < 1600) {
          //     obj.props.colSpan = 0;
          //   }
          // }
        }
      }
    }

    return obj;
  };

  const columns = [
    {
      title: 'Staff',
      dataIndex: 'name',
      width: 160,
      fixed: 'left',
      render: (value, row, index) => {
        let nailistBookings = bookingsSchedule.find(i => i.nailistId === row['nailistId']);

        if (nailistBookings) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={row.photo} size='large' style={{ marginRight: 8 }} />
              {row.nailistUsername} <span style={{ color: '#E5004E' }}>({nailistBookings.bookings.length})</span>
            </div >
          );
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={row.photo} size='large' style={{ marginRight: 8 }} />
              {row.nailistUsername} <span style={{ color: '#E5004E' }}>(0)</span>
            </div >
          );
        }

      }
    },
    {
      title: '00:00',
      dataIndex: 'slot1',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 0);
      }
    },
    {
      title: '00:30',
      dataIndex: 'slot2',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 30);
      }
    },
    {
      title: '01:00',
      dataIndex: 'slot3',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 100);
      }
    },
    {
      title: '01:30',
      dataIndex: 'slot4',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 130);
      }
    },
    {
      title: '02:00',
      dataIndex: 'slot5',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 200);
      }
    },
    {
      title: '02:30',
      dataIndex: 'slot6',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 230);
      }
    },
    {
      title: '03:00',
      dataIndex: 'slot7',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 300);
      }
    },
    {
      title: '03:30',
      dataIndex: 'slot8',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 330);
      }
    },
    {
      title: '04:00',
      dataIndex: 'slot9',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 400);
      }
    },
    {
      title: '04:30',
      dataIndex: 'slot10',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 430);
      }
    },
    {
      title: '05:00',
      dataIndex: 'slot11',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 500);
      }
    },
    {
      title: '05:30',
      dataIndex: 'slot12',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 530);
      }
    },
    {
      title: '06:00',
      dataIndex: 'slot13',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 600);
      }
    },
    {
      title: '06:30',
      dataIndex: 'slot14',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 630);
      }
    },
    {
      title: '07:00',
      dataIndex: 'slot15',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 700);
      }
    },
    {
      title: '07:30',
      dataIndex: 'slot16',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 730);
      }
    },
    {
      title: '08:00',
      dataIndex: 'slot17',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 800);
      }
    },
    {
      title: '08:30',
      dataIndex: 'slot18',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 830);
      }
    },
    {
      title: '09:00',
      dataIndex: 'slot19',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 900);
      }
    },
    {
      title: '09:30',
      dataIndex: 'slot20',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 930);
      }
    },
    {
      title: '10:00',
      dataIndex: 'slot21',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1000);
      }
    },
    {
      title: '10:30',
      dataIndex: 'slot22',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1030);
      }
    },
    {
      title: '11:00',
      dataIndex: 'slot23',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1100);
      }
    },
    {
      title: '11:30',
      dataIndex: 'slot24',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1130);
      }
    },
    {
      title: '12:00',
      dataIndex: 'slot25',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1200);
      }
    },
    {
      title: '12:30',
      dataIndex: 'slot26',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1230);
      }
    },
    {
      title: '13:00',
      dataIndex: 'slot27',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1300);
      }
    },
    {
      title: '13:30',
      dataIndex: 'slot28',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1330);
      }
    },
    {
      title: '14:00',
      dataIndex: 'slot29',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1400);
      }
    },
    {
      title: '14:30',
      dataIndex: 'slot30',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1430);
      }
    },
    {
      title: '15:00',
      dataIndex: 'slot31',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1500);
      }
    },
    {
      title: '15:30',
      dataIndex: 'slot32',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1530);
      }
    },
    {
      title: '16:00',
      dataIndex: 'slot33',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1600);
      }
    },
    {
      title: '16:30',
      dataIndex: 'slot34',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1630);
      }
    },
    {
      title: '17:00',
      dataIndex: 'slot35',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1700);
      }
    },
    {
      title: '17:30',
      dataIndex: 'slot36',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1730);
      }
    },
    {
      title: '18:00',
      dataIndex: 'slot37',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1800);
      }
    },
    {
      title: '18:30',
      dataIndex: 'slot38',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1830);
      }
    },
    {
      title: '19:00',
      dataIndex: 'slot39',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1900);
      }
    },
    {
      title: '19:30',
      dataIndex: 'slot40',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 1930);
      }
    },
    {
      title: '20:00',
      dataIndex: 'slot41',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2000);
      }
    },
    {
      title: '20:30',
      dataIndex: 'slot42',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2030);
      }
    },
    {
      title: '21:00',
      dataIndex: 'slot43',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2100);
      }
    },
    {
      title: '21:30',
      dataIndex: 'slot44',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2130);
      }
    },
    {
      title: '22:00',
      dataIndex: 'slot45',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2200);
      }
    },
    {
      title: '22:30',
      dataIndex: 'slot46',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2230);
      }
    },
    {
      title: '23:00',
      dataIndex: 'slot47',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2300);
      }
    },
    {
      title: '23:30',
      dataIndex: 'slot48',
      align: 'center',
      width: 100,
      render: (value, row, index) => {
        return renderCell(row, 2330);
      }
    }
  ];

  const salon = {
    openTime: 0,
    closeTime: 2400,
    days: {
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      Sun: false
    }
  };

  const columnsSearch = [
    {
      title: 'Booking ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Treatment date',
      dataIndex: 'startTime',
      defaultSortOrder: 'descend',
      key: 'startTime',
      width: '150',
      render: (record) => (moment(record).format('HH:mm YYYY-MM-DD')),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Customer name',
      dataIndex: 'customer',
      key: 'customer',
      width: '150',
      render: (record) => {
        return (record.name)
      },
    },
    {
      title: 'Staff',
      dataIndex: 'orders',
      key: 'orders',
      render: (record) => {
        let staffs = record.map(item => ({
          id: item.staff.id,
          name: item.staff.name
        }))

        if (_.uniqBy(staffs, 'id').length >= 3) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
              {staffs[0].name},
              <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }}/>
              {staffs[1].name},
              <Popover content={(
                        <div>
                            {_.uniqBy(staffs, 'id').map((staff, index) => {
                              if (index >= 2) return <p>{staff.name}</p>
                            })}
                        </div>
                      )}>
                <Button type="link" style={{marginLeft: -8}}>+ {_.uniqBy(staffs, 'id').length - 2} more</Button>
              </Popover>              
            </div >
          );
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_.uniqBy(staffs, 'id').length === 1 && (
                <>
                <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
                {_.uniqBy(staffs, 'id')[0]?.name}
                </>
              )}

              {_.uniqBy(staffs, 'id').length === 2 && (
                <>
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}/>
                  {_.uniqBy(staffs, 'id')[0]?.name},
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }}/>
                  {_.uniqBy(staffs, 'id')[1].name}
                </>
              )}
            </div >
          );
        }
      }
    },
    {
      title: 'Total fee',
      width: '10%',
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      render: (text, record, index) => {
        if (record['finalPrice'] !== undefined) {
          return (
            <div>{formatYen(record['finalPrice'])}</div>
          );
        }
      }
    },
    {
      title: 'System',
      width: '10%',
      key: 'systemId',
      render: (text, record, index) => {
        if (record !== undefined) {
          let system = systems.find(item => item.id === record.systemId)
        
          return (
            <div>{system?.name}</div>
          );
        }
      },
      filterMultiple: true,
      filters: _.uniqBy(systems, 'id').map(item => {
        let system = {
          text: item.name,
          value: item.id
        }

        return system;
      }),
    },
    {
      title: 'Status update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '150',
      render: (record) => (moment(record).format('HH:mm YYYY-MM-DD')),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (record) => {
        switch (record) {
          case 0:
            return (<div className="status-block new">New</div>);
            break;
          case 1:
            return (<div className="status-block cancel">Cancel</div>);
            break;
          case 2:
            return (<div className="status-block arrived">Arrived</div>);
            break;
          case 3:
            return (<div className="status-block started">Started</div>);
            break;
          case 4:
            return (<div className="status-block done">Done</div>);
            break;
          default:
            return (<div className="status-block new">New</div>);
        }
      },
      filterMultiple: true,
      filters: [
        {
          text: 'New',
          value: 0,
        },
        {
          text: 'Cancel',
          value: 1,
        },
        {
          text: 'Arrived',
          value: 2,
        },
        {
          text: 'Started',
          value: 3,
        },
        {
          text: 'Done',
          value: 4,
        },
      ],
    },
    {
      title: '',
      width: '150',
      render: (record) => (
        <Dropdown overlay={menu(record.id, record.status)}>
          <a className="ant-dropdown-link" onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
            Action <DownOutlined />
          </a>
        </Dropdown>   
      ),
    },
  ];

  return (
    <Layout>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon/bookings">Bookings</a></Breadcrumb.Item>
          {isSearch === true ? 
          <Breadcrumb.Item> Search Result</Breadcrumb.Item>
          :
          <Breadcrumb.Item> Home</Breadcrumb.Item>
        }
        </Breadcrumb>
        <div className='bookings-page'>
          <Card style={{ marginBottom: 18 }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                <Col className="gutter-row" span={12}>
                  {isSearch === false ?
                  <h2>Bookings</h2>
                  :
                  <h2><a onClick={() => setIssearch(false)}><ArrowLeftOutlined /></a> Search Result</h2>
                  }
                </Col>
                <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
                  <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                    setBooking(undefined)
                    setIsCreateModal(true)
                  }}>Create Booking</Button>
                </Col>
              </Row>
  
            <Card style={{ marginBottom: 24, backgroundColor: '#F8F8F8'}}>
                <Form layout="inline" onSubmit={() => { }}>
                  <Form.Item>
                    <Input
                      name="keyword"
                      placeholder="Search Booking ID, Customer, Staff"
                      onChange={e => setKeyword(e.target.value) }
                      style={{ width: 200 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <RangePicker placeholder={['From date', 'To date']} 
                      onChange={value => setDates(value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" onClickCapture={onSubmitSearch} icon={<SearchOutlined />}>Search</Button>
                  </Form.Item>
                </Form>
              </Card>
              {isSearch === false ? 
              <>
              <Tabs
              type="card"
              tabBarExtraContent={(
                <div style={{ flexWrap: 1, flexDirection: 'column' }}>
                  <Button
                    onClick={() => { handelChangeRangeDate(-7); }}
                  ><CaretLeftOutlined /></Button>
                  <DatePicker
                    allowClear={false}
                    picker="week"
                    value={defaultValue}
                    onChange={onChangeRangeDate}
                  />
                  <Button
                    onClick={() => { handelChangeRangeDate(7); }}
                  ><CaretRightOutlined /></Button>
                </div>
              )}
              onChange={handleOnChangeTab}
            >
              
              {
                createTab(salon.days)
              }
              </Tabs>
              <Table
                columns={columns}
                dataSource={nailists}
                //loading={isLoading || isLoadingBookings}
                bordered
                scroll={{ x: 1300 }}
              />
              </>
              :
              <Table
                loading={loading}
                bordered
                columns={columnsSearch}
                dataSource={dataSource}
                onChange={(pagination, filters, sorter) => {
                  console.log('filters: ', filters)

                  //Status
                  if (filters.status !== null) {
                    setFiterStatus(filters.status)
                  } else {
                    setFiterStatus(null)
                  }

                  //System
                  if (filters.systemId !== null) {
                    setFilterSystem(filters.systemId)
                  } else {
                    setFilterSystem(null)
                  }
                  
                  setSortedInfo(sorter);
                  setCurrentPage(pagination.current);
                }
                }
                pagination={{
                  total: totalPage,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  pageSize: pageSize,
                  onShowSizeChange: (current, size) => { 
                    SetPageSize(size) 
                  },
                  current: currentPage
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      let bookingId = _.get(record, 'id');
                      dispatch(getPaymentByBookingId({ bookingId }))
                        .then(result => {
                          setPayment(result.data[0]);
                          openUpdateModal(bookingId);
                        }
                        )
                      
                    }
                  };
                }}
              />
              }
            </Card>
            <Modal
              visible={isCreateModal}
              title='Create Booking'
              onCancel={() => {
                Modal.confirm({
                  title: 'Confirm',
                  icon: <ExclamationCircleOutlined />,
                  content: 'This information will be canceled?',
                  okText: 'Sure',
                  cancelText: 'No',
                  onOk: () => {
                    setIsCreateModal(false)
                  }
                });
              }}
              destroyOnClose={true}
              width={980}
              centered
              footer={null}
            >  
              <BookingCreateForm 
                booking={booking} 
                onCancel={() => setIsCreateModal(false)}
              />
            </Modal>
            <Modal
            visible={isUpdateModal}
            title={`Booking Detail`}
            onCancel={() => setIsUpdateModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
            <BookingViewForm 
              booking={booking} 
              payment={payment}
              onCancel={()=> setIsUpdateModal(false)} 
              onCallback={() => onSubmitSearch()}  
            />
          </Modal>
          <Modal
            visible={isCancelModal}
            title='Cancel Booking'
            onCancel={() => {
              setIsCancelModal(false)
            }}
            destroyOnClose={true}
            width={380}
            centered
            footer={null}
          >  
            <BookingCancelForm 
              bookingId={booking?.bookingId} 
              onClose={() => setIsCancelModal(false)}
              onCallback={() => {
                setIsCancelModal(false)
                onSubmitSearch()
              }}
            />
          </Modal>
        </div>
      </Layout>
    </Layout>
  )
};

export default Bookings;