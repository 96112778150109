import React from 'react';
import { Button, Form, Input, Col, Row, Select, Divider, Space, InputNumber } from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { serviceCreate } from 'providers/ServiceProvider/actions';

const { Option } = Select;

const ServiceAddForm = ({ categoryId, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    categories = {}
  } = useSelector((state) => state.category);
  return (
    <Form
        form={form}
        layout="vertical"
        name="serviceAddForm"
        onFinish={values => 
          dispatch(serviceCreate(
            {values, categoryId}
          )).then(()=> onCancel())
        }
        initialValues={{
          services: [{
            name: '',
            duration: '',
            price: '',
            description: ''
          }],
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.List name="services">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map(field => (
                      <div key={field.key}>
                        <Row gutter={24}>
                          <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                            <Form.Item 
                              {...field}
                              name={[field.name, 'category']}
                              fieldKey={[field.fieldKey, 'category']}
                              label="Service Category"
                            >
                              <Select
                                defaultValue={categoryId}
                                disabled
                                showSearch
                                placeholder="Service Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {categories.map((item) => {
                                  return (
                                    <Option value={item.id}>{item.name}</Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                            <Form.Item 
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Please input the Service Name!' }]}
                              label="Service Name"
                              >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 2 }} xs={{ span: 24 }}>
                            {fields.length !== 1 && (
                            <DeleteFilled
                              style={{marginTop: 40}}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                            )}
                          </Col>
                        </Row>
                        <Row gutter={24} type="flex">
                          <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                            <Form.Item 
                              {...field}
                              name={[field.name, 'duration']}
                              fieldKey={[field.fieldKey, 'duration']}
                              rules={[{ required: true, message: 'Please select a Duration!' }]}
                              label="Duration (min)"
                            >
                              <Select
                                showSearch
                                placeholder="Duration"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value={10}>10</Option>
                                <Option value={20}>20</Option>
                                <Option value={30}>30</Option>
                                <Option value={40}>40</Option>
                                <Option value={50}>50</Option>
                                <Option value={60}>60</Option>
                                <Option value={70}>70</Option>
                                <Option value={80}>80</Option>
                                <Option value={90}>90</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col lg={{ span: 11 }} xs={{ span: 24 }}>
                            <Form.Item 
                              {...field}
                              name={[field.name, 'price']}
                              fieldKey={[field.fieldKey, 'price']}
                              rules={[{ required: true, message: 'Please input the Price!' }]}
                              label="Price"
                            >
                              <InputNumber min={0} max={999999} style={{width:'100%'}} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24} type="flex">
                          <Col lg={{ span: 22 }} xs={{ span: 24 }}>
                            <Form.Item 
                              {...field}
                              name={[field.name, 'description']}
                              fieldKey={[field.fieldKey, 'description']}
                              label="Description"
                            >
                              <Input.TextArea />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    ))}
                    <Space style={{ display: 'flex', marginBottom: -10, marginTop: -10, marginLeft: -15 }} align="start">
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> <strong>Add Service</strong>
                        </Button>
                      </Form.Item>
                    </Space>
                  </div>
                );
              }}
            </Form.List>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    
  );
};

export default ServiceAddForm;