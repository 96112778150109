import {
  serviceCreateSuccess,
  serviceCreateFail,
  serviceUpdateSuccess,
  serviceUpdateFail,
  serviceOrderSuccess,
  serviceOrderFail,
  serviceDeleteSuccess,
  serviceDeleteFail,
  //categoryList as categoryListAction,
} from './actions';

import {
  categoryList as categoryListAction,
} from '../CategoryProvider/actions';

import {
  SERVICE_CREATE,
  SERVICE_UPDATE,
  SERVICE_ORDER,
  SERVICE_DELETE
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function* serviceCreate(action) {
  try {
    const { categoryId, values } = action.payload;
    
    for (let i = 0; i < values.services.length; i++) {
      yield call(request, environment.api.serviceCreate,{...values.services[i], categoryId});
    }

    yield put(serviceCreateSuccess({}, action.meta));
    yield put(categoryListAction());
    Alert.success('Create successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(serviceCreateFail());
    console.log(error);
  }
}

function* serviceUpdate(action) {
  try {
    const { id, values } = action.payload;
    console.log('serviceUpdate.action.payload: ', action.payload)
    yield call(request, `${environment.api.serviceUpdate}/${id}`, values, 'PUT');
    yield put(serviceUpdateSuccess({}, action.meta));
    yield put(categoryListAction());
    Alert.success('Update successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(serviceUpdateFail());
    console.log(error);
  }
}

function* serviceOrder(action) {
  try {
    const { oldItem, newItem } = action.payload;
    console.log('serviceOrder.oldItem: ', oldItem)
  
    yield call(request, `${environment.api.serviceUpdate}/${oldItem.id}`, {
      description: oldItem.description,
      name: oldItem.name,
      order: newItem.order,
      categoryId: oldItem.categoryId,
      duration: oldItem.duration,
      price: oldItem.price,
    }, 'PUT');

    console.log('serviceOrder.newItem: ', newItem)
    yield call(request, `${environment.api.serviceUpdate}/${newItem.id}`, {
      description: newItem.description,
      name: newItem.name,
      order: oldItem.order,
      categoryId: newItem.categoryId,
      duration: newItem.duration,
      price: newItem.price,
    }, 'PUT');
    
    yield put(serviceOrderSuccess({}, action.meta));
    yield put(categoryListAction());
    //Alert.success('Update successfully');
  } catch (e) {
    Alert.error('Happen errors!');
    yield put(serviceUpdateFail());
    console.log(e);
  }
}

function* serviceDelete(action) {
  try {
    yield call(request, `${environment.api.serviceDelete}/${action.payload}`, {}, 'DELETE');
    yield put(serviceDeleteSuccess());
    yield put(categoryListAction());
    Alert.success('Delete successfully');
  } catch (error) {
    Alert.error(error.message);
    yield put(serviceDeleteFail());
  }
}

export default function* watchNailist() {
  yield takeLeading(SERVICE_CREATE, serviceCreate);
  yield takeEvery(SERVICE_UPDATE, serviceUpdate);
  yield takeEvery(SERVICE_ORDER, serviceOrder);
  yield takeLeading(SERVICE_DELETE, serviceDelete);
}