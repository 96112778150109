import environment from './base';
const baseURL = 'https://server.slb-cb.scrum-dev.com/';
const env = environment(baseURL);
export default {
  ...env,
  api: {
    ...env.api,
    auth0Domain: 'c2c-cb.us.auth0.com', //auth0Domain:"nailie-salonboard.us.auth0.com"
    auth0ClientId: '3Jttr8Yn5tA4I5e7CTZ6aMQazUt3q6M2',
    auth0Audience: 'https://c2c-cb.us.auth0.com/api/v2/',
    auth0RedirectUri: 'https://slb-cb.scrum-dev.com/auth0',
  },
  isDevelopment: false,
  isStaging: true,
  isProduction: false,
};