import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import { customerList } from 'providers/CustomerProvider/actions';

import { Layout, Modal, Input, Button, Breadcrumb, Menu, Table, Avatar, Divider} from 'antd';
import { Card, Row, Col, Form } from 'antd';
import { PlusOutlined, PictureOutlined } from '@ant-design/icons';
import './styles.less';
import _, { result } from 'lodash';
import moment from 'moment';

import CustomerCreateForm from 'components/Desktop/Customer/CustomerCreateForm';
import MenuSliderLeft from 'components/Desktop/Salon/MenuSliderLeft';

const { Search } = Input;
const { Content, Sider } = Layout;
const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};
const CustomerList = () => {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, SetPageSize] = useState(10);
  const [total, setTotal] = useState('');

  const { salon } = useSelector(state => state.salon);
  // const customerCreated = (newCustomer) => {
  //   setCustomers([newCustomer, ...customers])
  // };

  useEffect(() => {
    setLoading(true);
    dispatch(customerList({ currentPage, pageSize, sortedInfo, searchValue }))
      .then(result => {
        setLoading(false);
        const { total } = result.customers.data.pagination;
        const { data } = result.customers.data;
        setTotal(total);
        setCustomers(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
  }, [currentPage, pageSize, sortedInfo.order, sortedInfo.columnKey]);


  const onSearchChange = (value) => {
    setSearchValue(value);
    setCurrentPage(1);
    dispatch(customerList({ currentPage: 1, sortedInfo, searchValue: value }))
      .then(result => {
        setLoading(false);
        const { total } = result.customers.data.pagination;
        const { data } = result.customers.data;
        setTotal(total);
        setCustomers(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
  }

  const columns = [
    {
      title: 'Add date',
      align: 'center',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '11%',
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
          {moment(record.createdAt).format('HH:mm YYYY-MM-DD')}
          </div>
         
        );
      }
    },
    {
      title: 'Customer name',
      dataIndex: 'name',
      defaultSortOrder: 'descend',
      key: 'name',
      width: '12%',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '12%'
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      width: '12%'
    },

    {
      title: 'Total purchase',
      align: 'center',
      width: '10%',
      dataIndex: 'totalPurchase'
    },
    {
      title: 'Ranking',
      align: 'center',
      width: '10%',
      dataIndex: 'ranking'
    }

  ];

  return (
    <Layout className="customer-page">

      <MenuSliderLeft salon={salon} menuItem="3" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon">My Salon</a></Breadcrumb.Item>
          <Breadcrumb.Item>Customers</Breadcrumb.Item>
        </Breadcrumb>
        <div className='customer-listing-page'>
          <Card style={{ marginBottom: 18 }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>Customers</h2>
              </Col>
              <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => setIsCreateModal(true)}><PlusOutlined />Add Customer</Button>
              </Col>
            </Row>
            <Card style={{ marginBottom: 24, backgroundColor: '#F8F8F8'}}>
              <Form layout="inline" onSubmit={() => { }}>
                <Form.Item>
                  <Search
                    name="keyword"
                    placeholder="Customer Name,Email,Phone ..."
                    style={{ width: 320 }}
                    onSearch={value => onSearchChange(value)}
                    allowClear
                    enterButton="Search"
                  />
                </Form.Item>

              </Form>
            </Card>

            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={customers}
              onChange={(pagination, filters, sorter) => {
                if (sorter.order) {
                  setSortedInfo(sorter);
                }
                setCurrentPage(pagination.current);
              }}
              pagination={{
                total,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                pageSize: pageSize,
                onShowSizeChange: (current, size) => { SetPageSize(size) },
                current: currentPage
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    let customerId = _.get(record, 'id');
                    navigate(`/mysalon/customers/${customerId}`);

                  }
                };
              }}
            />

          </Card>
        </div>

        <Modal
          visible={isCreateModal}
          title="Add Customer"
          onCancel={() => setIsCreateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <CustomerCreateForm
            onCancel={() => setIsCreateModal(false)}
            // customerCreated={customerCreated}

            onCallBack={() =>
              dispatch(customerList({ currentPage, pageSize, sortedInfo, searchValue }))
                .then(result => {
                  setLoading(false);
                  const { total } = result.customers.data.pagination;
                  const { data } = result.customers.data;
                  setTotal(total);
                  setCustomers(data);
                })
                .catch((error) => {
                  setLoading(false);
                  console.log('Oops!', error)
                })
            }
          />
        </Modal>
      </Layout>
    </Layout>
  )
};

export default CustomerList;