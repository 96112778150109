import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Alert, Spin } from 'antd';

import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { loginSuccess, setCurrentUser } from 'providers/AuthProvider/actions';

import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from 'jwt-decode';

import logo from 'assets/images/logo-c2c.png';
import './styles.less';

const Auth0 = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState();
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loading, setLoaing] = useState(true)
  
  console.log('Auth0.isAuthenticated: ', isAuthenticated)
  console.log('Auth0.user: ', user)

  useEffect(() => {
    //Auth0
    // Wait for the Auth0 SDK to finish loading and authenticated
    // Ref: https://github.com/auth0/auth0-react/issues/100#issuecomment-679253563
    if (isLoading && !isAuthenticated) return;
    
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const user = jwt_decode(accessToken)
        dispatch(loginSuccess(accessToken, {}))
        dispatch(setCurrentUser(user))
        localStorage.setItem('__a_token', accessToken);

        if (user['https://user_metadata'].logged_in === true) { //First Login
          navigate(`/complete-profile`);
        } else {
          navigate(`/mysalon`);
        }
      } catch (e) {
        setLoaing(false)
        setError(e.message)
        console.log(e);
      }
    };
    
    getUserMetadata();
  }, [isAuthenticated, isLoading]);

  return (
    <div className="login-page">
      <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="login-page-row" >
        <Col xs={{ span: 24 }}>
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 46 }} />
            <h3 className="login-title">Controller Board</h3>
          </div>
          <div>
            {loading === false ?
            <Form name="loginForm">
              {error && (
                <Alert
                  description={error}
                  type="error"
                />)
              }
              <Form.Item style={{marginTop: 10}}>
                <a className="request-form-forgot" href="/login">
                  Back to login
                </a>
              </Form.Item>
            </Form>
            :
            <p style={{textAlign: 'center'}}><Spin /><br />
              Waiting ...
            </p>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Auth0;