import React from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, Avatar, Affix, Divider, Menu } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const { Sider } = Layout;

const MenuSiderLeft = ({salon, menuItem}) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    
    return (
    <Sider style={{ backgroundColor: '#fff', minHeight: document.documentElement.clientHeight }}>
        <Affix offsetTop={66}>
          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
              {(_.get(salon, 'logoUrl') === null || _.get(salon, 'logoUrl') === undefined) ?
                <Avatar icon={<PictureOutlined />} shape="circle" size={84} style={{ marginBottom: 10 }} />
                :
                <Avatar src={_.get(salon, 'logoUrl')} shape="circle" size={84} style={{ marginBottom: 10 }} />
              }
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <span style={{ fontWeight: '600' }}>{_.get(salon, 'businessName')}</span>
                <span style={{ color: '#a7a7a7' }}>Branch 1</span>
              </div>
            </Col>
          </Row>
          <Divider />
          <Menu
            mode="inline"
            defaultSelectedKeys={[menuItem]}
            className="mysalon-sider-menu"
          >
            <Menu.Item key="1" onClick={() => navigate(`/mysalon`)}>{t('Report')}</Menu.Item>
            <Menu.Item key="2" onClick={() => navigate(`/mysalon/services`)}>{t('Services')}</Menu.Item>
            <Menu.Item key="3" onClick={() => navigate(`/mysalon/customers`)}>{t('Customers')}</Menu.Item>
            <Menu.Item key="4" onClick={() => navigate(`/mysalon/settings`)}>{t('Settings')}</Menu.Item>
          </Menu>
        </Affix>
      </Sider>
    );
};

export default MenuSiderLeft;

