import React, {useState, useEffect} from 'react';
import { Button, Form, Input, Col, Row, DatePicker, Checkbox } from 'antd';

import moment from 'moment';

import { Select, Space, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';
import { staffScheduleUpdate } from 'providers/StaffProvider/actions';

import './styles.less';

const { Option } = Select;

const StaffScheduleEditForm = ({ staffId, staffDayInWeek, scheduleDayInWeek, staffCurrentDate, onCancel, onCallback }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const disabledDate = (current) => {
    // Can not select days before today-1 and today-1
    return current && current < moment().endOf('day').subtract(1, 'd');;
  }

  const [isError, setIsError] = useState(false)
  const [isErrorInline, setIsErrorInline] = useState()

  const [repeats, setRepeats] = useState();
  const [isAbsent, setIsAbsent] = useState()
  
  const slots = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 30,
      label: '00:30'
    },
    {
      value: 100,
      label: '01:00'
    },
    {
      value: 130,
      label: '01:30'
    },
    {
      value: 200,
      label: '02:00'
    },
    {
      value: 230,
      label: '02:30'
    },
    {
      value: 300,
      label: '03:00'
    },
    {
      value: 330,
      label: '03:30'
    },
    {
      value: 400,
      label: '04:00'
    },
    {
      value: 430,
      label: '04:30'
    },
    {
      value: 500,
      label: '05:00'
    },
    {
      value: 530,
      label: '05:30'
    },
    {
      value: 600,
      label: '06:00'
    },
    {
      value: 630,
      label: '06:30'
    },
    {
      value: 700,
      label: '07:00'
    },
    {
      value: 730,
      label: '07:30'
    },
    {
      value: 800,
      label: '08:00'
    },
    {
      value: 830,
      label: '08:30'
    },
    {
      value: 900,
      label: '09:00'
    },
    {
      value: 930,
      label: '09:30'
    },
    {
      value: 1000,
      label: '10:00'
    },
    {
      value: 1030,
      label: '10:30'
    },
    {
      value: 1100,
      label: '11:00'
    },
    {
      value: 1130,
      label: '11:30'
    },
    {
      value: 1200,
      label: '12:00'
    },
    {
      value: 1230,
      label: '12:30'
    },
    {
      value: 1300,
      label: '13:00'
    },
    {
      value: 1330,
      label: '13:30'
    },
    {
      value: 1400,
      label: '14:00'
    },
    {
      value: 1430,
      label: '14:30'
    },
    {
      value: 1500,
      label: '15:00'
    },
    {
      value: 1530,
      label: '15:30'
    },
    {
      value: 1600,
      label: '16:00'
    },
    {
      value: 1630,
      label: '16:30'
    },
    {
      value: 1700,
      label: '17:00'
    },
    {
      value: 1730,
      label: '17:30'
    },
    {
      value: 1800,
      label: '18:00'
    },
    {
      value: 1830,
      label: '18:30'
    },
    {
      value: 1900,
      label: '19:00'
    },
    {
      value: 1930,
      label: '19:30'
    },
    {
      value: 2000,
      label: '20:00'
    },
    {
      value: 2030,
      label: '20:30'
    },
    {
      value: 2100,
      label: '21:00'
    },
    {
      value: 2130,
      label: '21:30'
    },
    {
      value: 2200,
      label: '22:00'
    },
    {
      value: 2230,
      label: '22:30'
    },
    {
      value: 2300,
      label: '23:00'
    },
    {
      value: 2330,
      label: '23:30'
    },
  ]

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;
  
    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );
  
    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];
  
      if (currentEndTime > nextStartTime) {
        return true;
      }
    }
  
    return false;
  };

  console.log('scheduleDayInWeek: ', scheduleDayInWeek)

  useEffect(() => {
    if ((moment(scheduleDayInWeek[0]?.startAt).format('HH:mm') === '00:00') && (moment(scheduleDayInWeek[0]?.endAt).format('HH:mm') === '00:00')) {
      setIsAbsent(true)
    }
  }, []);

  console.log('isAbsent: ', isAbsent)

  return (
    <Form
        className="staff-form"
        form={form}
        {...layout}
        name="staffScheduleEditForm"
        onValuesChange={(_, values) => {
          //console.log(values)

          if (isAbsent === false) {
            let errorInline = false
            values.schedules.forEach(element => {
              if (element?.startAt >= element?.endAt) {
                errorInline = true
              }
            });
            if (errorInline === true) {
              setIsErrorInline('Start time must be greater than end time!')
            } else {
              setIsErrorInline()
            }
          }

        }}
        onFinish={values => {
          console.log('staffScheduleEditForm: ', values)
          
          let validate = values.schedules.map(item => {
            let startHH = Math.floor(item.startAt / 100);
            let startmm = item.startAt - startHH * 100;
            const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;
      
            let endHH = Math.floor(item.endAt / 100);
            let endmm = item.endAt - endHH * 100;
            const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;
      
            return [startHHmm, endHHmm]
          })
          
          if (checkOverlap(validate)) {
            setIsError(true)
          } else { //Update
            setIsError(false)

            let dayInWeek

            switch (staffDayInWeek) {
              case 'monday':
                dayInWeek = 1;
                break
              case 'tueday':
                dayInWeek = 2;
                break
              case 'wednesday':
                dayInWeek = 3;
                break
              case 'thursday':
                dayInWeek = 4;
                break
              case 'friday':
                dayInWeek = 5;
                break
              case 'saturday':
                dayInWeek = 6;
                break
              case 'sunday':
                dayInWeek = 0;
                break
              default:
                return dayInWeek = 0;
            }
            
            //schedules
            let schedules = []
            
            if (values.isAbsent === true) {
              let startAt = moment(`${staffCurrentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
              //let endAt = moment(`${staffCurrentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

              let item = {
                dayOfWeek: dayInWeek,
                startAt,
                endAt: startAt,
                isRepeat: 0,
                startSchedule: startAt,
                endSchedule: startAt
              }
              schedules.push(item)
            } else {
              values.schedules.forEach(element => {
                let startAt = moment(`${staffCurrentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
                let endAt = moment(`${staffCurrentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

                let item = {
                  dayOfWeek: dayInWeek,
                  startAt,
                  endAt,
                  isRepeat: values.isRepeat,
                  startSchedule: startAt,
                  endSchedule: values.endDate
                }
                schedules.push(item)
              });
            }

            dispatch(staffScheduleUpdate({
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              schedules,
              staffId: staffId
            }))
            .then((result) => {
              onCallback()
            }).catch((error) => {
              console.log('Oops!', error)
            })

          }
        }
        }
        initialValues={{
          isRepeat: 0,
          isAbsent: (moment(scheduleDayInWeek[0]?.startAt).format('HH:mm') === '00:00') && (moment(scheduleDayInWeek[0]?.endAt).format('HH:mm') === '00:00') ? true : false,
          schedules: scheduleDayInWeek.length === 0 ? 
          [
            {
              startAt: 800,
              endAt: 1700
            }
          ]: scheduleDayInWeek.map(item => {
            let startAt = parseInt(moment(item.startAt).format('HHmm'));
            let endAt = parseInt(moment(item.endAt).format('HHmm'));

            return {
              startAt,
              endAt
            }
      
          })
        }}
      >
        <Row>
          <Col span={24} style={{fontWeight: '600', marginBottom: 10}}>Working time:</Col>
        </Row>
        <Row gutter={24} style={{marginTop: 0, paddingLeft: 24}}>
          <Col span={24}>
            <Form.List name="schedules">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    return (
                      <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                        <Form.Item
                          {...field}
                          name={[field.name, 'startAt']}
                          label={'Start Time:'}
                          fieldKey={[field.fieldKey, 'startAt']}
                          rules={[
                            {
                              required: true, 
                              message: 'Start time is required!'
                            },
                          ]}
                        >
                          <Select
                            disabled={isAbsent}
                            style={{width: 120, marginLeft: 24}}
                          >
                            {slots.map((item) => {
                              return (
                                <Option value={item.value}>{item.label}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'endAt']}
                          label={'End Time:'}
                          fieldKey={[field.fieldKey, 'endAt']}
                          rules={[
                            {
                              required: true, 
                              message: 'End time is required!'
                            },
                          ]}
                        >
                            <Select
                              disabled={isAbsent}
                              style={{width: 120, marginLeft: 24}}
                            >
                            {slots.map((item) => {
                              return (
                                <Option value={item.value}>{item.label}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                        {fields.length !== 1 && (
                        <MinusCircleOutlined
                          disabled={isAbsent}
                          style={{marginTop: 10}}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                        )}
                      </Space>
                    )
                  }
                  )}
                  { isError === true && (
                    <Space style={{ display: 'flex', marginLeft: 102, marginTop: 0}} align="start">
                      <div className="staff-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 102, marginTop: 0}} align="start">
                      <div className="staff-schedule-error">{isErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 84}} align="start">
                    <Form.Item>
                      <Button
                        disabled={isAbsent}
                        type="link"
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
        </Row>

        <Row gutter={24} style={{marginTop: 24}}>
          <Col span={8}>
            <Form.Item
              name="isRepeat" 
              label="Repeats"
              labelAlign={'left'}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please select Repeats!'
              //   }
              // ]}
            >
              <Select
                disabled={isAbsent}
                style={{paddingLeft: 28, width: '100%'}}
              >
                <Option value={0}>Don't repeat</Option>
                <Option value={1}>Weekly on {staffDayInWeek}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Space style={{ display: 'flex', marginBottom: -15}} align="start">
              <Form.Item
                name="endRepeat" 
                label="End repeat"
              >
                <Select
                  disabled={isAbsent}
                  style={{width: 120, marginLeft: 24}}
                >
                  <Option value={0}>Ongoing</Option>
                  <Option value={1}>On date</Option>
                </Select>
              </Form.Item>
              <Form.Item 
                name="endDate"
                >
                <DatePicker 
                  disabled={isAbsent}
                  style={{ width: 200 }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24}>
            <Col span={12} style={{textAlign: 'left'}}>
              <Form.Item 
                name="isAbsent" 
                valuePropName="checked"
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setIsAbsent(true)
                  } else {
                    setIsAbsent(false)
                  }
                  }}
              >
                <Checkbox>Mark as absent</Checkbox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default StaffScheduleEditForm;