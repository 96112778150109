import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Divider, Avatar, Checkbox, Spin, Modal } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';

import { uploadImage, getAddressByPostCode } from 'providers/AuthProvider/actions';
import { salonAccountUpdate } from 'providers/SalonProvider/actions';


import moment from 'moment';
import _ from 'lodash';

const SalonAccountUpdateForm = ({ salon, onCallback }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(_.get(salon, 'logoUrl'))
  const [loading, setLoading] = useState(false)

  const handleUploadImg = () => e => {
    //Upload Image
    setLoading(true)
    dispatch(uploadImage({ file: e.target.files[0] }))
    .then(result => {
      setLogo(result)
      setLoading(false)
    })
    .catch(err => setLoading(false))
  };

  console.log('salon: ', salon)

  return (
    <Form
        form={form}
        name="completeForm"
        onFinish={values => {
          if (values.postCode !== '' && values.postCode !== undefined) {
            dispatch(getAddressByPostCode({postCode: values.postCode}))
            .then(result => {
              if (result.data.cityOrTown) {
                values = {
                  ...values,
                  cityOrTown: result.data.cityOrTown
                }
              }

              if (result.data.prefecture) {
                values = {
                  ...values,
                  prefecture: result.data.prefecture
                }
              }

              dispatch(salonAccountUpdate({
                ...values,
                salonId: _.get(salon, 'id'),
                logoUrl: logo,
                workingDays: salon.workingDays,
                business_hour_starttime: moment(`${new moment().format("YYYY/MM/DD")} ${_.get(salon, 'businessHour.startAt.hour')}:${_.get(salon, 'businessHour.startAt.minute')}`, "YYYY/MM/DD HH:mm"),
                business_hour_endtime: moment(`${new moment().format("YYYY/MM/DD")} ${_.get(salon, 'businessHour.endAt.hour')}:${_.get(salon, 'businessHour.endAt.minute')}`, "YYYY/MM/DD HH:mm"),
              }))
              .then((result) => {
                onCallback(result)
              }).catch((error) => {
                console.log('Oops!', error)
              })
            })
            .catch(error => {
              if (error.data.length > 0) {
                let msg = '';
                error.data.forEach((item) => {
                  msg = `${msg} ${item.message}. `
                })
                form.setFields([
                  {
                    name: 'postCode',
                    errors: [msg],
                  },
                ]);
                return;
              } else {
                form.setFields([
                  {
                    name: 'postCode',
                    errors: [error.message],
                  },
                ]);
              }

              return;
            })
          }
        }}
        // onValuesChange={(_, values) => {
        //   if (values.business_hour_starttime && values.business_hour_endtime && values.business_hour_starttime >= values.business_hour_endtime) {
        //     Modal.warning({
        //       content: 'Business Hour - End Time must be greater than Start Time!!',
        //     });
        //   }
        // }}
        initialValues={{
          businessName: _.get(salon, 'businessName'),
          email: _.get(salon, 'operatorEmail'),
          phone: _.get(salon, 'operatorPhoneNumber'),
          postCode: _.get(salon, 'location.postCode'),
          prefecture: _.get(salon, 'location.prefecture'),
          cityOrTown: _.get(salon, 'location.cityOrTown'),
          address: _.get(salon, 'location.address'),
          building_types_is_nail: _.get(salon, 'businessTypes') !== null && _.get(salon, 'businessTypes').includes('nail')? true : false,
          building_types_is_hair: _.get(salon, 'businessTypes') !== null && _.get(salon, 'businessTypes', []).includes('hair')? true : false,
          building_types_is_massage: _.get(salon, 'businessTypes') !== null && _.get(salon, 'businessTypes', []).includes('massage')? true : false,
          building_types_is_spa: _.get(salon, 'businessTypes') !== null && _.get(salon, 'businessTypes', []).includes('spa')? true : false,
          business_hour_starttime: moment(`${new moment().format("YYYY/MM/DD")} ${_.get(salon, 'businessHour.startAt.hour')}:${_.get(salon, 'businessHour.startAt.minute')}`, "YYYY/MM/DD HH:mm"),
          business_hour_endtime: moment(`${new moment().format("YYYY/MM/DD")} ${_.get(salon, 'businessHour.endAt.hour')}:${_.get(salon, 'businessHour.endAt.minute')}`, "YYYY/MM/DD HH:mm"),
        }}
      >
      <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Logo: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          {logo === undefined? 
            <Avatar icon={<PictureOutlined />} size={'large'} />
            :
            <Avatar size={'large'} src={logo} />
          }
          <label htmlFor="logo" className="ant-btn ant-btn-link">
            {loading === false? 
            `Change`
            :
            <Spin />
            }
          </label>
          <Input
            id='logo'
            name="logo"
            accept="image/*"
            type="file"
            hidden
            onChange={handleUploadImg()}
          />
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business name: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
            name="businessName"
            validateTrigger={'onBlur'}
            onKeyPress={(e) => {
              if (e.target.value.length >= 50) {
                e.preventDefault();
              }
            }}
            rules={[
              { 
                required: true, 
                message: 'Please input your Business Name!' 
              },
              {
                min: 2,
                message: "Please input more than 1 characters"
              },
              {
                max: 50,
                message: "Please input less than 50 characters"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Email: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="email">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Phone: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item name="phone">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Post Code: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
            name="postCode"
            validateTrigger={'onBlur'}
            rules={[
              { 
                required: true, 
                message: 'Please input a Post Code!' 
              },
              {
                min: 1,
                message: "Please input more than 1 characters"
              },
              {
                max: 7,
                message: "Please input less than 7 characters"
              }
            ]}
          >
            <Input 
              onKeyPress={(e) => {
                const key = e.which || e.key;
                if (e.target.value.length < 7) {
                  if (key < 48 || key > 57)
                    e.preventDefault();
                } else e.preventDefault();
              }}
              onBlur={e => {
                if (e.target.value !== '' && e.target.value !== undefined) {
                  dispatch(getAddressByPostCode({postCode: e.target.value}))
                  .then(result => {
                    if (result.data.cityOrTown) {
                      form.setFieldsValue({ cityOrTown: result.data.cityOrTown })
                    }

                    if (result.data.prefecture) {
                      form.setFieldsValue({ prefecture: result.data.prefecture })
                    }
                  })
                  .catch(error => {
                    form.setFieldsValue({ cityOrTown: '' })
                    form.setFieldsValue({ prefecture: '' })
                    if (error.data.length > 0) {
                      let msg = '';
                      error.data.forEach((item) => {
                        msg = `${msg} ${item.message}. `
                      })
                      form.setFields([
                        {
                          name: 'postCode',
                          errors: [msg],
                        },
                      ]);
                      
                    } else {
                      form.setFields([
                        {
                          name: 'postCode',
                          errors: [error.message],
                        },
                      ]);
                    }
                  })
                }
                else if (e.target.value === '') {
                  form.setFieldsValue({ cityOrTown: '' })
                  form.setFieldsValue({ prefecture: '' })
                }
            }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Prefectures: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
            name="prefecture"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>City and Town: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
            name="cityOrTown"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{marginTop: -4}}>Building Name/ Room number: </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item 
            name="address"
            rules={[{ required: true, message: 'Please input a Building Name/ Room number!' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business Hour: </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item 
            name="business_hour_starttime"
            rules={[{ required: true, message: 'Please input business hour Start Time!' }]}
          >
            <TimePicker
              style={{ width: 120 }}
              format={'HH:mm'}
              placeholder=""
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item 
            name="business_hour_endtime"
            rules={[{ required: true, message: 'Please input business hour End Time!' }]}
          >
            <TimePicker
              style={{ width: 120 }}
              format={'HH:mm'}
              placeholder=""
            />
          </Form.Item>
        </Col>
      </Row> */}
      <Divider />
      <Row gutter={24} style={{paddingRight: 10, paddingLeft: 10}}>
        <Col lg={{ span: 3 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Business Type: </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
              name="building_types_is_nail"
              label="Nail"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
              name="building_types_is_hair"
              label="Hair"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
              name="building_types_is_massage"
              label="Massage"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
              name="building_types_is_spa"
              label="Spa"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
      </Row>
            
      {/* <Divider />
      <Row gutter={24} style={{paddingRight: 0, paddingLeft: 10}}>
        <Col lg={{ span: 3 }} xs={{ span: 24 }} style={{paddingTop: 4}}>Working days: </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_mon"
              label="Mon"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_tue"
              label="Tue"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_wed"
              label="Wed"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_thu"
              label="Thu"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_fri"
              label="Fri"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_sat"
              label="Sat"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 3}} xs={{ span: 24 }}>
          <Form.Item
              name="working_days_is_sun"
              label="Sun"
              valuePropName="checked"
            >
              <Checkbox />
          </Form.Item>
        </Col>
      </Row>
       */}
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}> 
        <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
          <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
            <Button type="primary" htmlType="submit">
              Update and Publish
            </Button>
          </Col>
        </Row>
      </div>
    </Form> 
  );
};

export default SalonAccountUpdateForm;

