import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {isMobile} from "react-device-detect";

import './i18n';

//BEGIN DESKTOP
//Public
import HomeLayout from 'components/Desktop/Layout/Home';
import Login from 'pages/Desktop/Login';
import RequestAccount from 'pages/Desktop/RequestAccount';
import ForgotPassword from 'pages/Desktop/ForgotPassword';
import Auth0 from 'pages/Desktop/Auth0';
import CompleteProfile from 'pages/Desktop/CompleteProfile';

//Private
import MySalonLayout from 'components/Desktop/Layout/MySalon';
import MySalon from 'pages/Desktop/MySalon';
import Settings from 'pages/Desktop/MySalon/Settings';
import SalonAccount from 'pages/Desktop/MySalon/Settings/SalonAccount';

import Services from 'pages/Desktop/Services';
import CustomerList from 'pages/Desktop/Customers/CustomerList';
import CustomerInfo from 'pages/Desktop/Customers/CustomerInfo'
import StaffMember from 'pages/Desktop/Staffs/StaffMember';
import StaffSchedule from 'pages/Desktop/Staffs/StaffSchedule';
import StaffOverview from 'pages/Desktop/Staffs/StaffOverview';

import SampleParam from './pages/Desktop/SampleParam';
import StaffDetail from 'pages/Desktop/Staffs/StaffDetail';

import Bookings from 'pages/Desktop/Bookings';
import Scheduler from 'pages/Desktop/Scheduler';
//END DESKTOP

//BEGIN MOBILE
//Public
import HomeLayoutApp from 'components/Mobile/Layout/Home';
import LoginApp from 'pages/Mobile/Login';
import RequestAccountApp from 'pages/Mobile/RequestAccount';
import ForgotPasswordApp from 'pages/Mobile/ForgotPassword';

//Private
import MySalonLayoutApp from 'components/Mobile/Layout/MySalon';
import MySalonApp from 'pages/Mobile/MySalon';
//END MOBILE
//const store = initStore();
import store, { persistor } from '../src/redux/store';
import PaymentLists from 'pages/Desktop/Payments/PaymentList';

const App = () => (
  
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>Controller Board</title>
        </Helmet>
        {isMobile === true? 
        <Routes>
          <Route path="/" element={<HomeLayoutApp />}>
            <Route path="/" element={<LoginApp />} />
            <Route path="login" element={<LoginApp />} />
            <Route path="request-account" element={<RequestAccountApp />} />
            <Route path="forgot-password" element={<ForgotPasswordApp />} />
            <Route path="*" element={<LoginApp />} />
          </Route>
          <Route path="mysalon" element={<MySalonLayoutApp />}>
            <Route path="/" element={<MySalonApp />} />
            <Route path="services" element={<Services />} />
            <Route path="customers" element={<CustomerList />} />
            <Route path="customers/:id" element={<CustomerInfo />} />
            <Route path="staff-member" element={<StaffMember />} />
            <Route path="staff-member/:id" element={<StaffDetail />} />
            <Route path="staff-schedule" element={<StaffSchedule />} />
            <Route path="staff-overview" element={<StaffOverview />} />
            <Route path=":id" element={<SampleParam />} />
          </Route>
        </Routes>
        :
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="request-account" element={<RequestAccount />} />
            <Route path="auth0" element={<Auth0 />} />
            <Route path="complete-profile" element={<CompleteProfile />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Login />} />
          </Route>
          <Route path="mysalon" element={<MySalonLayout />}>
            <Route path="/" element={<MySalon />} />
            <Route path="settings" element={<Settings />} />
            <Route path="settings/salon-account" element={<SalonAccount />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="scheduler" element={<Scheduler />} />
            <Route path="services" element={<Services />} />
            <Route path="customers" element={<CustomerList />} />
            <Route path="customers/:id" element={<CustomerInfo />} />
            <Route path="staff-member" element={<StaffMember />} />
            <Route path="staff-member/:id" element={<StaffDetail />} />
            <Route path="staff-schedule" element={<StaffSchedule />} />
            <Route path="staff-overview" element={<StaffOverview />} />
            <Route path="payment" element={<PaymentLists />} />
            <Route path=":id" element={<SampleParam />} />
          </Route>
        </Routes>
        }
      </HelmetProvider>
    </BrowserRouter>
  
    </PersistGate>
  </Provider>

);

export default App;