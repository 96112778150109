import React from 'react';
import { Form, Input, Col, Row, DatePicker, Radio, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { customerCreate } from 'providers/CustomerProvider/actions';
import { getAddressByPostCode } from 'providers/AuthProvider/actions';

const CustomerCreateForm = ({ onCancel, onCallBack }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form
      form={form}
      {...layout}
      name="customerCreateForm"

      onFinish={values =>
      {
        if (values.postCode !== '' && values.postCode !== undefined) {
          dispatch(getAddressByPostCode({ postCode: values.postCode }))
            .then(result => {
              if (result.data.cityOrTown) {
                values = {
                  ...values,
                  cityOrTown: result.data.cityOrTown
                }
              }

              if (result.data.prefecture) {
                values = {
                  ...values,
                  prefecture: result.data.prefecture
                }
              }
              dispatch(customerCreate({ values }))
                .then((customer) => {
                  onCancel()
                  if (onCallBack !== undefined) {
                    onCallBack(customer)
                  }
                })
            })
            .catch(error => {
              if (error.data.length > 0) {
                let msg = '';
                error.data.forEach((item) => {
                  msg = `${msg} ${item.message}. `
                })
                form.setFields([
                  {
                    name: 'postCode',
                    errors: [msg],
                  },
                ]);
              } else {
                form.setFieldsValue({ postCode: '' })
                form.setFields([
                  {
                    name: 'postCode',
                    errors: [error.message],
                  },
                ]);
              }
              return;
            })
        } else 
        {
          dispatch(customerCreate({ values }))
            .then((customer) => {
              onCancel()
              if (onCallBack !== undefined) {
                onCallBack(customer)
              }
            })
          }
      
      }}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        gender: 0,
        birthday: '',
        area: undefined,
        prefecture: undefined,
        city: undefined,
        address: '',

      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            label="Customer name"
            rules={[
              {
                required: true,
                message: 'Please input the Customer name!'
              }
            ]}>
            <Input placeholder="Customer's name" />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="email"
            label="Email"

            rules={[
              {
                pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "The input is not valid Email !"
              }
            ]}>
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: 'Please input the Phone Number!'
              },
              {
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                message: "Not a valid Phone Number"
              },
              {
                min: 10,
                message: "Please type 10 or 11 numbers. Don't include (-)"
              }
            ]}
          >

            <Input
              placeholder="Phone number"
              style={{ width: '100%' }}
              onKeyPress={(e) => {
                const key = e.which || e.keyCode;
                if (e.target.value.length < 11) {
                  if (key < 48 || key > 57)
                    e.preventDefault();
                } else e.preventDefault();
              }}
            />

          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item name="gender" label="Gender">
            <Radio.Group>
              <Radio value={0}>Male</Radio>
              <Radio value={1}>Female</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="birthday"
            label="Birthday"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) {
                    const currentYear = new moment();
                    const ageOfBirth = currentYear.year() - value.year();
                    const monthOfBirth = (currentYear.month() + 1) - (value.month() + 1);
                    const dayOfBirth = currentYear.date() - value.date();

                    if ((ageOfBirth === 18 && ((monthOfBirth > 0) || ((monthOfBirth) === 0) && dayOfBirth >= 0)) || (ageOfBirth > 18)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The Birthday must be greater than 18 years!"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              popupStyle={{ transform: 'scale(1, 0.97)', translate: '(0, - 8)' }}
              showToday={false}
              style={{ width: '100%' }}
              placeholder="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="postCode"
            label="Post Code"
            validateTrigger={'onBlur'}
            rules={[
              {
                min: 1,
                message: "Please input more than 1 characters"
              },
              {
                max: 7,
                message: "Please input less than 7 characters"
              }
            ]}
          >
            <Input
              onKeyPress={(e) => {
                const key = e.which || e.key;
                if (e.target.value.length < 7) {
                  if (key < 48 || key > 57)
                    e.preventDefault();
                } else e.preventDefault();
              }}
              onBlur={e => {
                if (e.target.value !== '' && e.target.value !== undefined) {
                  dispatch(getAddressByPostCode({ postCode: e.target.value }))
                    .then(result => {
                      if (result.data.cityOrTown) {
                        form.setFieldsValue({ cityOrTown: result.data.cityOrTown })
                      }

                      if (result.data.prefecture) {
                        form.setFieldsValue({ prefecture: result.data.prefecture })
                      }
                    })
                    .catch(error => {
                      form.setFieldsValue({ cityOrTown: '' })
                      form.setFieldsValue({ prefecture: '' })
                      if (error.data.length > 0) {
                        let msg = '';
                        error.data.forEach((item) => {
                          msg = `${msg} ${item.message}. `
                        })
                        form.setFields([
                          {
                            name: 'postCode',
                            errors: [msg],
                          },
                        ]);

                      } else {
                        form.setFields([
                          {
                            name: 'postCode',
                            errors: [error.message],
                          },
                        ]);
                      }
                    })
                }
                else if (e.target.value === '' ) {
                  form.setFieldsValue({ cityOrTown: '' })
                  form.setFieldsValue({ prefecture: '' })
                }
              }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="prefecture"
            label="Prefectures"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="cityOrTown"
            label="City and Town"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="address"
            className="two-line-label"
            label={<span>Building name/<br /> room number</span>}
          >
            <Input />
          </Form.Item>
        </Col>

      </Row>
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
          </Button>
            <Button type="primary" htmlType="submit">
              Add Customer
          </Button>
          </Col>
        </Row>
      </div>
    </Form >

  );
};

export default CustomerCreateForm;