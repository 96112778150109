import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import moment from 'moment';

import { Layout, Modal, Input, Button, Breadcrumb, Table, Avatar } from 'antd';
import { Card, Row, Col, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fetchStaffList } from 'providers/StaffProvider/actions';

import StaffCreateForm from 'components/Desktop/Staff/StaffCreateForm';
import MenuSiderLeft from 'components/Desktop/Staff/MenuSliderLeft';

import './styles.less';

const { Search } = Input;
const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};

const StaffMember = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [filterStatus, setFiterStatus] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, SetPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [totalPage, setTotalalPage] = useState('');

  const lang = {
    addStaff: t('Add Staff'),
    addDate: t('Add date'),
    staffName: t('Staff name'),
    email: t('Email'),
    phone: t('Phone'),
    address: t('Address'),
    status: t('Status'),
    active: t('Active'),
    inActive: t('Inactive')
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(fetchStaffList({ currentPage, pageSize, filterStatus, sortedInfo, searchValue }))
      .then(result => {
        setLoading(false);
        const { total } = result.data.pagination;
        const { data } = result.data;
        setTotalalPage(total);
       setStaffs(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })

  }, [currentPage, filterStatus, sortedInfo.columnKey, sortedInfo.order, pageSize]);


  const onSearchChange = (value) => {
    setSearchValue(value);
    setCurrentPage(1);
    dispatch(fetchStaffList({ currentPage: 1, pageSize, sortedInfo, searchValue: value, filterStatus }))
      .then(result => {
        setLoading(false);
        const { total } = result.data.pagination;
        const { data } = result.data;
        setTotalalPage(total);
        setStaffs(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
  }
  //staffCreate
  const [isStaffCreateModal, setIsStaffCreateModal] = useState(false);
  const staffCreated = (newStaff) => {
    setStaffs([newStaff, ...staffs])
  };

  const columns = [
    {
      title: lang.addDate,
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      width: '150',
      render: (record) => (moment(record).format('HH:mm YYYY-MM-DD')),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
      title: lang.staffName,
      defaultSortOrder: 'descend',
      key: 'name',
      with: '200',
      render: (record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(record.avatar === null || record.avatar === undefined) ?
              <Avatar src="https://iupac.org/wp-content/uploads/2018/05/default-avatar.png" size='large' style={{ marginRight: 8 }} />
            : 
              <Avatar src={record.avatar} size='large' style={{ marginRight: 8 }} />
            }
            {record.name}
          </div >
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],

    },
    {
      title: lang.email,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: lang.phone,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: lang.address,
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      render: (record) => {
       return (<div > {_.get(record, 'address', '')},{_.get(record, 'cityOrTown', '')},{_.get(record, 'prefecture', '')},{_.get(record, 'postCode', '')}</div>)
      }
    },
    {
      title: lang.status,
      dataIndex: 'active',
      key: 'active',
      width: '5%',
      render: (record) => {
        switch (record) {
          case true:
            return (<div className="status-block active">{lang.active}</div>);
            break;
          case false:
            return (<div className="status-block inactive">{lang.inActive}</div>);
            break;
          default:
            return (<div className="status-block inactive">{lang.inActive}</div>);
        }
      },

      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ]
    }
  ];

  return (
    <Layout>
      <MenuSiderLeft menuItem="1" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon/staff-member">Staff</a></Breadcrumb.Item>
          <Breadcrumb.Item>Staff Member</Breadcrumb.Item>
        </Breadcrumb>
        <div className='staffmember-page'>
          <Card style={{ marginBottom: 18 }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>Staff Member</h2>
              </Col>
              <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClickCapture={() => setIsStaffCreateModal(true)}
                ><PlusOutlined />{lang.addStaff}</Button>
              </Col>
            </Row>
          
            < Card style={{ marginBottom: 16, backgroundColor: '#F8F8F8'}}>
              <Form layout="inline" onSubmit={() => { }}>
                <Form.Item>
                  <Search
                    name="keyword"
                    placeholder="Staff Name,email,phone"
                    onSearch={value => onSearchChange(value)}
                    style={{ width: 300 }}
                    allowClear
                    enterButton="Search"
                  />
                </Form.Item>
              </Form>
            </Card>

            <Table
              loading={loading}
              columns={columns}
              bordered
              dataSource={staffs}
              onChange={(pagination, filters, sorter) => {
                setFiterStatus(filters)
                if (sorter.order) {
                  setSortedInfo(sorter);
                }
                setCurrentPage(pagination.current);
              }
              }
              pagination={{
                total: totalPage,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                pageSize: pageSize,
                onShowSizeChange: (current, size) => { SetPageSize(size) },
                current: currentPage
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    let staffId = _.get(record, 'id');
                    navigate(`/mysalon/staff-member/${staffId}`);

                  }
                };
              }}
            />
          </Card>
        </div>
        <Modal
          visible={isStaffCreateModal}
          title={lang.addStaff}
          onCancel={() => setIsStaffCreateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <StaffCreateForm
            onCancel={() => setIsStaffCreateModal(false)}
            onCallBack={
              () => {
                dispatch(fetchStaffList({ currentPage, pageSize, filterStatus, sortedInfo, searchValue }))
                  .then(result => {
                    setLoading(false);
                    const { total } = result.data.pagination;
                    const { data } = result.data;
                    setTotalalPage(total);
                    setStaffs(data);
                  })
              }
            }
          />
        </Modal>
      </Layout>
    </Layout>
  )
};

export default StaffMember;