import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row, Select, Divider, Card, InputNumber, message } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { fetchStaffList } from 'providers/StaffProvider/actions';
import { bookingDetail, bookingUpdate, getPaymentByBookingId, quickPayment } from 'providers/BookingProvider/actions';
import { customerList } from 'providers/CustomerProvider/actions';

import BookingCancelForm from 'components/Desktop/Booking/BookingCancelForm';
import { formatYen } from 'utils/stringFormat';
import PaymentBillingForm from '../Payment/PaymentBillingForm';
import PaymentDetailForm from '../Payment/PaymentDeatailForm';

const { Option } = Select;

const BookingViewForm = ({ booking, payment, onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [formDiscount] = Form.useForm();

  const dispatch = useDispatch();

  const [isCreateDiscountModal, setIsCreateDiscountModal] = useState(false);
  const [isOpenBillingModal, SetIsOpenBillingModal] = useState(false);

  const [bookingId, setBookingId] = useState();
  const [customer, setCustomer] = useState();
  const [discount, setDiscount] = useState();
  const [grandAmountToal, setGrandAmountToal] = useState(0);
  const [grandMinuteToal, setGrandMinuteToal] = useState(0);
  const [total, setTotal] = useState(0);
  const [cancellationFee, setCancellationFee] = useState(0);
  const [charge, setCharge] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState([]);
  const { categories = [] } = useSelector((state) => state.category);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isViewDetail, SetIsViewDetail] = useState(false);

  useEffect(() => {
    dispatch(fetchStaffList({ currentPage: 1, pageSize: 10000000 }));
    dispatch(customerList());
    setBookingId(booking.bookingId)
    setCustomer(booking.customer)
    setDiscount(booking.discount === 0 ? undefined : booking.discount)
    setGrandAmountToal(booking.totalPrice)
    setGrandMinuteToal(booking.totalDuration)
    setTotal(booking.finalPrice)

    setCancellationFee(booking.cancellationFee)
    setCharge(booking.charge)

  }, []);
  useEffect(() => {
    const bookingId = booking?.bookingId
    dispatch(bookingDetail({ bookingId }))
      .then(result => {
        setBookings(result.data)
      }
      )

  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return 'New';
        break;
      case 1:
        return 'Cancel';
        break;
      case 2:
        return 'Arrived';
        break;
      case 3:
        return 'Started';
        break;
      case 4:
        return 'Done';
        break;
      default:
        return 'New';
    }
  }

  return (
    <Form
      form={form}
      name="bookingViewForm"
      onFinish={values =>
        dispatch(bookingUpdate({ booking, status: values.status, discount, totalPrice: total }))
          .then(() => {
            onCancel()
            onCallback()
          })
      }
      onValuesChange={(_, values) => {
        let totalGrand = 0;
        let totalMinute = 0;

        for (let i = 0; i < values.services.length; i++) {
          if (values.services[i]?.serviceId !== undefined) {
            for (let j = 0; j < categories.length; j++) {
              const service = categories[j].services.find(item => item.id === values.services[i].serviceId)
              if (service) {
                totalGrand = totalGrand + service.price;
                totalMinute = totalMinute + service.duration;
              }
            }
          }
        }

        setGrandAmountToal(totalGrand)
        setGrandMinuteToal(totalMinute)

        if (discount === undefined) {
          setTotal(totalGrand)
        } else {
          setTotal(totalGrand - discount)
        }
      }}
      initialValues={{
        status: booking.status,
        customerId: booking.customerId,
        bookingDate: booking.bookingDate,
        services: booking.services,
        bookingStatus: '',
        bookingNote: '',
        startTimeHeader: true,
        serviceHeader: true,
        staffHeader: true,
      }}

    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 14 }} xs={{ span: 24 }}>
          <Row gutter={24} style={{ marginBottom: 20 }}>
            <Col lg={{ span: 13 }} xs={{ span: 24 }}>
              <Row>
                <Col span={8}><span style={{ color: "#a7a7a7" }}>Booking date:</span></Col>
                <Col span={16}>{booking.bookingDateDisplay}</Col>
              </Row>
              <Row style={{ marginTop: 8 }}>
                <Col span={8}><span style={{ color: "#a7a7a7" }}>Booking ID:</span></Col>
                <Col span={16}><span style={{ marginLeft: -3 }}>{booking.systemName !== '' && `${booking.systemName} -`} {booking.bookingId}</span></Col>
              </Row>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 24 }}>
              {(booking.status === 1 || booking.status === 4) ?
                <>
                  <Row>
                    <Col span={10}><span style={{ color: "#a7a7a7" }}>Status update: </span></Col>
                    <Col span={14}>{booking.updatedAtDisplay}</Col>
                  </Row>
                  <Row style={{ marginTop: 8 }}>
                    <Col span={10}><span style={{ color: "#a7a7a7" }}>Status: </span></Col>
                    <Col span={14}><span style={{ marginLeft: -3 }}>{getStatus(booking.status)}</span></Col>
                  </Row>
                </>
                :
                <>
                  <Row>
                    <Col span={10}><span style={{ color: "#a7a7a7" }}>Status update: </span></Col>
                    <Col span={14}>{booking.updatedAtDisplay}</Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="status"
                        label={<span style={{ color: "#a7a7a7", marginRight: 40, marginTop: 4 }}>Status: </span>}
                        colon={false}
                      >
                        <Select
                          style={{ width: 120, marginTop: 4 }}
                        >
                          {booking.status === 0 && (
                            <>
                              <Option value={0}>New</Option>
                              <Option value={2}>Arrived</Option>
                              <Option value={3}>Started</Option>
                            </>
                          )}
                          {booking.status === 2 && (
                            <>
                              <Option value={2}>Arrived</Option>
                              <Option value={3}>Started</Option>
                            </>
                          )}
                          {booking.status === 3 && (
                            <Option value={3}>Started</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                </>
              }


            </Col>
          </Row>
          <Divider style={{ width: '109%', marginLeft: -24, marginTop: 0, marginBottom: 10 }} />
          <Row gutter={24} style={{ marginBottom: 4 }}>
            <Col lg={{ span: 5 }} xs={{ span: 24 }}>
              <span style={{ color: "#a7a7a7" }}>Start time</span>
            </Col>
            <Col lg={{ span: 11 }} xs={{ span: 24 }}>
              <span style={{ color: "#a7a7a7" }}>Service</span>
            </Col>
            <Col lg={{ span: 8 }} xs={{ span: 24 }}>
              <span style={{ color: "#a7a7a7" }}>Staff</span>
            </Col>
          </Row>
          {booking.services.map(service => {
            return (
              <Row gutter={24} style={{ marginBottom: 4 }}>
                <Col lg={{ span: 5 }} xs={{ span: 24 }}>{service.startTime.format('HH:mm')}</Col>
                <Col lg={{ span: 11 }} xs={{ span: 24 }}>{service.serviceName}</Col>
                <Col lg={{ span: 8 }} xs={{ span: 24 }}>{service.staffName}</Col>
              </Row>
            )
          })}
        </Col>
        <Col lg={{ span: 1 }} xs={{ span: 24 }}>
          <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '108%', marginTop: -25 }}></div>
        </Col>
        <Col lg={{ span: 9 }} xs={{ span: 24 }}>
          {customer !== undefined &&
            <Row style={{ backgroundColor: 'f4f4f4' }}>
              <Col span={24}>
                <Row><h3 style={{ fontWeight: 'bold' }}>Customers Info</h3></Row>
                <Row className="gutter-row" gutter={0} >
                  <Col span={6}>
                    <div style={{ color: "#a7a7a7" }}>Name: </div>
                    <div style={{ color: "#a7a7a7" }}>Gender: </div>
                    <div style={{ color: "#a7a7a7" }}>Phone: </div>
                    <div style={{ color: "#a7a7a7" }}>Email: </div>
                  </Col>
                  <Col span={18}>
                    <div>{customer?.name}</div>
                    <div> {customer === undefined ? '' : customer?.gender === 0 ? 'Male' : 'Female'} </div>
                    <div>{customer?.phoneNumber}</div>
                    <Row>{customer?.email}</Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          <Divider style={{ borderColor: 'D8D8D8', width: '119%', marginLeft: -40, marginTop: 16, marginBottom: 10 }} />
          <Row style={{ backgroundColor: 'f4f4f4', paddingBottom: 20 }}>
            <Col span={24}>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={10}>
                  <Row style={{ color: "#a7a7a7" }}>Grand total</Row>
                </Col>
                <Col span={14} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  <span>{grandMinuteToal}min/{formatYen(grandAmountToal)}</span>
                </Col>
              </Row>
              {discount === undefined ?
                <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={16}>
                    <span style={{ color: "#a7a7a7" }}>
                      {((booking.systemId === undefined || booking.systemId === null) && (booking.status === 0 || booking.status === 2 || booking.status === 3)) ?
                        <a onClick={() => setIsCreateDiscountModal(true)}>Discount</a>
                        :
                        `Discount`
                      }
                    </span>
                  </Col>
                  <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    <span style={{ fontWeight: 'bold' }}>{formatYen(0)}</span></Col>
                </Row>
                :
                <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={16}>
                    <span style={{ color: "#a7a7a7" }}>Discount
                    {((booking.systemId === undefined || booking.systemId === null) && (booking.status === 0 || booking.status === 2 || booking.status === 3 )) &&
                        <a
                          style={{ marginLeft: 4 }}
                          onClick={() => {
                            formDiscount.setFieldsValue({
                              discount: undefined
                            })
                            setDiscount(undefined)
                            setTotal(grandAmountToal)
                          }}><CloseCircleOutlined /></a>
                      }
                    </span>
                  </Col>
                  <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    <span style={{ fontWeight: 'bold' }}>{formatYen(discount)}</span>
                  </Col>
                </Row>
              }

              <div style={{ height: 1, width: "100%", backgroundColor: '#D8D8D8', marginTop: 5, marginBottom: 5 }}></div>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={16}>
                  <span className="title" style={{ fontSize: 16, color: "#a7a7a7" }}>Total</span>
                </Col>
                <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  <span className="content" style={{ fontSize: 16 }}>{formatYen(total)}</span>
                </Col>
              </Row>
              {booking.status === 1 && (
                <>
                  <div style={{ height: 1, width: "100%", backgroundColor: '#D8D8D8', marginTop: 5, marginBottom: 5 }}></div>
                  <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={16}>
                      <span className="title" style={{ color: "#a7a7a7" }}>Cancellation fee ({cancellationFee * 100}%)</span>
                    </Col>
                    <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                      <span className="content">{formatYen(charge)}</span>
                    </Col>
                  </Row>
                </>
              )}

            </Col>
          </Row>
        </Col>
      </Row>
      
      {
        //payment detail in booking view detail
        payment &&
        <>
          
          <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -8 }} />
          <h3>Payment</h3>
          <Row>
            <Col span={8}>
              <div style={{ marginBottom:8 }}>
                <span style={{ color: "#a7a7a7" }}>Payment ID: </span>
                <span style={{ textDecoration: 'underline' }}>
                  <a onClick={()=>SetIsViewDetail(true)}>
                    {payment?.id}
                  </a></span>
              </div>
            </Col>
            <Col span={11}>
            <div>
                <span style={{ color: "#a7a7a7" }}>Status: </span>
                {
                  payment?.status?.id === 1 &&
                  <span
                    style={{backgroundColor: "#C5EABF", padding: "2px 12px",borderRadius:8
                    }}
                  >
                    {payment?.status?.status}
                  </span>
                }
                {
                  payment?.status?.id === 2&&
                  <span
                    style={{
                      backgroundColor: "#BCDCED", padding: "2px 12px", borderRadius: 8
                    }}
                  >
                    {payment?.status?.status}
                  </span>
                }
                {
                  payment?.status?.id === 3 &&
                  <span
                    style={{
                      backgroundColor: "#F2473E", padding: "2px 12px", borderRadius: 8
                    }}
                  >
                    {payment?.status?.status}
                  </span>
                }
                {
                  payment?.status?.id === 4 &&
                  <span
                    style={{
                      backgroundColor: "#D5D4D4", padding: "2px 12px", borderRadius: 8
                    }}
                  >
                    {payment?.status?.status}
                  </span>
                }
                {
                  payment?.status?.id === 5 &&
                  <span
                    style={{
                      backgroundColor: "#F4D0D5", padding: "2px 12px", borderRadius: 8
                    }}
                  >
                    {payment?.status?.status}
                  </span>
                }
             
              </div>
            </Col>
          </Row>
        </>
      }
    
      

      {(booking.systemId === undefined || booking.systemId === null) ?
        <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={8} style={{ textAlign: 'left' }}>
              <Button
                onClick={() => {
                  setIsCancelModal(true)
                }}
                danger
                type="clear"
                disabled={(booking.status === 1) ? true : false} //Do not allow to update when Done, Cancel
              >
                Cancel Booking
                </Button>
            </Col>
            <Col className="gutter-row" span={16} style={{ textAlign: 'right' }}>
              <Button
                type="clear"
                onClick={async () => {
                  await dispatch(quickPayment({ booking, status: 4 }));
                  let result = await dispatch(getPaymentByBookingId({ bookingId }));
                  setPaymentDetail(result?.data[0]);
                  SetIsOpenBillingModal(true);
                  // onCancel()
                  // onCallback()
                }}
                disabled={(booking.status === 1 || booking.status === 4) ? true : false}
              >
                Quick Payment
                </Button>
              <Button
                type="primary" htmlType="submit"
                disabled={(booking.status === 1 || booking.status === 4) ? true : false}
              >
                Update Booking
                </Button>
            </Col>
          </Row>
        </div>
        :
        <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={8} style={{ textAlign: 'left' }}>
              <Button
                onClick={() => {
                  setIsCancelModal(true)
                }}
                danger
                type="clear"
                disabled={(booking.status === 1) ? true : false} //Do not allow to update when Done, Cancel
              >
                Cancel Booking
                </Button>
            </Col>
            <Col className="gutter-row" span={16} style={{ textAlign: 'right' }}>
              <Button
                type="clear"
                onClick={() => {
                  dispatch(quickPayment({ booking, status: 4 }))
                    .then(() => {
                      onCancel()
                      onCallback()
                    })
                }}
                disabled={(booking.status === 1 || booking.status === 4) ? true : false}
              >
                Quick Payment
                </Button>
              <Button
                type="primary" htmlType="submit"
                disabled={(booking.status === 1 || booking.status === 4) ? true : false}
              >
                Update Booking
                </Button>
            </Col>
          </Row>
        </div>
      }

      <Modal
        visible={isCancelModal}
        title='Cancel Booking'
        onCancel={() => {
          setIsCancelModal(false)
        }}
        destroyOnClose={true}
        width={380}
        centered
        footer={null}
      >
        <BookingCancelForm
          bookingId={booking.bookingId}
          onClose={() => setIsCancelModal(false)}
          onCallback={() => {
            setIsCancelModal(false)
            onCancel()
            onCallback()
          }}
        />
      </Modal>

      <Modal
        visible={isCreateDiscountModal}
        title="Add Discount"
        okText="Save"
        cancelText="Cancel"
        onCancel={() => setIsCreateDiscountModal(false)}
        destroyOnClose={true}
        width={380}
        centered
        onOk={() => {
          formDiscount
            .validateFields()
            .then(values => {
              if (values.discount > grandAmountToal) {
                message.error("Discount must not be greater than total price!");
                return;
              }

              setDiscount(values.discount)

              if (values.discount !== undefined) {
                let totalNew = total - values.discount;
                setTotal(totalNew)
              }

              setIsCreateDiscountModal(false)
            })
            .catch(err => {
              console.error(err)
            });
        }}
      >
        <Form
          form={formDiscount}
          name="discountForm"
          initialValues={{ discount }}
        >
          <Form.Item
            name="discount"
            label="Discount Cash"
            rules={[
              {
                required: true,
                message: 'Please input the Name!'
              }
            ]}
          >
            <InputNumber
              placeholder="Discount Cash"
              min={0}
              formatter={value => `¥${value}`}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={isOpenBillingModal}
        title='Billing'
        onCancel={() => {
          if (!isComplete) {
          Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'This billing  will be canceled?',
            okText: 'Sure',
            cancelText: 'No',
            onOk: () => {
              SetIsOpenBillingModal(false);
              onCancel();
              onCallback();
            }
          });
          }
          else {
            onCancel();
            onCallback();
            SetIsOpenBillingModal(false);
          }
        }}
        destroyOnClose={true}
        width={820}
        centered
        footer={null}
      >
        <PaymentBillingForm
          paymentDetail={paymentDetail}
          isComplete={isComplete}
          setIsComplete={setIsComplete}
          booking={bookings}
          onCallback={() => {
            SetIsOpenBillingModal(false)
            onCancel()
            onCallback()
          }}
        />
      </Modal>
      <Modal
        visible={isViewDetail}
        title='Payment Detail'
        onCancel={() => {
          SetIsViewDetail(false)
        }}
        destroyOnClose={true}
        width={710}
        centered
        footer={null}
      >
        <PaymentDetailForm
          paymentDetail={payment}
          onCallback={() => {
            SetIsViewDetail(false);
           
          }}
          booking={bookings}
        />
      </Modal>
    </Form>
  );
};

export default BookingViewForm;