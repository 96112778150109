import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS,
  BOOKING_GET_STAFFS_BY_SERVICE_FAIL,
  BOOKING_DETAIL,
  BOOKING_DETAIL_SUCCESS,
  BOOKING_DETAIL_FAIL,
  BOOKING_LIST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_CREATE,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_UPDATE,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_ORDER,
  BOOKING_ORDER_SUCCESS,
  BOOKING_ORDER_FAIL,
  BOOKING_DELETE,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_CANCEL,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_CANCEL_FAIL,
  QUICK_PAYMENT,
  QUICK_PAYMENT_SUCCESS,
  QUICK_PAYMENT_FAIL,
  GET_APPS,
  GET_APPS_SUCCESS,
  GET_APPS_FAIL,
  BOOKING_TIPS,
  BOOKING_TIPS_SUCCESS,
  BOOKING_TIPS_FAIL,
  BOOKING_DELETE_TIPS,
  BOOKING_DELETE_TIPS_SUCCESS,
  BOOKING_DELETE_TIPS_FAIL,
  GET_PAYMENT_BYBOOKINGID,
  GET_PAYMENT_BYBOOKINGID_SUCCESS,
  GET_PAYMENT_BYBOOKINGID_FAIL,
} from './types';

const INITIAL_STATE = {
  bookings: [],
  booking: {},
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOKING_GET_STAFFS_BY_SERVICE:
      return {
        ...state,
      };

    case BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_GET_STAFFS_BY_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_LIST:
      return {
        ...state,
        loading: true
      };

    case BOOKING_LIST_SUCCESS:
      return {
        ...state,
        bookings: action.payload.bookings,
        loading: false
      };

    case BOOKING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case BOOKING_DETAIL:
      return {
        ...state,
      };

    case BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case BOOKING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_CREATE:
      return {
        ...state,
      };

    case BOOKING_CREATE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_UPDATE:
      return {
        ...state,
      };

    case BOOKING_UPDATE_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_ORDER:
      return {
        ...state,
      };

    case BOOKING_ORDER_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case BOOKING_DELETE:
      return {
        ...state,
        loading: true
      };

    case BOOKING_DELETE_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case BOOKING_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case BOOKING_CANCEL:
      return {
        ...state,
      };

    case BOOKING_CANCEL_SUCCESS:
      return {
        ...state,
      };

    case BOOKING_CANCEL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case QUICK_PAYMENT: case QUICK_PAYMENT_SUCCESS: case QUICK_PAYMENT_FAIL:
      return {
        ...state,
      };

    case GET_APPS: case GET_APPS_SUCCESS: case GET_APPS_FAIL:
      return {
        ...state,
      };
    case BOOKING_TIPS: case BOOKING_TIPS_SUCCESS: case BOOKING_TIPS_FAIL:
      return {
        ...state,
      };
    case BOOKING_DELETE_TIPS: case BOOKING_DELETE_TIPS_SUCCESS: case BOOKING_DELETE_TIPS_FAIL:
      return {
        ...state,
      };
    case GET_PAYMENT_BYBOOKINGID: case GET_PAYMENT_BYBOOKINGID_SUCCESS: case GET_PAYMENT_BYBOOKINGID_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};