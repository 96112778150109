export default function (baseURL) {
  return {
    baseURL: `${baseURL}`,
    api: {
      //Auth0
      auth0Domain: 'c2c-cb.us.auth0.com',
      auth0ClientId: 'q8MEhx7juknBJG1wT05VppQnbB2LCTOz',
      auth0Audience: 'https://c2c-cb.us.auth0.com/api/v2/',
      auth0RedirectUri: 'http://localhost:3000/auth0',

      //Authentication
      salonLogin: 'api/v1/users/login',
      salonRequest: 'api/v1/salons',
      completedProfileSalon: 'api/v1/salons',
      updateAccountSalon: 'api/v1/salons',
      updateScheduleSalon: 'api/v1/salons',
      uploadImage: 'api/v1/media/images/signature',
      getAddressByPostCode: 'api/v1/media/address',

      //Apps
      getApps: 'api/v1/apps',

      //Service
      categoryList: 'api/v1/services/categories',
      categoryCreate: 'api/v1/services/categories',
      categoryUpdate: 'api/v1/services/categories',
      categoryDelete: 'api/v1/services/categories',

      //Category    
      serviceCreate: 'api/v1/services',
      serviceList: 'api/v1/services',
      serviceUpdate: 'api/v1/services',
      serviceDelete: 'api/v1/services',

      //Staff
      staffList: 'api/v1/staff',
      staffDetail: 'api/v1/staff',
      staffCreate: 'api/v1/staff',
      staffUpdate: 'api/v1/staff/',
      areasList: 'api/v1/areas',
      prefecturesList: 'api/v1/prefectures',
      citiesList: 'api/v1/cities',
      salonGetCurrentUser: 'api/v1/users/profile',
      staffAddServices: 'api/v1/staff',
      staffSendInvite: 'api/v1/apps/085094953333804/staff',
      staffCancelInvite: 'api/v1/apps/085094953333804/staff',
      staffScheduleList: 'api/v1/staff/schedules',
      staffScheduleUpdate: 'api/v1/staff',

      //Booking
      getBookings: 'api/v1/bookings',
      getBookingStaff: 'api/v1/bookings/staff',
      bookingCreate: 'api/v1/bookings',
      bookingUpdate: 'api/v1/bookings',
      bookingSystemUpdate: 'api/v1/apps/085094953333804/bookings',
      getBooking: '/api/v1/bookings',
      getPaymentByBookingId:'api/v1/bookings',

      //Customer
      customerList: 'api/v1/customers',
      customerCreate: 'api/v1/customers',
      customerUpdate: 'api/v1/customers',
      customerDetail: 'api/v1/customers',

      //Salon
      salonDetail: 'api/v1/salons',
      salonAccountUpdate: '/api/v1/salons',
      salonBankUpdate: 'api/v1/salons',
      salonGetBank: '/api/v1/banks',
      salonGetBankBranch: '/api/v1/banks',

      //Payment
      payments: '/api/v1/payments',
      paymentSummary: '/api/v1/payments/summary',
      paymentCompletion: '/api/v1/payments',
      paymentDetail: '/api/v1/payments',
      paymentRefund: '/api/v1/payments',
      paymentTips: 'api/v1/payments',

    },
    isProduction: false,
    isDevelopment: true,
  };
}
