import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Layout, Modal, Button, Spin, Breadcrumb, Dropdown, Menu, Table, Avatar } from 'antd';
import { Card, Row, Col, DatePicker, Select, Form, PageHeader } from 'antd';
import { SearchOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import MenuSiderLeft from 'components/Desktop/Staff/MenuSliderLeft';
import { staffScheduleList } from 'providers/StaffProvider/actions';
import StaffScheduleEditForm from 'components/Desktop/Staff/StaffScheduleEditForm';

import './styles.less';

import moment from 'moment';
import { round } from 'utils/common';
import { clone } from 'lodash';

const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};

const StaffSchedule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [filterStatus, setFiterStatus] = useState();
  const [year, setYear] = useState(moment().year());
  const [week, setWeek] = useState(moment().week());
  const [pageSize, SetPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [totalPage, setTotalalPage] = useState('');

  const [isEditModal, setIsEditModal] = useState(false);
  const [staffId, setStaffId] = useState();
  const [scheduleDayInWeek, setScheduleDayInWeek] = useState([]);
  const [staffDayInWeek, setStaffDayInWeek] = useState();
  const [staffCurrentDate, setStaffCurrentDate] = useState();
  const [header, setHeader] = useState('');

  const disabledDate = (current) => {
    // Can not select days before today-1 and today-1
    return current && current < moment().endOf('day').subtract(1, 'd');;
  }

  const [disableColumns, setDisableColumns] = useState()

  const [defaultValue, setDefaultValue] = useState(() => {
    return moment();
  });

  const [currentDate, setCurrentDate] = useState(() => {
    return moment();
  });

  const onChangeRangeDate = (date, dateString) => {
    //console.log('onChangeRangeDate.year: ', dateString.split("-")[0])
    //console.log('onChangeRangeDate.weekString: ', dateString.split("-")[1].substr(0, dateString.split("-")[1].length - 2))
    setWeek(parseInt(dateString.split("-")[1].substr(0, dateString.split("-")[1].length - 2)))
    setYear(parseInt(dateString.split("-")[0]))
    
    setDefaultValue(date);
    setCurrentDate(date);
  };

  const handelChangeRangeDate = (numberDay) => {
    const newDate = moment(currentDate.toString()).add(numberDay, 'day');
    setDefaultValue(newDate);
    setCurrentDate(newDate);
  };

  const renderCell = (record, dayInWeek, duration, staff, isDisable) => {
    let totalHours = 0;
    record.forEach(element => {
      //let currentDate = new moment();
      //let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm")
      //let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm")
      
      if (element.endAt !== null && element.endAt !== undefined) {
        let duration = moment.duration(moment(element.endAt).diff(moment(element.startAt)));
        let hours = duration.hours();
        let minutes = duration.minutes();
  
        totalHours = totalHours + hours + round(minutes/60,1)
      }
    });

    if (isDisable === true ) {
      return (
        <div className="cell disable">
          <div>
            {totalHours !== 0 && <div style={{fontWeight: 'bold'}}>Total {totalHours} hours</div>}
          </div>
          {record.map(item => {
            if (moment(item.startAt).format('HH:mm') !== '00:00' && moment(item.endAt).format('HH:mm') !== '00:00') {
              return (
                <div>
                  {`${moment(item.startAt).format('HH:mm')} to ${moment(item.endAt).format('HH:mm')}`}
                </div>
              )
            } else {
              return (
                <div style={{color: 'red', fontWeight: 'bold', marginLeft: 8}}>
                  Absent
                </div>
              )
            }
            
          })}
        </div>
      )
    } else {
      return (
        <div className="cell" style={{ cursor: 'pointer'}} onClick={() => {
          const cloneDate = currentDate;
        
          setHeader(`${dayInWeek} ${cloneDate.add(duration, 'days').format('MMMM Do YYYY')}`)
          setStaffCurrentDate(cloneDate.clone())
          setStaffDayInWeek(dayInWeek)
          setStaffId(staff?.staffId)
          setScheduleDayInWeek(record)
          setIsEditModal(true)
        }}>
          <div>
            {totalHours !== 0 && <div style={{fontWeight: 'bold'}}>Total {totalHours} hours</div>}
          </div>
          {record.map(item => {
            if (moment(item.startAt).format('HH:mm') !== '00:00' && moment(item.endAt).format('HH:mm') !== '00:00') {
              return (
                <div>
                  {`${moment(item.startAt).format('HH:mm')} to ${moment(item.endAt).format('HH:mm')}`}
                </div>
              )
            } else {
              return (
                <div style={{color: 'red', fontWeight: 'bold', marginLeft: 8}}>
                  Absent
                </div>
              )
            }
            
          })}
        </div>
      )
    }

    
  };

  const renderDays = (duration, weekDate) => {
    if (weekDate === undefined) {
      return ''
    } else {
      const sunday = weekDate.add((weekDate.day())*(-1), 'days')
      const x = sunday.clone();
      return x.add(duration, 'days').format('DDMMMYYYY')
    }
  }

  const columns = [
    {
      title: 'Staff',
      dataIndex: 'staff',
      width: 160,
      fixed: 'left',
      render: (record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 16 }}>
            <Avatar src={record.photo} size='large' style={{ marginRight: 8 }} />
            {record.staffName}
          </div >
        )
      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Sunday</span> {renderDays(0, currentDate)}</div>,
      dataIndex: 'sunday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()

        if (today.week() === week) {
          if (today.day() > 0) { 
            return renderCell(row.sunday, 'sunday', -6, row.staff, true)
          } else {
            return renderCell(row.sunday, 'sunday', -6, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.sunday, 'sunday', -6, row.staff, true)
        } else {
          return renderCell(row.sunday, 'sunday', -6, row.staff, false)
        }
      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Monday</span> {renderDays(1, currentDate)}</div>,
      dataIndex: 'monday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()

        if (today.week() === week) {
          if (today.day() > 1) { 
            return renderCell(row.monday, 'monday', -5, row.staff, true)
          } else {
            return renderCell(row.monday, 'monday', -5, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.monday, 'monday', -5, row.staff, true)
        } else {
          return renderCell(row.monday, 'monday', -5, row.staff, false)
        }

      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Tuesday</span> {renderDays(2, currentDate)}</div>,
      dataIndex: 'tueday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()
        
        if (today.week() === week) {
          if (today.day() > 2) { 
            return renderCell(row.tueday, 'tueday', -4, row.staff, true)
          } else {
            return renderCell(row.tueday, 'tueday', -4, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.tueday, 'tueday', -4, row.staff, true)
        } else {
          return renderCell(row.tueday, 'tueday', -4, row.staff, false)
        }

      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Wednesday</span> {renderDays(3, currentDate)}</div>,
      dataIndex: 'wednesday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()

        if (today.week() === week) {
          if (today.day() > 3) { 
            return renderCell(row.wednesday, 'wednesday', -3, row.staff, true)
          } else {
            return renderCell(row.wednesday, 'wednesday', -3, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.wednesday, 'wednesday', -3, row.staff, true)
        } else {
          return renderCell(row.wednesday, 'wednesday', -3, row.staff, false)
        }

      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Thursday</span> {renderDays(4, currentDate)}</div>,
      dataIndex: 'thursday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()
        
        if (today.week() === week) {
          if (today.day() > 4) { 
            return renderCell(row.thursday, 'thursday', -2, row.staff, true)
          } else {
            return renderCell(row.thursday, 'thursday', -2, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.thursday, 'thursday', -2, row.staff, true)
        } else {
          return renderCell(row.thursday, 'thursday', -2, row.staff, false)
        }

      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Friday</span> <br />{renderDays(5, currentDate)}</div>,
      dataIndex: 'friday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()
        
        if (today.week() === week) {
          if (today.day() > 5) { 
            return renderCell(row.friday, 'friday', -1, row.staff, true)
          } else {
            return renderCell(row.friday, 'friday', -1, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.friday, 'friday', -1, row.staff, true)
        } else {
          return renderCell(row.friday, 'friday', -1, row.staff, false)
        }

      }
    },
    {
      title: <div><span style={{fontWeight: 'bold'}}>Saturday</span> {renderDays(6, currentDate)}</div>,
      dataIndex: 'saturday',
      width: 100,
      render: (value, row, index) => {
        const today = new moment()
        
        if (today.week() === week) {
          if (today.day() > 6) { 
            return renderCell(row.saturday, 'saturday', 0, row.staff, true)
          } else {
            return renderCell(row.saturday, 'saturday', 0, row.staff, false)
          }
        } else if (today.week() > week) {
          return renderCell(row.saturday, 'saturday', 0, row.staff, true)
        } else {
          return renderCell(row.saturday, 'saturday', 0, row.staff, false)
        }
        
      }
    },
  ];

  useEffect(() => {
    getStaffSchedule(currentPage, pageSize, filterStatus, sortedInfo, week)
  }, [currentPage, filterStatus, sortedInfo.columnKey, sortedInfo.order, pageSize, week, year]);

  const getStaffSchedule = (currentPage, pageSize, filterStatus, sortedInfo, week) => {
    setLoading(true);
    dispatch(staffScheduleList({ currentPage, pageSize, filterStatus, sortedInfo, weekOfYear: week }))
      .then(result => {
        setLoading(false);
        const { total } = result.data.pagination;
        const { data } = result.data;
        setTotalalPage(total);
        setStaffs(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
  }

  console.log('staffs: ', staffs)

  return (
    <Layout>
      <MenuSiderLeft menuItem="2" />
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><HomeOutlined /> {t('Staff')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('Staff Schedule')}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='staff-schedules'>
        <Card style={{marginBottom: 18}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={24}>
                <h2>{t('Staff Schedule')}</h2>
              </Col>
            </Row>

            <Card style={{ marginBottom: 24, backgroundColor: '#F8F8F8'}}>
              <Form layout="inline" onSubmit={() => { }}>
                <Form.Item>
                  <div style={{ flexWrap: 1, flexDirection: 'column' }}>
                    <Button
                      onClick={() => { 
                        handelChangeRangeDate(-7);
                        if (week === 1) {
                          setWeek(52)
                          setYear(year - 1)
                        } else {
                          setWeek(week - 1)
                        }
                       }}
                    ><CaretLeftOutlined /></Button>
                    <DatePicker
                      allowClear={false}
                      picker="week"
                      value={defaultValue}
                      onChange={onChangeRangeDate}
                      disabledDate={disabledDate}
                    />
                    <Button
                      onClick={() => { 
                        handelChangeRangeDate(7);
                        if (week === 52) {
                          setWeek(1)
                          setYear(year + 1)
                        } else {
                          setWeek(week + 1)
                        }
                      }}
                    ><CaretRightOutlined /></Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClickCapture={() => {
                    console.log('Today')
                    setCurrentDate(new moment())
                    setDefaultValue(new moment())
                    setWeek(new moment().week())
                    setYear(new moment().year())
                  }} icon={<SearchOutlined />}>Today</Button>
                </Form.Item>
              </Form>
            </Card>

            <Table
              columns={columns}
              dataSource={staffs.map(item => {
                return {
                  key: item.id, 
                  staff: {
                    photo: item.avatar === null ? 'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png' : item.avatar, 
                    staffId: item.id, 
                    staffName: item.name, 
                  },
                  monday: item.schedules.filter(item => item.dayOfWeek === 1),
                  tueday: item.schedules.filter(item => item.dayOfWeek === 2),
                  wednesday: item.schedules.filter(item => item.dayOfWeek === 3),
                  thursday: item.schedules.filter(item => item.dayOfWeek === 4),
                  friday: item.schedules.filter(item => item.dayOfWeek === 5),   
                  saturday: item.schedules.filter(item => item.dayOfWeek === 6),
                  sunday: item.schedules.filter(item => item.dayOfWeek === 0), 
                }
              })}
              loading={loading}
              bordered
              pagination={false}
            />
        </Card>
      </div>

      <Modal
            visible={isEditModal}
            title={`New Timesheet on ${header}`}
            onCancel={() => setIsEditModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
          <StaffScheduleEditForm
            staffId={staffId}
            staffDayInWeek={staffDayInWeek}
            scheduleDayInWeek={scheduleDayInWeek}
            staffCurrentDate={staffCurrentDate}
            onCancel={() => setIsEditModal(false)} 
            onCallback={() => {
              setIsEditModal(false)
              getStaffSchedule(currentPage, pageSize, filterStatus, sortedInfo, week)
            }} 
          />
        </Modal>

    
      </Layout>
    </Layout>
  )
};

export default StaffSchedule;