import {
  SALON_DETAIL,
  SALON_ACCOUNT_UPDATE,
  SALON_BANK_UPDATE,
  SALON_GET_BANK,
  SALON_GET_BANK_BRANCH,
  SALON_SCHEDULE_UPDATE
} from './types';
import request from 'utils/request';
import { put, takeLeading, call } from 'redux-saga/effects';

import {
  salonDetailSuccess,
  salonDetailFail,
  salonAccountUpdateFail,
  salonAccountUpdateSuccess,
  salonBankUpdateSuccess,
  salonBankUpdateFail,
  salonGetBankSuccess,
  salonGetBankFail,
  salonGetBankBranchSuccess,
  salonGetBankBranchFail,
  salonScheduleUpdateFail,
  salonScheduleUpdateSuccess,
} from './actions';

import environment from 'environment';
import { message as Alert } from 'antd';

function* salonDetail(action) {
  const salonId = action.payload;

  try {
    const salon = yield call(request, `${environment.api.salonDetail}/${salonId}`, {}, 'GET');
    yield put(salonDetailSuccess(salon, action.meta));
  } catch (error) {
    yield put(salonDetailFail(error, action.meta));
  }
}

function* salonAccountUpdate(action) {
  try {
    const { businessName, salonId, postCode, prefecture, cityOrTown, address, logoUrl, workingDays } = action.payload;
    const { building_types_is_nail,  building_types_is_hair, building_types_is_massage, building_types_is_spa } = action.payload;
    const { business_hour_starttime, business_hour_endtime } = action.payload;

    let businessTypes = [];
    if (building_types_is_nail === true) {
      businessTypes.push('nail')
    }

    if (building_types_is_hair === true) {
      businessTypes.push('hair')
    }

    if (building_types_is_massage === true) {
      businessTypes.push('massage')
    }

    if (building_types_is_spa === true) {
      businessTypes.push('spa')
    }

    yield call(request, `${environment.api.updateAccountSalon}/${salonId}`, {
      businessName,
      businessTypes,
      workingDays,
      location: {
        postCode,
        prefecture,
        cityOrTown,
        address
      },
      logoUrl,
      businessHour: {
        startAt: {
          hour: Math.floor(business_hour_starttime / 100),
          minute: business_hour_starttime % 100
        },
        endAt: {
          hour: Math.floor(business_hour_endtime / 100),
          minute: business_hour_endtime % 100
        }
      }
    }, 'PATCH');  

    yield put(salonAccountUpdateSuccess({}, action.meta));
    Alert.success(`Save successfully`);
  } catch (error) {
    console.log(JSON.stringify(error,0,2))
    let msg = '';

    if (error.data.length > 0) {
      msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      msg = error.message;
      Alert.error(error.message);
    }
    yield put(salonAccountUpdateFail(msg, action.meta));
  }
}

function* salonBankUpdate(action) {
  try {
    const { bankId, branchId, accountType, accountNumber, accountName, salonId } = action.payload;
    console.log('bankId, branchId: ', bankId, branchId)
    const salon = yield call(request, `${environment.api.salonBankUpdate}/${salonId}/banks`,
      {
        bankId,
        branchId,
        accountType,
        accountNumber,
        accountName
      }, 'PATCH');

    yield put(salonBankUpdateSuccess(salon, action.meta));
    
    Alert.success('Update successfully');
  } catch (error) {
    yield put(salonBankUpdateFail(error, action.meta));
  }
}

function* salonGetBank(action) {
  try {
    const banks = yield call(request, environment.api.salonGetBank, {}, 'GET');
    console.log('banks: ', banks)
    yield put(salonGetBankSuccess(banks, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetBankFail(error, action.meta));
  }
}

function* salonGetBankBranch(action) {
  console.log('salonGetBankBranch.saga: ', action.payload);
  const bankId = action.payload;

  try {
    const branches = yield call(request, `${environment.api.salonGetBankBranch}/${bankId}/branches`, {}, 'GET');
    console.log('branches: ', branches)
    yield put(salonGetBankBranchSuccess(branches, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(salonGetBankBranchFail(error, action.meta));
  }
}

function* salonScheduleUpdate(action) {
  try {
    const { timezone, schedules, salonId } = action.payload;
    console.log('saga --> timezone, schedules, salonId: ', timezone, schedules, salonId)
    const salon = yield call(request, `${environment.api.updateScheduleSalon}/${salonId}/schedule`,
      {
        timezone,
        schedules
      }, 'PUT');

    yield put(salonScheduleUpdateSuccess(salon, action.meta));
    
    Alert.success('Update successfully');
  } catch (error) {
    yield put(salonScheduleUpdateFail(error, action.meta));
  }
}

export default function* watchSalonList() {
  yield takeLeading(SALON_DETAIL, salonDetail);
  yield takeLeading(SALON_ACCOUNT_UPDATE, salonAccountUpdate);
  yield takeLeading(SALON_BANK_UPDATE, salonBankUpdate);
  yield takeLeading(SALON_GET_BANK, salonGetBank);
  yield takeLeading(SALON_GET_BANK_BRANCH, salonGetBankBranch);
  yield takeLeading(SALON_SCHEDULE_UPDATE, salonScheduleUpdate);
}