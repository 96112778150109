export const SALON_DETAIL = 'SALON_DETAIL';
export const SALON_DETAIL_SUCCESS = 'SALON_DETAIL_SUCCESS';
export const SALON_DETAIL_FAIL = 'SALON_DETAIL_FAIL';

export const SALON_ACCOUNT_UPDATE = 'SALON_ACCOUNT_UPDATE';
export const SALON_ACCOUNT_UPDATE_SUCCESS = 'SALON_ACCOUNT_UPDATE_SUCCESS';
export const SALON_ACCOUNT_UPDATE_FAIL = 'SALON_ACCOUNT_UPDATE_FAIL';

export const SALON_BANK_UPDATE = 'SALON_BANK_UPDATE';
export const SALON_BANK_UPDATE_SUCCESS = 'SALON_BANK_UPDATE_SUCCESS';
export const SALON_BANK_UPDATE_FAIL = 'SALON_BANK_UPDATE_FAIL';

export const SALON_GET_BANK = 'SALON_GET_BANK';
export const SALON_GET_BANK_SUCCESS = 'SALON_GET_BANK_SUCCESS';
export const SALON_GET_BANK_FAIL = 'SALON_GET_BANK_FAIL';

export const SALON_GET_BANK_BRANCH = 'SALON_GET_BANK_BRANCH';
export const SALON_GET_BANK_BRANCH_SUCCESS = 'SALON_GET_BANK_BRANCH_SUCCESS';
export const SALON_GET_BANK_BRANCH_FAIL = 'SALON_GET_BANK_BRANCH_FAIL';

export const SALON_SCHEDULE_UPDATE = 'SALON_SCHEDULE_UPDATE';
export const SALON_SCHEDULE_UPDATE_SUCCESS = 'SALON_SCHEDULE_UPDATE_SUCCESS';
export const SALON_SCHEDULE_UPDATE_FAIL = 'SALON_SCHEDULE_UPDATE_FAIL';