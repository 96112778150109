import { setSampleActionSuccess } from './actions';
import { SAMPLE_CONST } from './constants';
import { put, takeEvery } from 'redux-saga/effects';

function* function1(action) {
  try {
    yield put(setSampleActionSuccess(action.payload));
  } catch (e) {

  }
}
export default function* watchSample() {
  yield takeEvery(SAMPLE_CONST, function1);
}