import React from 'react';
import { useParams } from 'react-router-dom';

import './styles.less';

const SampleParam = () => {
  let { id } = useParams();
  
  return (
    <div className='sample-param'>
        SampleParam: {id}
    </div>
  )
};

export default SampleParam;