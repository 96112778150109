import { combineReducers } from 'redux';
import sample from 'providers/SampleProvider/reducer';
import auth from 'providers/AuthProvider/reducer';
import customer from 'providers/CustomerProvider/reducer';
import category from 'providers/CategoryProvider/reducer';
import service from 'providers/ServiceProvider/reducer';
import salon from 'providers/SalonProvider/reducer';
import staffs from 'providers/StaffProvider/reducer';
import booking from 'providers/BookingProvider/reducer';

export default combineReducers({
  sample,
  auth,
  customer,
  category,
  service,
  salon,
  staffs,
  booking
});