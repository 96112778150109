import React from 'react';
import { Form, Input, Col, Row, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { categoryCreate } from 'providers/CategoryProvider/actions';

const CategoryCreateForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  return (
    <Form
        form={form}
        layout="vertical"
        name="categoryEditForm"
        onFinish={values => 
          dispatch(categoryCreate({values}))
          .then(()=>  onCancel())
        }
        initialValues={{
          name: '',
          description: ''
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Category Name" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="description" 
              label="Category Description"
              >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
  
      </Form>
    
  );
};

export default CategoryCreateForm;