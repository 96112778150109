import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS,
  BOOKING_GET_STAFFS_BY_SERVICE_FAIL,
  BOOKING_LIST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_DETAIL,
  BOOKING_DETAIL_SUCCESS,
  BOOKING_DETAIL_FAIL,
  BOOKING_CREATE,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_UPDATE,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_ORDER,
  BOOKING_ORDER_SUCCESS,
  BOOKING_ORDER_FAIL,
  BOOKING_DELETE,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_CANCEL,
  BOOKING_CANCEL_FAIL,
  BOOKING_CANCEL_SUCCESS,
  QUICK_PAYMENT,
  QUICK_PAYMENT_SUCCESS,
  QUICK_PAYMENT_FAIL,
  GET_APPS,
  GET_APPS_FAIL,
  GET_APPS_SUCCESS,
  BOOKING_TIPS,
  BOOKING_TIPS_SUCCESS,
  BOOKING_TIPS_FAIL,
  BOOKING_DELETE_TIPS,
  BOOKING_DELETE_TIPS_SUCCESS,
  BOOKING_DELETE_TIPS_FAIL,
  GET_PAYMENT_BYBOOKINGID,
  GET_PAYMENT_BYBOOKINGID_SUCCESS,
  GET_PAYMENT_BYBOOKINGID_FAIL
} from './types';

//BOOKING_GET_STAFFS_BY_SERVICE
export function bookingGetStaffsByService(payload = {}) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE, payload, meta: { thunk: true } };
}
export function bookingGetStaffsByServiceSuccess(payload = {}, meta) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS, payload, meta };
}
export function bookingGetStaffsByServiceFail(payload = {}, meta) {
  return { type: BOOKING_GET_STAFFS_BY_SERVICE_FAIL, payload, meta, error: true };
}

//BOOKING_LIST
export function bookingList(payload = {}) {
  return { type: BOOKING_LIST, payload };
}
export function bookingListSuccess(payload = {}) {
  return { type: BOOKING_LIST_SUCCESS, payload };
}
export function bookingListFail(payload = {}) {
  return { type: BOOKING_LIST_FAIL, payload };
}

export function bookingDetail(payload = {}) {
  return { type: BOOKING_DETAIL, payload, meta: { thunk: true } };
}
export function bookingDetailSuccess(payload = {}, meta) {
  return { type: BOOKING_DETAIL_SUCCESS, payload, meta };
}
export function bookingDetailFail(payload = {}, meta) {
  return { type: BOOKING_DETAIL_FAIL, payload, meta, error: true };
}

//BOOKING_CREATE
export function bookingCreate(payload = {}) {
  return { type: BOOKING_CREATE, payload, meta: { thunk: true } };
}
export function bookingCreateSuccess(payload = {}, meta) {
  return { type: BOOKING_CREATE_SUCCESS, payload, meta };
}
export function bookingCreateFail(payload = {}, meta) {
  return { type: BOOKING_CREATE_FAIL, payload, meta, error: true };
}

//BOOKING_UPDATE
export function bookingUpdate(payload = {}) {
  return { type: BOOKING_UPDATE, payload, meta: { thunk: true } };
}
export function bookingUpdateSuccess(payload = {}, meta) {
  return { type: BOOKING_UPDATE_SUCCESS, payload, meta };
}
export function bookingUpdateFail(payload = {}, meta) {
  return { type: BOOKING_UPDATE_FAIL, payload, meta, error: true };
}

//BOOKING_DELETE
export function bookingDelete(payload = {}) {
  return { type: BOOKING_DELETE, payload };
}
export function bookingDeleteSuccess(payload = {}) {
  return { type: BOOKING_DELETE_SUCCESS, payload };
}
export function bookingDeleteFail(payload = {}) {
  return { type: BOOKING_DELETE_FAIL, payload };
}

//BOOKING_CANCEL
export function bookingCancel(payload = {}) {
  return { type: BOOKING_CANCEL, payload, meta: { thunk: true } };
}
export function bookingCancelSuccess(payload = {}, meta) {
  return { type: BOOKING_CANCEL_SUCCESS, payload, meta };
}
export function bookingCancelFail(payload = {}, meta) {
  return { type: BOOKING_CANCEL_FAIL, payload, meta, error: true };
}

//QUICK_PAYMENT
export function quickPayment(payload = {}) {
  return { type: QUICK_PAYMENT, payload, meta: { thunk: true } };
}
export function quickPaymentSuccess(payload = {}, meta) {
  return { type: QUICK_PAYMENT_SUCCESS, payload, meta };
}
export function quickPaymentFail(payload = {}, meta) {
  return { type: QUICK_PAYMENT_FAIL, payload, meta, error: true };
}

//GET_APPS
export function getApps(payload = {}) {
  return { type: GET_APPS, payload, meta: { thunk: true } };
}
export function getAppsSuccess(payload = {}, meta) {
  return { type: GET_APPS_SUCCESS, payload, meta };
}
export function getAppsFail(payload = {}, meta) {
  return { type: GET_APPS_FAIL, payload, meta, error: true };
}
//ADD TIPS
export function bookingTips(payload = {}) {
  return { type: BOOKING_TIPS, payload, meta: { thunk: true } };
}
export function bookingTipsSuccess(payload = {}, meta) {
  return { type: BOOKING_TIPS_SUCCESS, payload, meta };
}
export function bookingTipsFail(payload = {}, meta) {
  return { type: BOOKING_TIPS_FAIL, payload, meta, error: true };
}

//REMOVE TIPS
export function bookingDeleteTips(payload = {}) {
  return { type: BOOKING_DELETE_TIPS, payload, meta: { thunk: true } };
}
export function bookingDeleteTipsSuccess(payload = {}, meta) {
  return { type: BOOKING_DELETE_TIPS_SUCCESS, payload, meta };
}
export function bookingDeleteTipsFail(payload = {}, meta) {
  return { type: BOOKING_DELETE_TIPS_FAIL, payload, meta, error: true };
}

//GET PAYMENT BY BOOKINGID
export function getPaymentByBookingId(payload = {}) {
  return { type: GET_PAYMENT_BYBOOKINGID, payload, meta: { thunk: true } };
}
export function getPaymentByBookingIdSuccess(payload = {}, meta) {
  return { type: GET_PAYMENT_BYBOOKINGID_SUCCESS, payload, meta };
}
export function getPaymentByBookingIdFail(payload = {}, meta) {
  return { type: GET_PAYMENT_BYBOOKINGID_FAIL, payload, meta, error: true };
}



