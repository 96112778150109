import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _, { result } from 'lodash';
import { message } from 'antd';
import { Button, Modal, Form, TimePicker, InputNumber, DatePicker, Col, Row, Select, Divider, Space, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bookingGetStaffsByService, bookingCreate } from 'providers/BookingProvider/actions';
import { customerList } from 'providers/CustomerProvider/actions';
import { formatYen } from 'utils/stringFormat';

import CustomerCreateForm from 'components/Desktop/Customer/CustomerCreateForm';
const { Option, OptGroup } = Select;

const BookingCreateForm = ({ booking, onCancel }) => {
  const [form] = Form.useForm();
  const [formDiscount] = Form.useForm();
  
  const dispatch = useDispatch();
  const [isCreateCustomerModal, setIsCreateCustomerModal] = useState(false);
  const [isCreateDiscountModal, setIsCreateDiscountModal] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState();
  const [discount, setDiscount] = useState();
  const [grandAmountTotal, setGrandAmountTotal] = useState(0);
  const [grandMinuteTotal, setGrandMinuteTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [bookingDate, setBookingDate] = useState(()=> {
    return new moment()
  })
  const [slot, setSlot] = useState([
    {
      fieldKey: 0,
      value: moment().add(10, 'minutes')
    }
  ]);
  const [staffsByService, setStaffsbyService] = useState([
    // {
    //   fieldKey: '23424242424',
    //   staffs: [
    //     {
    //       id: '324342342424',
    //       name: 'Staff 1'
    //     }
    //   ]
    // }
  ]);

  const [removeKeyCurrent, setRemoveKeyCurrent] = useState()
  const [removeKey, setRemoveKey] = useState([])
  const {categories = []} = useSelector((state) => state.category);

  const disabledDate = (current) => {
    // Can not select days before today-1 and today-1
    return current && current < moment().endOf('day').subtract(1, 'd');;
  }

  const recalculateStartTime = (key) => {
    const arrServices = form.getFieldsValue().services;

    arrServices && arrServices.forEach((service, i) => {
      if (i > key) {
        //Check Validate Last Row
        let last = i-1;
        for (let j = i-1; j >= 0; j--) {
          if (!removeKey.includes(j)) {
            last = j;
            break;
          }
        }
        
        if (last >= 0) {
          //Recalculate Start Time
          const services = form.getFieldsValue().services.map((item, index) => {
            if (i === index) {
              //GetDuration
              let duration = 0;
              for (let j = 0; j < categories.length; j++) {
                const serviceX = categories[j].services.find(item => item.id === form.getFieldValue("services")[last].serviceId)
                if (serviceX) {
                  duration = serviceX?.duration;
                }
              }  

              const nextTime = moment(form.getFieldValue("services")[last].startTime).add(duration, 'minutes')

              return {
                startTime: nextTime,
                serviceId: item.serviceId,
                staffId: item.staffId
              }
            } else {
              return item
            }
          })

          form.setFieldsValue({
            services
          })
        }
      }
    });


    // form.getFieldsValue().services.forEach((service, i) => {
    //   if (i > key) {
    //     let offSet = 1;
        
    //     const services = form.getFieldsValue().services.map((item, index) => {
    //       if (removeKey.includes(index) === false) {
    //         if (i === index) {
    //           //GetDuration
    //           let duration = 0;
    //           for (let j = 0; j < categories.length; j++) {
    //             if (form.getFieldValue("services")[index-offSet].serviceId === '' || form.getFieldValue("services")[index-offSet].serviceId === undefined) {
    //               duration = 0;
    //             } else {
    //               const serviceX = categories[j].services.find(item => item.id === form.getFieldValue("services")[index-offSet].serviceId)
    //               if (serviceX) {
    //                 duration = serviceX.duration;
    //               }
    //             }
    //           }  

    //           const nextTime = moment(form.getFieldValue("services")[index-offSet].startTime).add(duration, 'minutes')

    //           return {
    //             startTime: nextTime,
    //             serviceId: item.serviceId,
    //             staffId: item.staffId
    //           }
    //         } else {
    //           return item
    //         }
    //       } else {
    //         offSet = offSet + 1
    //         return item
    //       }
    //     });

    //     //console.log(`${i}: `, services)
        
    //     form.setFieldsValue({
    //       services
    //     })
    //   }
    // });
  }

  const reselectStaffByStartTimeAndService = (startTime, serviceId, fieldKey) => {
    const payload = {
      serviceId,
      startTime, //2020-07-29T07:50:42.000Z
    }

    dispatch(bookingGetStaffsByService(payload))
    .then(result => {
      
      let staffs = result.map(item => ({id: item.id, name: item.name}));
      const staffsbyServiceItem = {
        fieldKey,
        staffs
      }
      
      const filterStaffsbyService = staffsByService.filter(item => item.fieldKey !== fieldKey)
      setStaffsbyService([...filterStaffsbyService, staffsbyServiceItem])

      //Reset Form for StaffId
      const services = form.getFieldsValue().services.map((item, index) => {
        if (index === fieldKey) {
          if (staffs.map(item => item.id).includes(item.staffId)) {
            return {
              startTime: item.startTime,
              serviceId: item.serviceId,
              staffId: item.staffId
            }
          } else {
            if (!(item.staffId === '' || item.staffId === undefined || item.staffId === null)) {
              let serviceName = 0;
              for (let j = 0; j < categories.length; j++) {
                const find = categories[j].services.find(x => x.id === item.serviceId)
                if (find) {
                  serviceName = find.name;
                }
              }     
              message.warning(`The staff serving "${serviceName}" is changed.`);
            }
            
            return {
              startTime: item.startTime,
              serviceId: item.serviceId,
              staffId: staffs[0]?.id
            }
          }
        } else {
          return item
      }});
      
      form.setFieldsValue({
        services
      })
    })
    .catch(error => {
      console.log('error: ', error)
      const staffsbyServiceItem = {
        fieldKey: fieldKey,
        staffs: []
      }
      
      const filterStaffsbyService = staffsByService.filter(item => item.fieldKey !== fieldKey)
      setStaffsbyService([...filterStaffsbyService, staffsbyServiceItem])

      //Reset Form for StaffId
      const services = form.getFieldsValue().services.map((item, index) => {
        if (index === fieldKey) {
          return {
            startTime: item.startTime,
            serviceId: item.serviceId,
            staffId: ''
          }
        } else {
          return item
      }});
      
      form.setFieldsValue({
        services
      })
    })
  }

  const recalculateAmount = (services) => {
    let totalGrand = 0;
    let totalMinute = 0;

    if (services) {
      for(let i=0; i < services.length; i++) {
        if (!removeKey.includes(i)) { //Remove
          if (services[i]?.serviceId !== undefined) {
            for (let j = 0; j < categories.length; j++) {
              const service = categories[j].services.find(item => item.id === services[i].serviceId)
              if (service) {
                totalGrand = totalGrand + service.price;
                totalMinute = totalMinute + service.duration;
              }
            }    
          }  
        }
                  
      }
    }
    
    setGrandAmountTotal(totalGrand)
    setGrandMinuteTotal(totalMinute)

    if (discount === undefined) {
      setTotal(totalGrand)
    } else {
      setTotal(totalGrand - discount)
    }
  }

  useEffect(() => {
    dispatch(customerList())
    .then(result => {
      setCustomers(result.customers.data.data)
    });

    if (booking) {
      setCustomer(booking.customer)
      setDiscount(booking.discount === 0?undefined: booking.discount)
      setGrandAmountTotal(booking.totalPrice)
      setGrandMinuteTotal(booking.totalDuration)
      setTotal(booking.finalPrice)

      //Reset Start Time
      const servicesStartTime = form.getFieldsValue().services && form.getFieldsValue().services.map((item, index) => {
        const startTimeUpdate = moment(`${bookingDate.format("YYYY/MM/DD")} ${item.startTime.format("HH:mm")}`, "YYYY/MM/DD HH:mm")
        return {
          startTime: startTimeUpdate,
          serviceId: item.serviceId,
          staffId: item.staffId
        }
      });
      
      form.setFieldsValue({
        services: servicesStartTime
      })
    }
  }, []);

  useEffect(() => {
    //Check Validate Last Row
    let last = removeKeyCurrent-1;
    for (let j = removeKeyCurrent-1; j >= 0; j--) {
      if (!removeKey.includes(j)) {
        last = j;
        break;
      }
    }

    if (last > 0) {
      recalculateStartTime(last)
    } else {
      recalculateStartTime(0)
    }

  }, [removeKeyCurrent]);

  useEffect(() => {
    recalculateAmount(form.getFieldValue("services"))

  }, [removeKey]);

  return (
    <Form
        form={form}
        name="bookingCreateForm"
        onFinish={values => 
          dispatch(bookingCreate({values, discount, removeKey})).then(()=> onCancel())
        }
        onValuesChange={(_, values) => {
          recalculateAmount(values.services)
        }}  
        initialValues={
          booking ? 
          {
            status: 0,
            customerId: booking.customerId,
            bookingDate: new moment(),
            services: booking.services.length === 0 ?  
                      [{
                        startTime: moment().add(10, 'minutes'),
                        serviceId: '',
                        staffId: ''
                      }]
                      : 
                      booking.services,
            bookingStatus: '',
            bookingNote: '',
            startTimeHeader: true,
            serviceHeader: true,
            staffHeader: true,
          }
          :
          {
            status: 0,
            customer: '',
            bookingDate: new moment(),
            services: [{
              startTime: moment().add(10, 'minutes'),
              serviceId: '',
              staffId: ''
            }],
            bookingStatus: '',
            bookingNote: '',
            startTimeHeader: true,
            serviceHeader: true,
            staffHeader: true,
          }
        }
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 14 }} xs={{ span: 24 }}>
            <Row gutter={24}>
              <Col lg={{ span: 15 }} xs={{ span: 24 }}>
                <Form.Item 
                  name="bookingDate" 
                  label="Booking Date"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the booking Date!'
                    }
                  ]}>
                  <DatePicker style={{ width: 200 }} 
                    onChange={(value) => {
                      setBookingDate(value)

                      //Reset Start Time
                      const servicesStartTime = form.getFieldsValue().services && form.getFieldsValue().services.map((item, index) => {
                        const startTimeUpdate = moment(`${value.format("YYYY/MM/DD")} ${item.startTime.format("HH:mm")}`, "YYYY/MM/DD HH:mm")
                        return {
                          startTime: startTimeUpdate,
                          serviceId: item.serviceId,
                          staffId: item.staffId
                        }
                      });
                      
                      form.setFieldsValue({
                        services: servicesStartTime
                      })

                      //Reset StaffId
                      const arrServices = form.getFieldsValue().services;
                      arrServices && arrServices.forEach((service, i) => {
                        const slotByLine = slot.find(item => item.fieldKey === i);
                        let startTime

                        if (slotByLine === undefined || slotByLine.value === null) {
                          startTime = moment(`${value.format("YYYY/MM/DD")} 23:59`, "YYYY/MM/DD HH:mm")
                        } else {
                          startTime = moment(`${value.format("YYYY/MM/DD")} ${slotByLine.value.format("HH:mm")}`, "YYYY/MM/DD HH:mm")
                        }

                        //Reset per Service
                        reselectStaffByStartTimeAndService(startTime.utc().format(), service.serviceId, i)
                      })
                    }} 
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 9 }} xs={{ span: 24 }}>
                <Form.Item 
                  name="status"
                  label="Status"
                  rules={[{ required: true, message: 'Please select a status!' }]}
                >
                  <Select style={{ width: 120 }}>
                    <Option value={0}>New</Option>
                    <Option value={2}>Arrived</Option>
                    <Option value={3}>Started</Option>
                  </Select>
                </Form.Item>
                
              </Col>
            </Row>
            <Divider style={{width: '109%', marginLeft: -24, marginTop: 22, marginBottom: 10}} />
            <Row gutter={24} type="flex">
              <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                <Space style={{ display: 'flex'}} align="start">
                  <Form.Item
                    name={'startTimeHeader'}
                    rules={[{ required: true}]}
                    label={'Start Time'}
                  > 
                    <div style={{ width: 58 }}></div>
                  </Form.Item>
                  <Form.Item
                    name={'serviceHeader'}
                    rules={[{ required: true, message: 'Missing startTime!!!' }]}
                    label={'Service'}
                  > 
                    <div style={{ width: 200 }}></div>
                  </Form.Item>      
                  <Form.Item
                    name={'staffHeader'}
                    rules={[{ required: true, message: 'Missing startTime!!!' }]}
                    label={'Staff'}
                  > 
                    <div style={{ width: 168 }}></div>
                  </Form.Item>        
                </Space>
                <Form.List name="services">
                {(fields, { add, remove }) => {
                  return (
                    <div style={{marginTop: -50}}>
                      {fields.map(field => {
                        if ( removeKey.includes(field.key) === false) {
                          return (
                            <Space style={{ display: 'flex', marginBottom: -20}} align="start">
                              <Form.Item
                                {...field}
                                name={[field.name, 'startTime']}
                                fieldKey={[field.fieldKey, 'startTime']}
                                rules={[{ required: true, message: 'Missing startTime!!!' }]}
                              >
                                <TimePicker
                                  style={{ width: 120 }}
                                  format={'HH:mm'}
                                  placeholder=""
                                  onChange={value => {
                                    const filterSlot = slot.filter(item => item.fieldKey !== field.key)
                                      setSlot([...filterSlot, {
                                        fieldKey: field.key,
                                        value
                                      }])
    
                                    //Reset Service & Staff Reselected
                                    const services = form.getFieldsValue().services.map((item, index) => {
                                      if (index === field.key) {
                                        return {
                                          serviceId: '',
                                          staffId: '',
                                          startTime: item.startTime
                                        }
                                      } else {
                                        return item
                                    }});
                                    
                                    form.setFieldsValue({
                                      services
                                    })
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'serviceId']}
                                fieldKey={[field.fieldKey, 'serviceId']}
                                rules={[
                                  { 
                                    required: true, 
                                    message: 'Missing service!!!',
                                  },
                                ]}
                              >
                                <Select style={{ width: 220 }} 
                                  onChange={value => {
                                    const slotByLine = slot.find(item => item.fieldKey === field.key);
                                    let startTime
                                    if (slotByLine === undefined || slotByLine.value === null) {
                                      startTime = moment(`${bookingDate.format("YYYY/MM/DD")} 23:59`, "YYYY/MM/DD HH:mm")
                                    } else {
                                      startTime = moment(`${bookingDate.format("YYYY/MM/DD")} ${slotByLine.value.format("HH:mm")}`, "YYYY/MM/DD HH:mm")
                                    }
    
                                    reselectStaffByStartTimeAndService(startTime.utc().format(), value, field.key)
                                    recalculateStartTime(field.key)
                                  }}>
                                  {categories.filter(i => i.active === true).map((item, index) => {
                                    if (item?.services?.filter(i => i.active === true)?.length > 0) {
                                      return (
                                        <OptGroup label={item.name}>
                                          {item.services.filter(i => i.active === true).map(service => {
                                            return (
                                              <Option value={service.id}>{service.name} ({service.duration}min, {formatYen(service.price)})</Option>
                                            )
                                          }
                                          )}
                                      </OptGroup>
                                      )
                                    }
                                  }
                                  )}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'staffId']}
                                fieldKey={[field.fieldKey, 'staffId']}
                                rules={[{ required: true, message: 'Missing staff!!!' }]}
                              >
                                <Select 
                                  style={{ width: 168 }}
                                  onFocus={()=> {
                                    //Reset Service & Staff Reselected
                                    const serviceX = form.getFieldsValue().services.find((item, index) => index === field.fieldKey);
                                    const payload = {
                                      serviceId: serviceX.serviceId,
                                      startTime: serviceX.startTime.format()
                                    }

                                    dispatch(bookingGetStaffsByService(payload))
                                    .then(result => {
                                      
                                      let staffs = result.map(item => ({id: item.id, name: item.name}));
                                      const staffsbyServiceItem = {
                                        fieldKey: field.fieldKey,
                                        staffs
                                      }
                                      
                                      const filterStaffsbyService = staffsByService.filter(item => item.fieldKey !== field.fieldKey)
                                      setStaffsbyService([...filterStaffsbyService, staffsbyServiceItem])
                                    })
                                  }}
                                >
                                  {staffsByService?.length !== 0  && staffsByService.find(item => item.fieldKey === field.key)?.staffs.map(item => (
                                      <Option value={item.id}>{item.name}</Option>
                                    ))
                                  }
                                </Select>
                              </Form.Item>
                              {(fields.length - removeKey.length) > 1 && (
                              <MinusCircleOutlined
                                style={{marginTop: 10}}
                                onClick={() => {
                                  setRemoveKey([
                                    ...removeKey, field.key
                                  ])

                                  setRemoveKeyCurrent(field.key)
                                }}
                              />)
                              }
                            </Space>
                          )
                        }
                      }
                      )}
                      <Space style={{ display: 'flex', marginBottom: 8, marginTop: 20 }} align="start">
                        <Form.Item>
                          <Button
                            style={{marginLeft: -18}}
                            type="link"
                            onClick={ () => {
                              const services = form.getFieldValue("services")

                              //Check Validate Last Row
                              let last = services.length-1;
                              for (let j = services.length-1; j >= 0; j--) {
                                if (!removeKey.includes(j)) {
                                  last = j;
                                  break;
                                }
                              }

                              //GetDuration
                              const serviceId = services[last]?.serviceId
                              let duration = 0;

                              if (serviceId !== '') {
                                for (let j = 0; j < categories.length; j++) {
                                  const serviceX = categories[j].services.find(item => item.id === serviceId)
                                  if (serviceX) {
                                    duration = serviceX.duration;
                                  }
                                }  
                              }

                              const nextTime = moment(services[last].startTime).add(duration, 'minutes')
                              const defaultValue = {
                                serviceId: '',
                                staffId: '',
                                startTime: nextTime
                              };
                              add(defaultValue);

                              setSlot([
                                ...slot, 
                                {
                                  fieldKey: slot.length,
                                  value: nextTime
                                }
                              ])

                              setStaffsbyService([
                                ...staffsByService, 
                                {
                                  fieldKey: staffsByService.length,
                                  staffs: []
                                }
                              ])
                            }}
                            block
                          >
                            <PlusOutlined /> Add Service
                          </Button>
                        </Form.Item>
                      </Space>
                    </div>
                  );
                }}
                </Form.List>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 1 }} xs={{ span: 24 }}>
            <div style={{width: 1, backgroundColor: '#f0f0f0', height: '107%', marginTop: -25}}></div>
          </Col>
          <Col lg={{ span: 9 }} xs={{ span: 24 }}>
            <Row gutter={24} type="flex">
              <Col lg={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item 
                  name="customerId"
                  label="Customer"
                  rules={[{ required: true, message: 'Please select your customer!' }]}
                >
                  <Select
                    onChange={value => {
                      const customerFind= _.get(customers, 'data.data', []).find(item => item.id === value)
                      setCustomer(customerFind)
                    }}
                    showSearch
                    optionFilterProp="children"
                    style={{ width: 246 }}
                    defaultValue={customer?.id}
                    placeholder="Customer"
                  >
                    {customers.map(item => {
                      if (item?.active === true) {
                        return (
                          <Option value={item.id}>{item.name} - {item.phoneNumber}</Option>
                        )
                      }
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 24 }} xs={{ span: 24 }} style={{textAlign: 'right', marginTop: -10, marginBottom: 10}}>
                <a onClick={()=> setIsCreateCustomerModal(true)}>New Customer</a>
              </Col>
            </Row>
            <Divider style={{width: '113%', marginLeft: -38, marginTop: 0, marginBottom: 10}} />

            {customer !== undefined && 
            <Card style={{backgroundColor:'#f0f2f5', marginTop: 20, }}>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={24}>
                  <span style={{color: "#a7a7a7"}}>Customer Name: </span>
                  <span>{customer?.name}</span>
                </Col>
                <Col span={24}>
                  <span style={{color: "#a7a7a7"}}>Gender: </span>
                  <span>
                  {
                    customer === undefined ? '' : customer?.gender === 0?'Male': 'Female'
                  }
                  </span>
                </Col>
                <Col span={24}>
                  <span style={{color: "#a7a7a7"}}>Phone: </span>
                  <span>{customer?.phoneNumber}</span>
                </Col>
                <Col span={24}>
                  <span style={{color: "#a7a7a7"}}>Email: </span>
                  <span >{customer?.email}</span>
                </Col>
              </Row>
            </Card>
            }
          <Card style={{ backgroundColor:'#F8F8F8', marginTop: 20, marginBottom: 20}}>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={10}>
                  <span style={{color: "#a7a7a7"}}>Grand total</span>
                </Col>
              <Col span={14} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                <span>{grandMinuteTotal}min/{formatYen(grandAmountTotal)}</span>
                </Col>
              
                {discount === undefined && (
                  <Col span={24}>
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={16}> <a onClick={()=> setIsCreateDiscountModal(true)}>Discount</a></Col>
                    <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }} > <span >{formatYen(0)}</span></Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {discount !== undefined && (
                  <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={16}>
                      <span style={{color: "#a7a7a7"}}>Discount <a onClick={()=> {
                        formDiscount.setFieldsValue({
                          discount: undefined
                        })
                        setDiscount(undefined)
                        setTotal(grandAmountTotal)
                      }}><CloseCircleOutlined /></a></span>
                    </Col>
                    <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                      <span className="content">{formatYen(discount)}</span>
                    </Col>
                  </Row>
              )}
              
              <div style={{height: 1, width: "100%", backgroundColor: '#600', marginTop: 5, marginBottom: 5}}></div>
            <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={16}>
                <span className="title" style={{ fontSize: 16, color: "#a7a7a7" }}>Total</span>
              </Col>
              <Col span={8} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                <span className="content" style={{ fontSize: 16 }}>{formatYen(total)}</span>
              </Col>
            </Row>
            </Card>
          </Col>
        </Row>
        
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={async () => {
                console.log('Quick Payment')
              }}>
                  Quick Payment
              </Button>
              <Button type="primary" htmlType="submit">
                  Create Booking
              </Button>
            </Col>
          </Row>
        </div>
        <Modal
          visible={isCreateCustomerModal}
          title="Add Customer"
          onCancel={() => setIsCreateCustomerModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <CustomerCreateForm 
            onCancel={() => setIsCreateCustomerModal(false)} 
            onCallBack={(customer) => {
              setCustomer(customer.customer.data)
              dispatch(customerList())
              .then(result => {
                setCustomers(result.customers.data.data)
                form.setFieldsValue({
                  customerId: customer.customer.data?.id
                })
                setIsCreateCustomerModal(false);
              });
            }
          } 
        
          />
        </Modal>
        <Modal
          visible={isCreateDiscountModal}
          title="Add Discount"
          okText="Save"
          cancelText="Cancel"
          onCancel={() => setIsCreateDiscountModal(false)}
          destroyOnClose={true}
          width={380}
          centered
          onOk={() => {
            formDiscount
              .validateFields()
              .then(values => {
                if (values.discount > grandAmountTotal) {
                  message.error("Discount must not be greater than total price!");
                  return;
                }

                setDiscount(values.discount)

                if (values.discount !== undefined) {
                  let totalNew = total - values.discount;
                  setTotal(totalNew)
                }

                setIsCreateDiscountModal(false)
              })
              .catch(err => {
                console.error(err)
              });
          }}
        >
          <Form
              form={formDiscount}
              name="discountForm"
              initialValues={{discount}}
            >
              <Form.Item
                name="discount"
                label="Discount Cash"
                rules={[
                  {
                    required: true,
                    message: 'Please input the Name!'
                  }
                ]}
              >
                <InputNumber 
                    placeholder="Discount Cash" 
                    min={0}
                    formatter={value => `¥${value}`}
                />
              </Form.Item>
          </Form>
        </Modal>
        
      </Form>
  );
};

export default BookingCreateForm;