import React, {useEffect, useState} from 'react';
import { Button, Modal, Form, Col, Row, Select, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { bookingCancel, bookingDetail } from 'providers/BookingProvider/actions';
const { Option } = Select;

const BookingCancelForm = ({ bookingId, onCallback }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(bookingDetail({bookingId}))
    .then(result => {
      setStatus(result.data.status)
      setLoading(false)
    });
  }, []);

  return (
    <Form
        form={form}
        name="bookingCancelForm"
        onFinish={values => {
            Modal.confirm({
              title: 'Confirm',
              icon: <ExclamationCircleOutlined />,
              content: 'Are you sure you want to cancel this booking?',
              okText: 'Sure',
              cancelText: 'No',
              onOk: () => {
                dispatch(bookingCancel({bookingId, fee: values.fee}))
                .then(()=> {
                  onCallback()
                })
              }
            });
          }
        }
      initialValues={{
        fee: 0
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="fee"
              label="Cancellation fee"
              rules={[{ required: true, message: 'Please select a cancellation fee!' }]}
            >
              {loading === false? 
                status === 4 ?
                  <Select 
                    style={{ width: '100%' }}
                  >
                    <Option value={0}>No fee</Option>
                  </Select>
                  :
                  <Select 
                    style={{ width: '100%' }}
                  >
                    <Option value={0}>No fee</Option>
                    <Option value={0.5}>Charge 50% booking fee</Option>
                    <Option value={1}>Charge 100% booking fee</Option>
                  </Select>
                :
                <Select 
                    style={{ width: '100%' }}
                    loading={true}
                  >
                    <Option value={0}>Loading</Option>
                </Select>
              }
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default BookingCancelForm;