import {
  FETCH_STAFF_LIST,
  FETCH_STAFF_LIST_SUCCESS,
  FETCH_STAFF_LIST_FAIL,
  UPDATE_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAIL,
  ADD_STAFF,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  STAFF_DETAIL_CATEGORIES_SERVICES,
  STAFF_DETAIL_CATEGORIES_SERVICES_FAIL,
  STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS,
  STAFF_DETAIL_ADD_SERVICES,
  STAFF_DETAIL_ADD_SERVICES_FAIL,
  STAFF_DETAIL_ADD_SERVICES_SUCCESS,
  STAFF_SEND_INVITE,
  STAFF_SEND_INVITE_FAIL,
  STAFF_SEND_INVITE_SUCCESS,
  STAFF_CANCEL_INVITE,
  STAFF_CANCEL_INVITE_FAIL,
  STAFF_CANCEL_INVITE_SUCCESS,
  STAFF_DETAIL,
  STAFF_DETAIL_SUCCESS,
  STAFF_DETAIL_FAIL,
  STAFF_SCHEDULE_LIST,
  STAFF_SCHEDULE_LIST_SUCCESS,
  STAFF_SCHEDULE_LIST_FAIL,
  STAFF_SCHEDULE_UPDATE,
  STAFF_SCHEDULE_UPDATE_SUCCESS,
  STAFF_SCHEDULE_UPDATE_FAIL
} from './types';

//FETCH STAFF LIST
export function fetchStaffList(payload = {}) {
  return { type: FETCH_STAFF_LIST, payload , meta: { thunk: true }};
}

export function fetchStaffListSuccess(payload = {},meta ) {
  return { type: FETCH_STAFF_LIST_SUCCESS, payload , meta};
}

export function fetchStaffListFail(payload = {}, meta) {
  return { type: FETCH_STAFF_LIST_FAIL, payload, meta, error: true};
}

//ADD STAFF
export function addStaff(payload = {}) {
  return { type: ADD_STAFF, payload, meta: { thunk: true } }
}

export function addStaffSuccess(payload = {}, meta) {
  return { type: ADD_STAFF_SUCCESS, payload, meta }
}

export function addStaffFail(payload = {}, meta) {
  return { type: ADD_STAFF_FAIL, payload, meta, error: true }
}

//UPDATE STAFF_PROFILE
export function updateStaffProfile(payload = {}) {
  return { type: UPDATE_STAFF_PROFILE, payload, meta: { thunk: true } };
}

export function updateStaffProfileSuccess(payload = {}, meta) {
  return { type: UPDATE_STAFF_PROFILE_SUCCESS, payload, meta };
}

export function updateStaffProfileFail(payload = {}, meta) {
  return { type: UPDATE_STAFF_PROFILE_FAIL, payload, meta, error: true };
}

//STAFF_DETAIL
export function staffDetailCategoriesServices(payload = {}) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES, payload, meta: { thunk: true } };
}
export function staffDetailCategoriesServicesSuccess(payload = {}, meta) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS, payload, meta };
}
export function staffDetailCategoriesServicesFail(payload = {}, meta) {
  return { type: STAFF_DETAIL_CATEGORIES_SERVICES_FAIL, payload, meta, error: true };
}

export function staffDetailAddServices(payload = {}) {
  return { type: STAFF_DETAIL_ADD_SERVICES, payload, meta: { thunk: true } };
}
export function staffDetailAddServicesSuccess(payload = {}, meta) {
  return { type: STAFF_DETAIL_ADD_SERVICES_SUCCESS, payload, meta };
}
export function staffDetailAddServicesFail(payload = {}, meta) {
  return { type: STAFF_DETAIL_ADD_SERVICES_FAIL, payload, meta, error: true };
}

export function staffSendInvite(payload = {}) {
  return {type: STAFF_SEND_INVITE, payload, meta: { thunk: true }};
}
export function staffSendInviteSuccess(payload = {}, meta) {
  return {type: STAFF_SEND_INVITE_SUCCESS, payload, meta};
}
export function staffSendInviteFail(payload = {}, meta) {
  return {type: STAFF_SEND_INVITE_FAIL, payload, meta, error: true};
}

export function staffCancelInvite(payload = {}) {
  return {type: STAFF_CANCEL_INVITE, payload, meta: { thunk: true }};
}
export function staffCancelInviteSuccess(payload = {}, meta) {
  return {type: STAFF_CANCEL_INVITE_SUCCESS, payload, meta};
}
export function staffCancelInviteFail(payload = {}, meta) {
  return {type: STAFF_CANCEL_INVITE_FAIL, payload, meta, error: true};
}

export function staffDetail(payload = {}) {
  return { type: STAFF_DETAIL, payload, meta: { thunk: true } }
}
export function staffDetailSuccess(payload = {}, meta) {
  return { type: STAFF_DETAIL_SUCCESS, payload, meta }
}
export function staffDetailFail(payload = {}, meta) {
  return { type: STAFF_DETAIL_FAIL, payload, meta, error: true }
}

export function staffScheduleList(payload = {}) {
  return { type: STAFF_SCHEDULE_LIST, payload, meta: { thunk: true } }
}
export function staffScheduleListSuccess(payload = {}, meta) {
  return { type: STAFF_SCHEDULE_LIST_SUCCESS, payload, meta }
}
export function staffScheduleListFail(payload = {}, meta) {
  return { type: STAFF_SCHEDULE_LIST_FAIL, payload, meta, error: true }
}

export function staffScheduleUpdate(payload = {}) {
  return { type: STAFF_SCHEDULE_UPDATE, payload, meta: { thunk: true } }
}
export function staffScheduleUpdateSuccess(payload = {}, meta) {
  return { type: STAFF_SCHEDULE_UPDATE_SUCCESS, payload, meta }
}
export function staffScheduleUpdateFail(payload = {}, meta) {
  return { type: STAFF_SCHEDULE_UPDATE_FAIL, payload, meta, error: true }
}

