import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _, { values } from 'lodash';
import { Button, Form, Checkbox, Col, Row, Select, Space, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { salonScheduleUpdate } from 'providers/SalonProvider/actions';

import './styles.less';

const { Option } = Select;

const SalonScheduleUpdateForm = ({ salon, onCallback, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  
  const slots = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 30,
      label: '00:30'
    },
    {
      value: 100,
      label: '01:00'
    },
    {
      value: 130,
      label: '01:30'
    },
    {
      value: 200,
      label: '02:00'
    },
    {
      value: 230,
      label: '02:30'
    },
    {
      value: 300,
      label: '03:00'
    },
    {
      value: 330,
      label: '03:30'
    },
    {
      value: 400,
      label: '04:00'
    },
    {
      value: 430,
      label: '04:30'
    },
    {
      value: 500,
      label: '05:00'
    },
    {
      value: 530,
      label: '05:30'
    },
    {
      value: 600,
      label: '06:00'
    },
    {
      value: 630,
      label: '06:30'
    },
    {
      value: 700,
      label: '07:00'
    },
    {
      value: 730,
      label: '07:30'
    },
    {
      value: 800,
      label: '08:00'
    },
    {
      value: 830,
      label: '08:30'
    },
    {
      value: 900,
      label: '09:00'
    },
    {
      value: 930,
      label: '09:30'
    },
    {
      value: 1000,
      label: '10:00'
    },
    {
      value: 1030,
      label: '10:30'
    },
    {
      value: 1100,
      label: '11:00'
    },
    {
      value: 1130,
      label: '11:30'
    },
    {
      value: 1200,
      label: '12:00'
    },
    {
      value: 1230,
      label: '12:30'
    },
    {
      value: 1300,
      label: '13:00'
    },
    {
      value: 1330,
      label: '13:30'
    },
    {
      value: 1400,
      label: '14:00'
    },
    {
      value: 1430,
      label: '14:30'
    },
    {
      value: 1500,
      label: '15:00'
    },
    {
      value: 1530,
      label: '15:30'
    },
    {
      value: 1600,
      label: '16:00'
    },
    {
      value: 1630,
      label: '16:30'
    },
    {
      value: 1700,
      label: '17:00'
    },
    {
      value: 1730,
      label: '17:30'
    },
    {
      value: 1800,
      label: '18:00'
    },
    {
      value: 1830,
      label: '18:30'
    },
    {
      value: 1900,
      label: '19:00'
    },
    {
      value: 1930,
      label: '19:30'
    },
    {
      value: 2000,
      label: '20:00'
    },
    {
      value: 2030,
      label: '20:30'
    },
    {
      value: 2100,
      label: '21:00'
    },
    {
      value: 2130,
      label: '21:30'
    },
    {
      value: 2200,
      label: '22:00'
    },
    {
      value: 2230,
      label: '22:30'
    },
    {
      value: 2300,
      label: '23:00'
    },
    {
      value: 2330,
      label: '23:30'
    },
  ]

  const [isMonday, setIsMonday] = useState(true)
  const [isMondayError, setIsMondayError] = useState(false)
  const [isMondayErrorInline, setIsMondayErrorInline] = useState()
  const [mondayIs24h, setMondayIs24h] = useState(false)

  const [isTueday, setIsTueday] = useState(false)
  const [isTuedayError, setIsTuedayError] = useState(false)
  const [isTuedayErrorInline, setIsTuedayErrorInline] = useState()
  const [tuedayIs24h, setTuedayIs24h] = useState(false)

  const [isWednesday, setIsWednesday] = useState(false)
  const [isWednesdayError, setIsWednesdayError] = useState(false)
  const [isWednesdayErrorInline, setIsWednesdayErrorInline] = useState()
  const [wednesdayIs24h, setWednesdayIs24h] = useState(false)

  const [isThursday, setIsThursday] = useState(false)
  const [isThursdayError, setIsThursdayError] = useState(false)
  const [isThursdayErrorInline, setIsThursdayErrorInline] = useState()
  const [thursdayIs24h, setThursdayIs24h] = useState(false)

  const [isFriday, setIsFriday] = useState(false)
  const [isFridayError, setIsFridayError] = useState(false)
  const [isFridayErrorInline, setIsFridayErrorInline] = useState()
  const [fridayIs24h, setFridayIs24h] = useState(false)

  const [isSaturday, setIsSaturday] = useState(false)
  const [isSaturdayError, setIsSaturdayError] = useState(false)
  const [isSaturdayErrorInline, setIsSaturdayErrorInline] = useState()
  const [saturdayIs24h, setSaturdayIs24h] = useState(false)

  const [isSunday, setIsSunday] = useState(false)
  const [isSundayError, setIsSundayError] = useState(false)
  const [isSundayErrorInline, setIsSundayErrorInline] = useState()
  const [sundayIs24h, setSundayIs24h] = useState(false)

  useEffect(() => {
    //Monday
    if (salon.schedules.filter(item => item.dayOfWeek === 1).length === 0) {
      setIsMonday(false)
    } else {
      setIsMonday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 1).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setMondayIs24h(true)
      }
    })

    //Tueday
    if (salon.schedules.filter(item => item.dayOfWeek === 2).length === 0) {
      setIsTueday(false)
    } else {
      setIsTueday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 2).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setTuedayIs24h(true)
      }
    })

    //Wednesday
    if (salon.schedules.filter(item => item.dayOfWeek === 3).length === 0) {
      setIsWednesday(false)
    } else {
      setIsWednesday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 3).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setWednesdayIs24h(true)
      }
    })

    //Thursday
    if (salon.schedules.filter(item => item.dayOfWeek === 4).length === 0) {
      setIsThursday(false)
    } else {
      setIsThursday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 4).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setThursdayIs24h(true)
      }
    })

    //Friday
    if (salon.schedules.filter(item => item.dayOfWeek === 5).length === 0) {
      setIsFriday(false)
    } else {
      setIsFriday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 5).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setFridayIs24h(true)
      }
    })

    //Saturday
    if (salon.schedules.filter(item => item.dayOfWeek === 6).length === 0) {
      setIsSaturday(false)
    } else {
      setIsSaturday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 6).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setSaturdayIs24h(true)
      }
    })

    //Sunday
    if (salon.schedules.filter(item => item.dayOfWeek === 0).length === 0) {
      setIsSunday(false)
    } else {
      setIsSunday(true)
    }

    salon.schedules.filter(item => item.dayOfWeek === 0).forEach(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      if (startAt === 0 && endAt === 2330) {
        setSundayIs24h(true)
      }
    })

  }, []);

  const checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;
  
    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );
  
    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];
  
      if (currentEndTime > nextStartTime) {
        return true;
      }
    }
  
    return false;
  };

  // const timeSegments1 = [
  //   ["03:00", "04:00"],
  //   ["02:00", "07:00"],
  //   ["12:00", "15:00"]
  // ];
  // const timeSegments2 = [
  //   ["05:00", "07:00"],
  //   ["03:00", "04:00"],
  //   ["12:00", "15:00"]
  // ];
  // console.log(checkOverlap(timeSegments1)); // prints true
  // console.log(checkOverlap(timeSegments2)); // prints false

  const initialValues = { 
    monday: salon.schedules.filter(item => item.dayOfWeek === 1).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    tueday: salon.schedules.filter(item => item.dayOfWeek === 2).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    wednesday: salon.schedules.filter(item => item.dayOfWeek === 3).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    thursday: salon.schedules.filter(item => item.dayOfWeek === 4).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    friday: salon.schedules.filter(item => item.dayOfWeek === 5).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    saturday: salon.schedules.filter(item => item.dayOfWeek === 6).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
    sunday: salon.schedules.filter(item => item.dayOfWeek === 0).map(item => {
      let startAt = parseInt(moment(item.startAt).format('HHmm'));
      let endAt = parseInt(moment(item.endAt).format('HHmm'));
      return {
        startAt,
        endAt
      }
    }),
  }

  const onSubmit = (values) => {
    //validate Monday
    let mondayError = true

    let validateMondays = values.monday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })
    
    if (mondayIs24h === true) {
      setIsMondayError(false)
      mondayError = false
    } else {
      if (checkOverlap(validateMondays)) {
        setIsMondayError(true)
        mondayError = true
      } else {
        setIsMondayError(false)
        mondayError = false
      }
    }

    //validate Tueday
    let tuedayError = true

    let validateTuedays = values.tueday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })
    
    if (tuedayIs24h === true) {
      setIsTuedayError(false)
      tuedayError = false
    } else {
      if (checkOverlap(validateTuedays)) {
        setIsTuedayError(true)
        tuedayError = true
      } else {
        setIsTuedayError(false)
        tuedayError = false
      }
    }

    //validate Wednesday
    let wednesdayError = true

    let validateWednesdays = values.wednesday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })

    if (wednesdayIs24h === true) {
      setIsWednesdayError(false)
      wednesdayError = false
    } else {
      if (checkOverlap(validateWednesdays)) {
        setIsWednesdayError(true)
        wednesdayError = true
      } else {
        setIsWednesdayError(false)
        wednesdayError = false
      }
    }

    //validate Thursday
    let thursdayError = true

    let validateThursdays = values.thursday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })

    if (thursdayIs24h === true) {
      setIsThursdayError(false)
      thursdayError = false
    } else {
      if (checkOverlap(validateThursdays)) {
        setIsThursdayError(true)
        thursdayError = true
      } else {
        setIsThursdayError(false)
        thursdayError = false
      }
    }

    //validate Friday
    let fridayError = true

    let validateFridays = values.friday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })

    if (fridayIs24h === true) {
      setIsFridayError(false)
      fridayError = false
    } else {
      if (checkOverlap(validateFridays)) {
        setIsFridayError(true)
        fridayError = true
      } else {
        setIsFridayError(false)
        fridayError = false
      }
    }

    //validate Saturday
    let saturdayError = true

    let validateSaturdays = values.saturday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })
    
    if (saturdayIs24h === true) {
      setIsSaturdayError(false)
      saturdayError = false
    } else {
      if (checkOverlap(validateSaturdays)) {
        setIsSaturdayError(true)
        saturdayError = true
      } else {
        setIsSaturdayError(false)
        saturdayError = false
      }
    }

    //validate Sunday
    let sundayError = true

    let validateSundays = values.sunday.map(item => {
      let startHH = Math.floor(item.startAt / 100);
      let startmm = item.startAt - startHH * 100;
      const startHHmm = `${startHH < 10 ? `0${startHH}` : startHH}:${startmm < 10 ? `0${startmm}` : startmm}`;

      let endHH = Math.floor(item.endAt / 100);
      let endmm = item.endAt - endHH * 100;
      const endHHmm = `${endHH < 10 ? `0${endHH}` : endHH}:${endmm < 10 ? `0${endmm}` : endmm}`;

      return [startHHmm, endHHmm]
    })
    
    if (sundayIs24h === true) {
      setIsSundayError(false)
      sundayError = false
    } else {
      if (checkOverlap(validateSundays)) {
        setIsSundayError(true)
        sundayError = true
      } else {
        setIsSundayError(false)
        sundayError = false
      }
    }

    //schedules
    let schedules = []
    let currentDate = new moment();

    //Monday
    if (isMonday === true) {
      if (mondayIs24h === false) {
        values.monday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 1,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 1,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Tueday
    if (isTueday === true) {
      if (tuedayIs24h === false) {
        values.tueday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 2,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 2,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Wednesday
    if (isWednesday === true) {
      if (wednesdayIs24h === false) {
        values.wednesday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 3,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 3,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Thursday
    if (isThursday === true) {
      if (thursdayIs24h === false) {
        values.thursday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 4,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 4,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Friday
    if (isFriday === true) {
      if (fridayIs24h === false) {
        values.friday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 5,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 5,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Saturday
    if (isSaturday === true) {
      if (saturdayIs24h === false) {
        values.saturday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 6,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 6,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    //Sunday
    if (isSunday === true) {
      if (sundayIs24h === false) {
        values.sunday.forEach(element => {
          let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.startAt / 100)}:${element.startAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()
          let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(element.endAt / 100)}:${element.endAt % 100}`, "YYYY/MM/DD HH:mm").toISOString()

          let item = {
            dayOfWeek: 0,
            startAt,
            endAt
          }
          schedules.push(item)
        });
      } else {
        let startAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(0 / 100)}:${0 % 100}`, "YYYY/MM/DD HH:mm").toISOString()
        let endAt = moment(`${currentDate.format("YYYY/MM/DD")} ${Math.floor(2330 / 100)}:${2330 % 100}`, "YYYY/MM/DD HH:mm").toISOString()

        let item = {
          dayOfWeek: 0,
          startAt,
          endAt
        }
        schedules.push(item)
      }
    }

    console.log('schedules: ', schedules)

    if (mondayError === true || tuedayError === true || wednesdayError === true || thursdayError === true || fridayError === true || saturdayError === true || sundayError === true) {
      return
    } else {
      dispatch(salonScheduleUpdate({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        schedules,
        salonId: user['https://user_metadata']?.salon_id
      }))
      .then((result) => {
        onCallback()
      }).catch((error) => {
        console.log('Oops!', error)
      })
    }

  }

  return (
    <Form
        className="salon-form"
        style={{marginLeft: 15, marginRight: 15}}
        form={form}
        name="salonScheduleUpdateForm"
        onFinish={values => onSubmit(values)}
        onValuesChange={(_, values) => {
          //console.log(values)

          //Monday
          let mondayErrorInline = false
          values.monday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              mondayErrorInline = true
            }
          });
          if (mondayErrorInline === true) {
            setIsMondayErrorInline('End time must be greater than start time!')
          } else {
            setIsMondayErrorInline()
          }

          //Tueday
          let tuedayErrorInline = false
          values.tueday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              tuedayErrorInline = true
            }
          });
          if (tuedayErrorInline === true) {
            setIsTuedayErrorInline('End time must be greater than start time!')
          } else {
            setIsTuedayErrorInline()
          }

          //Wednesday
          let wednesdayErrorInline = false
          values.wednesday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              wednesdayErrorInline = true
            }
          });
          if (wednesdayErrorInline === true) {
            setIsWednesdayErrorInline('End time must be greater than start time!')
          } else {
            setIsWednesdayErrorInline()
          }

          //Thursday
          let thursdayErrorInline = false
          values.thursday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              thursdayErrorInline = true
            }
          });
          if (thursdayErrorInline === true) {
            setIsThursdayErrorInline('End time must be greater than start time!')
          } else {
            setIsThursdayErrorInline()
          }

          //Friday
          let fridayErrorInline = false
          values.friday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              fridayErrorInline = true
            }
          });
          if (fridayErrorInline === true) {
            setIsFridayErrorInline('End time must be greater than start time!')
          } else {
            setIsFridayErrorInline()
          }

          //Saturday
          let saturdayErrorInline = false
          values.saturday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              saturdayErrorInline = true
            }
          });
          if (saturdayErrorInline === true) {
            setIsSaturdayErrorInline('End time must be greater than start time!')
          } else {
            setIsSaturdayErrorInline()
          }

          //Sunday
          let sundayErrorInline = false
          values.sunday.forEach(element => {
            if (element?.startAt >= element?.endAt) {
              sundayErrorInline = true
            }
          });
          if (sundayErrorInline === true) {
            setIsSundayErrorInline('End time must be greater than start time!')
          } else {
            setIsSundayErrorInline()
          }

          
        }}  
        initialValues={initialValues}
      >
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isMonday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsMonday(true)
              } else {
                setIsMonday(false)
              }
              }}><strong>Monday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="monday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isMonday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={mondayIs24h || (isMonday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={mondayIs24h || (isMonday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && mondayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={mondayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={mondayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={mondayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={mondayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && mondayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isMondayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isMondayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isMondayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isMonday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item >
              <Checkbox 
                disabled={!isMonday}
                checked={mondayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setMondayIs24h(true)
                } else {
                  setMondayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>

        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isTueday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsTueday(true)
              } else {
                setIsTueday(false)
              }
              }}><strong>Tueday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="tueday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isTueday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={tuedayIs24h || (isTueday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={tuedayIs24h || (isTueday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && tuedayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={tuedayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={tuedayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={tuedayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={tuedayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && tuedayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isTuedayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isTuedayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isTuedayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isTueday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isTueday}
                checked={tuedayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setTuedayIs24h(true)
                } else {
                  setTuedayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>

        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isWednesday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsWednesday(true)
              } else {
                setIsWednesday(false)
              }
              }}><strong>Wednesday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="wednesday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isWednesday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={wednesdayIs24h || (isWednesday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={wednesdayIs24h || (isWednesday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && wednesdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={wednesdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={wednesdayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={wednesdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={wednesdayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && wednesdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isWednesdayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isWednesdayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isWednesdayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isWednesday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isWednesday}
                checked={wednesdayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setWednesdayIs24h(true)
                } else {
                  setWednesdayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>

        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isThursday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsThursday(true)
              } else {
                setIsThursday(false)
              }
              }}><strong>Thursday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="thursday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isThursday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={thursdayIs24h || (isThursday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={thursdayIs24h || (isThursday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && thursdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={thursdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={thursdayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={thursdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={thursdayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && thursdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isThursdayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isThursdayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isThursdayErrorInline}</div>
                    </Space>
                  )}

                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isThursday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isThursday}
                checked={thursdayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setThursdayIs24h(true)
                } else {
                  setThursdayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>

        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isFriday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsFriday(true)
              } else {
                setIsFriday(false)
              }
              }}><strong>Friday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="friday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isFriday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={fridayIs24h || (isFriday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={fridayIs24h || (isFriday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && fridayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={fridayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={fridayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={fridayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={fridayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && fridayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isFridayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isFridayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isFridayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isFriday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isFriday}
                checked={fridayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setFridayIs24h(true)
                } else {
                  setFridayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>

        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isSaturday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsSaturday(true)
              } else {
                setIsSaturday(false)
              }
              }}><strong>Saturday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="saturday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isSaturday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={saturdayIs24h || (isSaturday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={saturdayIs24h || (isSaturday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && saturdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={saturdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={saturdayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={saturdayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={saturdayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && saturdayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isSaturdayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isSaturdayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isSaturdayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isSaturday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isSaturday}
                checked={saturdayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setSaturdayIs24h(true)
                } else {
                  setSaturdayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>  
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)'}}>
          <Col span={24}>
            <div style={{width: '104%', backgroundColor: 'white', height: '100%', marginLeft: -24}}></div>
          </Col>
        </Row>
        
        <Row gutter={24} style={{backgroundColor: 'rgba(128, 128, 128, 0.08)', paddingTop: 10}}>
          <Col span={5}>
            <Checkbox 
              checked={isSunday}
              onChange={(e) => {
              if (e.target.checked === true) {
                setIsSunday(true)
              } else {
                setIsSunday(false)
              }
              }}><strong>Sunday</strong></Checkbox>
          </Col>
          <Col span={1} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={9}>
            <Form.List name="sunday">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(field => {
                    if (isSunday === false) {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                          >
                            <Select
                              disabled={sundayIs24h || (isSunday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                          >
                              <Select
                              disabled={sundayIs24h || (isSunday === false)}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && sundayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    } else {
                      return (
                        <Space style={{ display: 'flex', marginBottom: -15}} align="start">
                          <Form.Item
                            {...field}
                            name={[field.name, 'startAt']}
                            fieldKey={[field.fieldKey, 'startAt']}
                            rules={sundayIs24h === false ? [
                              {
                                required: true, 
                                message: 'Start time is required!'
                              },
                            ]: []}
                          >
                            <Select
                              disabled={sundayIs24h}
                              style={{width: 120, marginLeft: 24}}
                            >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'endAt']}
                            fieldKey={[field.fieldKey, 'endAt']}
                            rules={sundayIs24h === false ? [
                              {
                                required: true, 
                                message: 'End time is required!'
                              },
                            ]: []}
                          >
                              <Select
                                disabled={sundayIs24h}
                                style={{width: 120, marginLeft: 24}}
                              >
                              {slots.map((item) => {
                                return (
                                  <Option value={item.value}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </Form.Item>
                          {fields.length !== 1 && sundayIs24h !== true && (
                          <MinusCircleOutlined
                            style={{marginTop: 10}}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                          )}
                        </Space>
                      )
                    }
                  }
                  )}
                  { isSundayError === true && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">Start time must be greater than previous shift end time!</div>
                    </Space>
                  )}

                  { isSundayErrorInline !== '' && (
                    <Space style={{ display: 'flex', marginLeft: 24, marginTop: 0}} align="start">
                      <div className="salon-schedule-error">{isSundayErrorInline}</div>
                    </Space>
                  )}
                  
                  <Space style={{ display: 'flex', marginBottom: -25, marginLeft: 6}} align="start">
                    <Form.Item>
                      <Button
                        type="link"
                        disabled={!isSunday}
                        onClick={ () => {
                          add();  
                        }}
                        block
                      >
                        <PlusOutlined /> Add more
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              );
            }}
            </Form.List>
          </Col>  
          <Col lg={{ span: 1 }} xs={{ span: 24 }} style={{marginRight: -24}}>
            <div style={{width: 2, backgroundColor: 'white', height: '114%', marginTop: -10}}></div>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Checkbox 
                disabled={!isSunday}
                checked={sundayIs24h}
                onChange={(e) => {
                if (e.target.checked === true) {
                  setSundayIs24h(true)
                } else {
                  setSundayIs24h(false)
                }
                }}
              >24 hours</Checkbox>
            </Form.Item>
                          
          </Col>
        </Row>
        
        <Row gutter={24} type="flex" style={{textAlign: 'right', marginTop: 24}}>
          <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
            <Button type="primary" htmlType="submit" style={{marginRight: -12}}>
              Save and apply for Staff
            </Button>
          </Col>
        </Row>
        
      </Form>
  );
};

export default SalonScheduleUpdateForm;