import React from 'react';
import { Outlet } from 'react-router-dom';

const MySalonLayoutApp = () => {
  return (
    <Outlet />
  )
};



export default MySalonLayoutApp;