import { all } from 'redux-saga/effects';
import watchSample from 'providers/SampleProvider/saga';
import watchAuth from 'providers/AuthProvider/saga';
import watchCustomer from 'providers/CustomerProvider/saga';
import watchCategory from 'providers/CategoryProvider/saga';
import watchService from 'providers/ServiceProvider/saga';
import watchStaffList from 'providers/StaffProvider/saga';
import watchBooking from 'providers/BookingProvider/saga';
import watchSalon from 'providers/SalonProvider/saga';
import watchPayments from 'providers/PaymentProvider/saga';
export default function* rootSaga() {
  yield all([
    watchSample(),
    watchAuth(),
    watchCustomer(),
    watchCategory(),
    watchStaffList(),
    watchService(),
    watchBooking(),
    watchSalon(),
    watchPayments()
  ]);
}