import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_CURRENT_USER,
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAIL,
  FETCH_PREFECTURES,
  FETCH_PREFECTURES_SUCCESS,
  FETCH_PREFECTURES_FAIL,
  FETCH_CITY,
  FETCH_CITY_SUCCESS,
  FETCH_CITY_FAIL,
  REQUESR_ACCOUNT_SALON,
  REQUESR_ACCOUNT_SALON_FAIL,
  REQUESR_ACCOUNT_SALON_SUCCESS,
  COMPLETED_PROFILE_SALON,
  COMPLETED_PROFILE_SALON_SUCCESS,
  COMPLETED_PROFILE_SALON_FAIL,
  GET_ADDRESS_BY_POST_CODE,
  GET_ADDRESS_BY_POST_CODE_SUCCESS,
  GET_ADDRESS_BY_POST_CODE_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_SUCCESS,
  UPDATE_SCHEDULES_SALON,
  UPDATE_SCHEDULES_SALON_SUCCESS,
  UPDATE_SCHEDULES_SALON_FAIL
} from './types';

export function login(payload = {}) {
  return { type: LOGIN, payload, meta: { thunk: true } };
}
export function loginSuccess(payload = {}, meta) {
  return { type: LOGIN_SUCCESS, payload, meta };
}
export function loginFail(payload = {}, meta) {
  return { type: LOGIN_FAIL, payload, meta, error: true };
}

export function logout(payload = {}) {
  return { type: LOGOUT, payload, meta: { thunk: true } };
}
export function logoutSuccess(payload = {}, meta) {
  return { type: LOGIN_FAIL, payload, meta };
}
export function logoutFail(payload = {}, meta) {
  return { type: LOGIN_FAIL, payload, meta, error: true };
}

export function setCurrentUser(payload = {}) {
  return { type: SET_CURRENT_USER, payload };
}
//fetch areas
export function fetchAreas(payload = {}) {
  return { type: FETCH_AREAS, payload }
}

export function fetchAreasSuccess(payload = {}) {
  return { type: FETCH_AREAS_SUCCESS, payload }
}

export function fetchAreasFail(payload = {}) {
  return { type: FETCH_AREAS_FAIL, payload }
}

//fetch prefecture
export function fetchPrefectures(payload = {}) {
  return { type: FETCH_PREFECTURES, payload }
}

export function fetchPrefecturesSuccess(payload = {}) {
  return { type: FETCH_PREFECTURES_SUCCESS, payload }
}

export function fetchPrefecturesFail(payload = {}) {
  return { type: FETCH_PREFECTURES_FAIL, payload }
}

//fetch cities
export function fetchCity(payload = {}) {
  return { type: FETCH_CITY, payload }
}
export function fetchCitySuccess(payload = {}) {
  return { type: FETCH_CITY_SUCCESS, payload }
}
export function fetchCityFail(payload = {}) {
  return { type: FETCH_CITY_FAIL, payload }
}

//Request Account
export function requestAccountSalon(payload = {}) {
  return { type: REQUESR_ACCOUNT_SALON, payload, meta: { thunk: true } };
}
export function requestAccountSalonSuccess(payload = {}, meta) {
  return { type: REQUESR_ACCOUNT_SALON_SUCCESS, payload, meta };
}
export function requestAccountSalonFail(payload = {}, meta) {
  return { type: REQUESR_ACCOUNT_SALON_FAIL, payload, meta, error: true };
}

export function completedProfileSalon(payload = {}) {
  return { type: COMPLETED_PROFILE_SALON, payload, meta: { thunk: true } };
}
export function completedProfileSalonSuccess(payload = {}, meta) {
  return { type: COMPLETED_PROFILE_SALON_SUCCESS, payload, meta };
}
export function completedProfileSalonFail(payload = {}, meta) {
  return { type: COMPLETED_PROFILE_SALON_FAIL, payload, meta, error: true };
}

export function getAddressByPostCode(payload = {}) {
  return {type: GET_ADDRESS_BY_POST_CODE, payload, meta: {thunk: true}};
}
export function getAddressByPostCodeSuccess(payload = {}, meta) {
  return {type: GET_ADDRESS_BY_POST_CODE_SUCCESS, payload, meta};
}
export function getAddressByPostCodeFail(payload = {}, meta) {
  return {type: GET_ADDRESS_BY_POST_CODE_FAIL, payload, meta, error: true};
}

export function uploadImage(payload = {}) {
  return {type: UPLOAD_IMAGE, payload, meta: {thunk: true}};
}
export function uploadImageSuccess(payload = {}, meta) {
  return {type: UPLOAD_IMAGE_SUCCESS, payload, meta};
}
export function uploadImageFail(payload = {}, meta) {
  return {type: UPLOAD_IMAGE_FAIL, payload, meta, error: true};
}

export function updateSchedulesSalon(payload = {}) {
  return { type: UPDATE_SCHEDULES_SALON, payload, meta: { thunk: true } };
}
export function updateSchedulesSalonSuccess(payload = {}, meta) {
  return { type: UPDATE_SCHEDULES_SALON_SUCCESS, payload, meta };
}
export function updateSchedulesSalonFail(payload = {}, meta) {
  return { type: UPDATE_SCHEDULES_SALON_FAIL, payload, meta, error: true };
}


