export const BOOKING_GET_STAFFS_BY_SERVICE = 'BOOKING_GET_STAFFS_BY_SERVICE';
export const BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS = 'BOOKING_GET_STAFFS_BY_SERVICE_SUCCESS';
export const BOOKING_GET_STAFFS_BY_SERVICE_FAIL = 'BOOKING_GET_STAFFS_BY_SERVICE_FAIL';

export const BOOKING_LIST = 'BOOKING_LIST';
export const BOOKING_LIST_SUCCESS = 'BOOKING_LIST_SUCCESS';
export const BOOKING_LIST_FAIL = 'BOOKING_LIST_FAIL';

export const BOOKING_DETAIL = 'BOOKING_DETAIL';
export const BOOKING_DETAIL_SUCCESS = 'BOOKING_DETAIL_SUCCESS';
export const BOOKING_DETAIL_FAIL = 'BOOKING_DETAIL_FAIL';

export const BOOKING_CREATE = 'BOOKING_CREATE';
export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS';
export const BOOKING_CREATE_FAIL = 'BOOKING_CREATE_FAIL';

export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_UPDATE_SUCCESS = 'BOOKING_UPDATE_SUCCESS';
export const BOOKING_UPDATE_FAIL = 'BOOKING_UPDATE_FAIL';

export const BOOKING_ORDER = 'BOOKING_ORDER';
export const BOOKING_ORDER_SUCCESS = 'BOOKING_ORDER_SUCCESS';
export const BOOKING_ORDER_FAIL = 'BOOKING_ORDER_FAIL';

export const BOOKING_DELETE = 'BOOKING_DELETE';
export const BOOKING_DELETE_SUCCESS = 'BOOKING_DELETE_SUCCESS';
export const BOOKING_DELETE_FAIL = 'BOOKING_DELETE_FAIL';

export const BOOKING_CANCEL = 'BOOKING_CANCEL';
export const BOOKING_CANCEL_SUCCESS = 'BOOKING_CANCEL_SUCCESS';
export const BOOKING_CANCEL_FAIL = 'BOOKING_CANCEL_FAIL';

export const QUICK_PAYMENT = 'QUICK_PAYMENT';
export const QUICK_PAYMENT_SUCCESS = 'QUICK_PAYMENT_SUCCESS';
export const QUICK_PAYMENT_FAIL = 'QUICK_PAYMENT_FAIL';

export const GET_APPS = 'GET_APPS';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_FAIL = 'GET_APPS_FAIL';

export const BOOKING_TIPS = 'BOOKING_TIPS';
export const BOOKING_TIPS_SUCCESS = 'BOOKING_TIPS_SUCCESS';
export const BOOKING_TIPS_FAIL = 'BOOKING_TIPS_FAIL';

export const BOOKING_DELETE_TIPS = 'BOOKING_DELETE_TIPS';
export const BOOKING_DELETE_TIPS_SUCCESS = 'BOOKING_DELETE_TIPS_SUCCESS';
export const BOOKING_DELETE_TIPS_FAIL = 'BOOKING_DELETE_TIPS_FAIL';

export const GET_PAYMENT_BYBOOKINGID = 'GET_PAYMENT_BYBOOKINGID';
export const GET_PAYMENT_BYBOOKINGID_SUCCESS = 'GET_PAYMENT_BYBOOKINGID_SUCCESS';
export const GET_PAYMENT_BYBOOKINGID_FAIL = 'GET_PAYMENT_BYBOOKINGID_FAIL';