import React, { useState, useEffect } from 'react';
import moment from 'moment';


import { Layout, Button, Input, Breadcrumb, Card, Row, Col, Modal, Tabs, DatePicker, Radio, Avatar, Form, Menu, Dropdown } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import 'react-big-calendar/lib/sass/styles.scss'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import ExampleControlSlot from './ExampleControlSlot'

const localizer = momentLocalizer(moment)
const events = [
  {
    id: 0,
    title: 'Board meeting',
    start: new Date(2018, 0, 29, 9, 0, 0),
    end: new Date(2018, 0, 29, 13, 0, 0),
    resourceId: 1,
  },
  {
    id: 1,
    title: 'MS training',
    allDay: true,
    start: new Date(2018, 0, 29, 14, 0, 0),
    end: new Date(2018, 0, 29, 16, 30, 0),
    resourceId: 2,
  },
  {
    id: 2,
    title: 'Team lead meeting',
    start: new Date(2018, 0, 29, 8, 30, 0),
    end: new Date(2018, 0, 29, 12, 30, 0),
    resourceId: 3,
  },
  {
    id: 11,
    title: 'Birthday Party',
    start: new Date(2018, 0, 30, 7, 0, 0),
    end: new Date(2018, 0, 30, 10, 30, 0),
    resourceId: 4,
  },
]

const resourceMap = [
  { resourceId: 1, resourceTitle: 'Board room' },
  { resourceId: 2, resourceTitle: 'Training room' },
  { resourceId: 3, resourceTitle: 'Meeting room 1' },
  { resourceId: 4, resourceTitle: 'Meeting room 2' },
]

const SchedulerPage = () => {
  useEffect(() => {
  }, []);

  return (
    <Layout>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon/scheduler">Scheduler</a></Breadcrumb.Item>
          <Breadcrumb.Item> Home</Breadcrumb.Item>
        </Breadcrumb>
        <div className='bookings-page'>
          <Card style={{ marginBottom: 18 }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                <Col className="gutter-row" span={12}>
                  <h2>Scheduler</h2>
                </Col>
              </Row>
            </Card>
            <Card>
            <Calendar
              events={events}
              localizer={localizer}
              defaultView={Views.DAY}
              views={['day', 'work_week']}
              step={60}
              defaultDate={new Date(2018, 0, 29)}
              resources={resourceMap}
              resourceIdAccessor="resourceId"
              resourceTitleAccessor="resourceTitle"
            />

              
            </Card>
          </div>
      </Layout>
    </Layout>
  )
};

export default SchedulerPage;