import axios from 'axios';
import _ from 'lodash';
import environment from 'environment';

const getAxiosInstance = () => {
  const token = localStorage.getItem('__a_token');
  const headers = {
    ...(token ? { Authorization: 'Bearer ' + token} : {})
  };

  const axiosInstance = axios.create({
    baseURL: environment.baseURL,
    headers
  });

  axiosInstance.interceptors.response.use((response) => {
    if ([200, 201, 202, 204].includes(response.status)) {
      const result = response.data;
      if (_.isObject(result.isObject)) {
        result.statusCode = response.status;
      }
      return response.data;
    }
    return Promise.reject(response);
  }, (error) => {
    console.log('code: ', error.response.status)
    let { code } = error.response.data || error.response.status;
    code = code || error.response.status;

    if (code) {
      // Authorize error
      if (code === 401 || code === 403) {
        localStorage.removeItem('__a_token');
        window.location = '/'
      }

      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.response.data);
  });

  return axiosInstance;
};

const request = (url, data = {}, method = 'POST', isNoHeader) => {
  try {
    if (isNoHeader) { //Not using header
      switch (method) {
        case 'GET': return axios.get(url, { params: data });
        case 'PUT': return axios.put(url, data);
        case 'PATCH': return axios.patch(url, data);
        case 'DELETE': return axios.delete(url, data);
        default: return axios.post(url, data);
      }
    } else {
      const API = getAxiosInstance();
      switch (method) {
        case 'GET': return API.get(url, { params: data });
        case 'PUT': return API.put(url, data);
        case 'PATCH': return API.patch(url, data);
        case 'DELETE': return API.delete(url, data);
        default: return API.post(url, data);
      }
    }
    
  } catch (error) {
    return Promise.reject(error);
  }
};

export default request;
