export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';

export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';

export const CATEGORY_CREATE = 'CATEGORY_CREATE';
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL';

export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';

export const CATEGORY_ORDER = 'CATEGORY_ORDER';
export const CATEGORY_ORDER_SUCCESS = 'CATEGORY_ORDER_SUCCESS';
export const CATEGORY_ORDER_FAIL = 'CATEGORY_ORDER_FAIL';

export const CATEGORY_DELETE = 'CATEGORY_DELETE';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';