import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from "react-router-dom";
import './styles.less';
import _ from 'lodash';
import moment from 'moment';
import { formatYen } from 'utils/stringFormat';

import { Layout, Modal, Input, Button, Breadcrumb, Popover, Menu, Avatar, Statistic, Table } from 'antd';
import {  Card, Row, Col, DatePicker, Form } from 'antd';
import { SearchOutlined, EditOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';

import { bookingList } from 'providers/BookingProvider/actions';
import CustomerUpdateForm from 'components/Desktop/Customer/CustomerUpdateForm';
import { useSelector, useDispatch } from 'react-redux';
import { customerDetail } from 'providers/CustomerProvider/actions';

const { Content, Sider } = Layout;
const { RangePicker } = DatePicker;

const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};

const CustomerInfo = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const {
    customers = {},
    loading
  } = useSelector((state) => state.customer);

  const {
    bookings = {},
  } = useSelector((state) => state.booking);
  const [currentCustomer, setCurrentCustomer] = useState([]);
  const dataSource = _.get(bookings, 'data');
  const totalPage = _.get(bookings, 'pagination.total');
  const createDate = _.get(currentCustomer, 'createdAt');
  const updateDate = _.get(currentCustomer, 'updatedAt');

  //CustomerEditForm
 
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [pageSize, SetPageSize] = useState(10);
  const [filterStatus, setFiterStatus] = useState();
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState([]);
  const [keyword, setKeyword] = useState(_.get(currentCustomer, 'name'));
  const [loadingDetail, setLoadingDetail] = useState(false);
  
  useEffect(() => {
    setLoadingDetail(true)
    dispatch(customerDetail(id))
      .then(result => {
        setCurrentCustomer(result.data)
        setLoadingDetail(false)
      })
      .catch(err => setLoadingDetail(false))
  }, []);


  useEffect(() => {
    let params = {};
    params['keyword'] = _.trim(keyword);
    if (dates && dates[0]) {
      params['from'] = moment(dates[0]).utc().format();
    }
    if (dates && dates[1]) {
      params['to'] = moment(dates[1]).utc().format();
    }
    params['page'] = currentPage;
    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    params['status'] = filterStatus;
    dispatch(bookingList(params));
  }, [filterStatus, currentPage, sortedInfo.columnKey, sortedInfo.order, pageSize]);

  const onSubmitSearch = () => {
    let params = {};
    params['keyword'] = _.trim(keyword);
    if (dates && dates[0]) {
      params['from'] = moment(dates[0]).utc().format();
    }
    if (dates && dates[1]) {
      params['to'] = moment(dates[1]).utc().format();
    }
    params['page'] = currentPage;
    params['status'] = filterStatus;
    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    dispatch(bookingList(params));
  };

  const columnsBookings = [
    {
      title: 'Booking ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Treatment date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150',
      render: (record) => (moment(record).format('HH:mm YYYY-MM-DD')),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Customer name',
      dataIndex: 'customer',
      key: 'customer',
      width: '150',
      render: (record) => {
        return (record.name)
      },
    },
    {
      title: 'Staff',
      dataIndex: 'orders',
      key: 'orders',
      render: (record) => {
        let staffs = record.map(item => ({
          id: item.staff.id,
          name: item.staff.name
        }))
        if (_.uniqBy(staffs, 'id').length >= 3) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
              {staffs[0].name},
              <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }} />
              {staffs[1].name},
              <Popover content={(
                <div>
                  {_.uniqBy(staffs, 'id').map(staff => {
                    //console.log(staff)
                    return <p>{staff.name}</p>
                  })}
                </div>
              )} title="Staff">
                <Button type="link" style={{ marginLeft: -8 }}>+ 3 more</Button>
              </Popover>
            </div >
          );
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_.uniqBy(staffs, 'id').length === 1 && (
                <>
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
                  {_.uniqBy(staffs, 'id')[0]?.name}
                </>
              )}
              {_.uniqBy(staffs, 'id').length === 2 && (
                <>
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
                  {_.uniqBy(staffs, 'id')[0]?.name},
                  <Avatar icon={<UserOutlined />} style={{ marginRight: 8, marginLeft: 4 }} />
                  {_.uniqBy(staffs, 'id')[1].name}
                </>
              )}
            </div >
          );
        }
      }
    },
    {
      title: 'Total fee',
      width: '15%',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text, record, index) => {
        if (record['totalPrice'] !== undefined) {
          return (
            <div>{formatYen(record['totalPrice'])}</div>
          );
        }
      }
    },
    {
      title: 'Status update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '150',
      render: (record) => (moment(record).format('HH:mm YYYY-MM-DD')),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (record) => {
        switch (record) {
          case 0:
            return (<div className="status-block new">New</div>);
            break;
          case 1:
            return (<div className="status-block cancel">Cancel</div>);
            break;
          case 2:
            return (<div className="status-block arrived">Arrived</div>);
            break;
          case 3:
            return (<div className="status-block started">Started</div>);
            break;
          case 4:
            return (<div className="status-block done">Done</div>);
            break;
          default:
            return (<div className="status-block new">New</div>);
        }
      },
      filterMultiple: false,
      filters: [
        {
          text: 'New',
          value: 0,
        },
        {
          text: 'Cancel',
          value: 1,
        },
        {
          text: 'Arrived',
          value: 2,
        },
        {
          text: 'Started',
          value: 3,
        },
        {
          text: 'Done',
          value: 4,
        },
      ]
    }
  ];


  return (
    <Layout>
      {
        !currentCustomer && <Navigate to="/mysalon/customers" replace={true} />
      }
      <Sider width={200} className="sider-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={['3']}
          style={{ height: '100%', borderRight: 0, marginTop: 30 }}
        >
          <Menu.Item key="1" onClick={() => navigate(`/mysalon/reports`)}>Report</Menu.Item>
          <Menu.Item key="2" onClick={() => navigate(`/mysalon/services`)}>Services</Menu.Item>
          <Menu.Item key="3" onClick={() => navigate(`/mysalon/customers`)}>Customers</Menu.Item>
          <Menu.Item key="4" onClick={() => navigate(`/mysalon/settings`)}>Settings</Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><HomeOutlined /> MySalon</Breadcrumb.Item>
          <Breadcrumb.Item>Customers</Breadcrumb.Item>
          <Breadcrumb.Item>Customer Detail</Breadcrumb.Item>
        </Breadcrumb>
        <div className='customer-info-page'>

          <Card style={{ marginBottom: 18 }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={19}>
                <h2>Customer Detail </h2>
              </Col>
              <Col className="gutter-row" span={5} style={{ textAlign: 'right' }}>
                <Button type="primary" icon={<EditOutlined />} onClick={() => setIsUpdateModal(true)}>Edit Customer</Button>
              </Col>
            </Row>
            <Col>

              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Customer Name: </span>
                  <span className="content">{_.get(currentCustomer, 'name')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Create Date: </span>
                  <span className="content">{moment(createDate).format('YYYY-MM-DD')}</span>
                </Col>
              </Row>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Email: </span>
                  <span className="content">{_.get(currentCustomer, 'email')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Phone: </span>
                  <span className="content">{_.get(currentCustomer, 'phoneNumber')}</span>
                </Col>
              </Row>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Birthday: </span>
                  <span className="content">{_.get(currentCustomer, 'birthday')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Gender: </span>
                  <span className="content">{_.get(currentCustomer, 'gender') === 0 ? "Male" : 'Female'}</span>
                </Col>

              </Row>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Post Code: </span>
                  <span className="content">{_.get(currentCustomer, 'location.postCode')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Prefectures: </span>
                  <span className="content">{_.get(currentCustomer, 'location.prefecture')}</span>
                </Col>
              </Row>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">City and Town: </span>
                  <span className="content">{_.get(currentCustomer, 'location.cityOrTown')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Building name/<br/>room number: </span>
                  <span className="content">{_.get(currentCustomer, 'location.address')}</span>
                </Col>
              </Row>
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Total Purchase: </span>
                  <span className="content">{_.get(currentCustomer, 'totalPurchase')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Ranking: </span>
                  <span className="content">{_.get(currentCustomer, 'ranking')}</span>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                <Col span={12}>
                  <span className="title">Last Visit Date: </span>
                  <span className="content">{moment(updateDate).format('YYYY-MM-DD')}</span>
                </Col>
                <Col span={12}>
                  <span className="title">Status: </span>
                  <span className="content">{_.get(currentCustomer, 'active') === true ? "Active" : "Inactive"}</span>
                </Col>
              </Row>
            </Col>

          </Card>
          <Card style={{ marginBottom: 24 }}>
            <Content style={{}}>
              <Row gutter={24} type="flex" justify="space-between">
                <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                  <Form layout="inline" onSubmit={() => { }}>
                    <Form.Item>
                      <Input
                        name="keyword"
                        placeholder="Search Booking ID, Customer, Staff"
                        onChange={e => setKeyword(e.target.value)}
                        style={{ width: 200 }}
                      />
                    </Form.Item>
                    <Form.Item>
                      <RangePicker placeholder={['From date', 'To date']}
                        onChange={value => setDates(value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" onClickCapture={onSubmitSearch} icon={<SearchOutlined />}>Search</Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col lg={{ span: 4 }} xs={{ span: 24 }} style={{ textAlign: 'right', marginTop: 8 }}>
                  <Statistic title="Booking Count" value={totalPage} />
                </Col>
              </Row>

              <Table
                loading={loading}
                bordered
                columns={columnsBookings}
                dataSource={dataSource}
                onChange={(pagination, filters, sorter) => {
                  if (filters.status !== null) {
                    setFiterStatus(filters.status[0])
                  } else {
                    setFiterStatus(null)
                  }

                  setSortedInfo(sorter);
                  setCurrentPage(pagination.current);
                }
                }
                pagination={{
                  total: totalPage,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  pageSize: pageSize,
                  onShowSizeChange: (current, size) => {
                    SetPageSize(size)
                  },
                  current: currentPage
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      //let staffId = _.get(record, 'id');
                      //navigate(`/mysalon/staff-member/${staffId}`);
                    }
                  };
                }}
              />

            </Content>
          </Card>
        </div>

        <Modal
          visible={isUpdateModal}
          title="Update Customer"
          onCancel={() => setIsUpdateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <CustomerUpdateForm
            onCancel={() => setIsUpdateModal(false)}
            customerDetail={currentCustomer}
            onCallback={(currentCustomer) => {
              dispatch(customerDetail(id))
                .then(result => {
                  setCurrentCustomer(result.data)
                })
                .catch(err => console.log(err))

              setIsUpdateModal(false)
            }}
          
          />
        </Modal>
      </Layout >
    </Layout >
  )
};

export default CustomerInfo;