import React from 'react';
import { Outlet } from 'react-router-dom';

const HomeLayout = () => {
  return (
    <div style={{ position: 'fixed', height: '100%', width: '100%', top: 0 }}>
      <Outlet />
    </div>
  )
};



export default HomeLayout;