import React from 'react';
import { Form, Input, Col, Row, DatePicker, Radio, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { customerUpdate } from 'providers/CustomerProvider/actions';
import { getAddressByPostCode } from 'providers/AuthProvider/actions';
import './styles.less';


const CustomerUpdateForm = ({ onCancel, customerDetail,onCallback}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let birthday = _.get(customerDetail, 'birthday');
  const id = _.get(customerDetail, 'id');
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Form
      form={form}
      {...layout}
      onFinish={values =>
      {
        if (values.postCode !== '' && values.postCode !== undefined) {
          dispatch(getAddressByPostCode({ postCode: values.postCode }))
            .then(result => {
              if (result.data.cityOrTown) {
                values = {
                  ...values,
                  cityOrTown: result.data.cityOrTown
                }
              }

              if (result.data.prefecture) {
                values = {
                  ...values,
                  prefecture: result.data.prefecture
                }
              }

              dispatch(customerUpdate({ values, id }))
                .then(() => {
                  onCallback()
                }).catch((error) => {
                  console.log('Oops!', error)
                })
            })
        }
        else {
          dispatch(customerUpdate({ values, id }))
            .then(() => {
              onCallback()
            }).catch((error) => {
              console.log('Oops!', error)
            })
        }
      }}

         
      initialValues={{
        name: _.has(customerDetail, 'name') ? customerDetail.name : '',
        email: _.has(customerDetail, 'email') ? customerDetail.email : '',
        phone: _.has(customerDetail, 'phoneNumber') ? customerDetail.phoneNumber : '',
        gender: _.has(customerDetail, 'gender') ? customerDetail.gender : '',
        birthday: _.has(customerDetail, 'birthday') ? (birthday === null ? '' : moment(birthday)) : '',
        active: _.has(customerDetail, 'active') ? customerDetail.active : '',
        postCode: _.get(customerDetail, 'location.postCode'),
        prefecture: _.get(customerDetail, 'location.prefecture'),
        cityOrTown: _.get(customerDetail, 'location.cityOrTown'),
        address: _.get(customerDetail, 'location.address'),
      }}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            label="Customer Name"
            rules={[
              {
                required: true,
                message: 'Please input the Customer Name!'
              }
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Form.Item name="gender" label="Gender">
            <Radio.Group>
              <Radio value={0}>Male</Radio>
              <Radio value={1}>Female</Radio>
            </Radio.Group>
        </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="phone"
            label="Phone"
          >
            <Input
              placeholder="Phone number"
              style={{ width: '100%' }}
              disabled="true"

            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "The input is not valid Email !"
              }
            ]}>
            <Input placeholder={"Email"} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="birthday"
            label="Birthday"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) {
                    const currentYear = new moment();
                    const ageOfBirth = currentYear.year() - value.year();
                    const monthOfBirth = (currentYear.month() + 1) - (value.month() + 1);
                    const dayOfBirth = currentYear.date() - value.date();

                    if ((ageOfBirth === 18 && ((monthOfBirth > 0) || ((monthOfBirth) === 0) && dayOfBirth >= 0)) || (ageOfBirth > 18)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The Birthday must be greater than 18 years!"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker      
              popupStyle={{ transform: 'scale(1, 0.97)' }}
              showToday={false}
              style={{ width: '100%' }}
              placeholder="YYYY-MM-DD"
            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="postCode"
            label="Post Code"
            validateTrigger={'onBlur'}
            rules={[
              {
                min: 1,
                message: "Please input more than 1 characters"
              },
              {
                max: 7,
                message: "Please input less than 7 characters"
              }
            ]}
          >
            <Input
              onKeyPress={(e) => {
                const key = e.which || e.key;
                if (e.target.value.length < 7) {
                  if (key < 48 || key > 57)
                    e.preventDefault();
                } else e.preventDefault();
              }}
              onBlur={e => {
                if (e.target.value !== '' && e.target.value !== undefined) {
                  dispatch(getAddressByPostCode({ postCode: e.target.value }))
                    .then(result => {
                      if (result.data.cityOrTown) {
                        form.setFieldsValue({ cityOrTown: result.data.cityOrTown })
                      }

                      if (result.data.prefecture) {
                        form.setFieldsValue({ prefecture: result.data.prefecture })
                      }
                    })
                    .catch(error => {
                      form.setFieldsValue({ cityOrTown: '' })
                      form.setFieldsValue({ prefecture: '' })
                      if (error.data.length > 0) {
                        let msg = '';
                        error.data.forEach((item) => {
                          msg = `${msg} ${item.message}. `
                        })
                        form.setFields([
                          {
                            name: 'postCode',
                            errors: [msg],
                          },
                        ]);

                      } else {
                        form.setFields([
                          {
                            name: 'postCode',
                            errors: [error.message],
                          },
                        ]);
                      }
                    })
                }
                else if (e.target.value === '') {
                  form.setFieldsValue({ cityOrTown: '' })
                  form.setFieldsValue({ prefecture: '' })
                }
              }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="prefecture"
            label="Prefectures"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="cityOrTown"
            label="City and Town"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="address"
            className="two-line-label"
            label={<span>Building name/<br/> room number</span>}
          >
            <Input />
          </Form.Item>
        </Col>
        {/* <Col lg={{ span: 12 }} xs={{ span: 24 }}>

          <Form.Item
            name="active"
            label="Status"
            valuePropName="checked"
          >
            <Checkbox>
              Active
            </Checkbox>

          </Form.Item>
        </Col> */}
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
              </Button>
            <Button type="primary" htmlType="submit">
              Save
              </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default CustomerUpdateForm;