import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Welcome": "Hello",
      "My Salon": "My Salon",
      "Bookings": "Bookings",
      "Payment": "Payment",
      "Staff": "Staff",
      "Report": "Report",
      "Profile": "Profile",
      "Change Password": "Change Password",
      "Logout": "Logout",
      "Services": "Services",
      "Customers": "Customers",
      "Settings": "Settings",
      "Staff Schedule": "Staff Schedule",
      "Publish Schedule": "Publish schedule",
      "Staff Member": "Staff Member",
      "Staff Overview": "Staff Overview",
      "Staff name": "Staff name",
      "Email": "Email",
      "Phone": "Phone",
      "Status": "Status",
      "Gender": "Gender",
      "Prefectures": "Prefectures",
      "City": "City",
      "Area": "Area",
      "Address": "Address",
      "Password": "Password",
      "Message (optional)": "Message (optional)",
      "Birthday": "Birthday",
      "Add date": "Add date",
      "Add Staff": "Add Staff",
      "Active": "Active",
      "Inactive": "Inactive",
      "Persional Info": "Persional Info",
      "Banking Account": "Banking Account",
      "Branch": "Branch",
      "Bank name": "Bank name",
      "Branch name": "Branch name",
      "Account type": "Account type",
      "Account number": "Account number",
      "Account name": "Account name",
    }
  },
  ja: {
    translation: {
      "Welcome": "こんにちは",
      "My Salon": "マイサロン",
      "Bookings": "予約",
      "Payment": "支払い",
      "Staff": "スタッフ",
      "Report": "報告書",
      "Profile": "プロフィール",
      "Change Password": "パスワードを変更する",
      "Logout": "ログアウト",
      "Services": "サービス",
      "Customers": "お客様",
      "Settings": "設定",
      "Staff Schedule": "スタッフスケジュール",
      "Publish Schedule": "公開スケジュール",
      "Staff Member": "スタッフメンバー",
      "Staff Overview": "スタッフ概要",
      "Staff name": "スタッフ名",
      "Email": "Eメール",
      "Phone": "電話",
      "Status": "状態",
      "Gender": "性別",
      "Prefectures": "都道府県",
      "City": "市",
      "Area": "範囲",
      "Address": "住所",
      "Password": "パスワード",
      "Message (optional)": "メッセージ（オプション）",
      "Birthday": "お誕生日",
      "Add date": "日付を追加",
      "Add Staff": "スタッフを追加",
      "Active": "アクティブ",
      "Inactive": "非活性",
      "Persional Info": "個人情報",
      "Banking Account": "銀行口座",
      "Branch": "ブランチ",
      "Bank name": "銀行名",
      "Branch name": "支店名",
      "Account type": "口座の種類",
      "Account number": "口座番号",
      "Account name": "アカウント名"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;