
import {
  SALON_DETAIL,
  SALON_DETAIL_SUCCESS,
  SALON_DETAIL_FAIL,
  SALON_ACCOUNT_UPDATE,
  SALON_ACCOUNT_UPDATE_FAIL,
  SALON_ACCOUNT_UPDATE_SUCCESS,
  SALON_BANK_UPDATE,
  SALON_BANK_UPDATE_SUCCESS,
  SALON_BANK_UPDATE_FAIL,
  SALON_GET_BANK,
  SALON_GET_BANK_SUCCESS,
  SALON_GET_BANK_FAIL,
  SALON_GET_BANK_BRANCH,
  SALON_GET_BANK_BRANCH_SUCCESS,
  SALON_GET_BANK_BRANCH_FAIL,
  SALON_SCHEDULE_UPDATE,
  SALON_SCHEDULE_UPDATE_FAIL,
  SALON_SCHEDULE_UPDATE_SUCCESS
} from './types';

const INITIAL_STATE = {
  salon: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SALON_DETAIL_SUCCESS: 
      return {
        ...state,
        salon: action.payload.data,
      };
    case SALON_DETAIL: 
    case SALON_DETAIL_FAIL:
    case SALON_BANK_UPDATE:
    case SALON_BANK_UPDATE_SUCCESS:
    case SALON_BANK_UPDATE_FAIL:
    case SALON_GET_BANK:
    case SALON_GET_BANK_SUCCESS:
    case SALON_GET_BANK_FAIL:
    case SALON_GET_BANK_BRANCH:
    case SALON_GET_BANK_BRANCH_SUCCESS:
    case SALON_GET_BANK_BRANCH_FAIL:
    case SALON_ACCOUNT_UPDATE:
    case SALON_ACCOUNT_UPDATE_FAIL:
    case SALON_ACCOUNT_UPDATE_SUCCESS:
    case SALON_SCHEDULE_UPDATE:
    case SALON_SCHEDULE_UPDATE_FAIL:
    case SALON_SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};
