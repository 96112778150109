import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import './styles.less';
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const onFinish = values => {
    console.log('Received values of form: ', values);
    navigate(`/mysalon`)
  };

  return (
    <div className="forgot-page">
        <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="forgot-page-row" >
          <Col xs={{ span: 24 }}>
            <div className="forgot-logo">
              <h3 className="forgot-title">Forgot password</h3>
            </div>
            <div>
              <Form
                  name="forgotForm"
                  className="forgot-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your Email!' }]}
                  >
                    <Input prefix={<MailOutlined />} placeholder="Email" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="forgot-form-button">
                      Request new password
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <a className="forgot-form-forgot" href="/login">
                      Back to login
                    </a>
                  </Form.Item>
                </Form>
            </div>
          </Col>
        </Row>
    </div>
  );
};

export default ForgotPassword;