import React from 'react';

import { Layout, Breadcrumb, Collapse, Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import MenuSliderLeft from 'components/Desktop/Salon/MenuSliderLeft';

import './styles.less';

const { Panel } = Collapse;

const Settings = () => {
  const { salon } = useSelector(state => state.salon);

  return (
    <Layout>
      
      <MenuSliderLeft salon={salon} menuItem="4" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>My Salon</Breadcrumb.Item>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
        </Breadcrumb>
        <div className='settings-page'>
          <h2>Settings</h2>
          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
            <Col span={12}>
              {/* <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Staff commissions</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/" className="title">Staff levels</a><br />
                    <span className="description">Assign staff levels.</span>
                  </p>
                  <Divider />
                  <p><a href="/" className="title">Level commissions</a><br />
                    <span className="description">Set the commission for staff levels.</span>
                  </p>
                  <Divider />
                  <p><a href="/" className="title">Payout cycle</a><br />
                    <span className="description">Set the payout cycle.</span>
                  </p>
                </Panel>
              </Collapse>

              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Customer ranking</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/" className="title">Ranking range</a><br />
                    <span className="description">Total purchase range for ranking.</span>
                  </p>
                </Panel>
              </Collapse> */}

              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Salon</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/mysalon/settings/salon-account" className="title">Account</a><br />
                    <span className="description">Manage your Salon account.</span>
                  </p>
                  {/* <Divider />
                  <p><a href="/" className="title">Branches</a><br />
                    <span className="description">Manage Salon branches and business hour.</span>
                  </p> */}
                </Panel>
              </Collapse>

            </Col>

            {/* <Col span={12}>
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Tax and Discount type</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/" className="title">Taxes</a><br />
                    <span className="description">Set the tax name and percentage rate.</span>
                  </p>
                  <Divider />
                  <p><a href="/" className="title">Discount types</a><br />
                    <span className="description">Create and manage discount type.</span>
                  </p>
                </Panel>
              </Collapse>

              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Login roles</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/" className="title">User login</a><br />
                    <span className="description">Create user and role.</span>
                  </p>
                  <Divider />
                  <p><a href="/" className="title">Login permissions</a><br />
                    <span className="description">Set the user permission to use the feature.</span>
                  </p>
                </Panel>
              </Collapse>

              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="site-collapse-custom-collapse"
              >
                <Panel header={(<span style={{fontWeight: 600}}>Themes</span>)} key="1" className="site-collapse-custom-panel">
                  <p><a href="/" className="title">Themes</a><br />
                    <span className="description">Change the layout styles.</span>
                  </p>
                </Panel>
              </Collapse>
            </Col> */}
          </Row>
        </div>
      </Layout>
    </Layout>
  )
};

export default Settings;