
export function formatWorkTime(open, close) {
  let openTime = String(open || (open === 0 && '0000') || '');
  let closeTime = String(close || (close === 0 && '0000') || '');

  if (!openTime && !closeTime) {
    return 'Closed';
  }

  openTime = openTime && (openTime.slice(0, 2) + ':' + openTime.slice(2));
  closeTime = closeTime && (closeTime.slice(0, 2) + ':' + closeTime.slice(2));

  return `${openTime} ~ ${closeTime}`;
}

const JPCurrency = {
  style: 'currency',
  currency: 'JPY'
};
export function formatYen(input) {
  return Number(input).toLocaleString('jp-JP', JPCurrency);
}

export function formatPercentage(number) {
  return Number(number).toFixed(2) * 100;
}