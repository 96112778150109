export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

//FETCH AREAS
export const FETCH_AREAS = 'FETCH_AREAS';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAIL = 'FETCH_AREAS_FAIL';

//FETCH PREFECTURES
export const FETCH_PREFECTURES = 'FETCH_PREFECTURES';
export const FETCH_PREFECTURES_SUCCESS = 'FETCH_PREFECTURES_SUCCESS';
export const FETCH_PREFECTURES_FAIL = 'FETCH_PREFECTURES_FAIL';

//FETCH CITIES
export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAIL = 'FETCH_CITY_FAIL';

export const REQUESR_ACCOUNT_SALON = 'REQUESR_ACCOUNT_SALON';
export const REQUESR_ACCOUNT_SALON_SUCCESS = 'REQUESR_ACCOUNT_SALON_SUCCESS';
export const REQUESR_ACCOUNT_SALON_FAIL = 'REQUESR_ACCOUNT_SALON_FAIL';

export const COMPLETED_PROFILE_SALON = 'COMPLETED_PROFILE_SALON';
export const COMPLETED_PROFILE_SALON_SUCCESS = 'COMPLETED_PROFILE_SALON_SUCCESS';
export const COMPLETED_PROFILE_SALON_FAIL = 'COMPLETED_PROFILE_SALON_FAIL';

export const GET_ADDRESS_BY_POST_CODE = 'GET_ADDRESS_BY_POST_CODE';
export const GET_ADDRESS_BY_POST_CODE_SUCCESS = 'GET_ADDRESS_BY_POST_CODE_SUCCESS';
export const GET_ADDRESS_BY_POST_CODE_FAIL = 'GET_ADDRESS_BY_POST_CODE_FAIL';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export const UPDATE_SCHEDULES_SALON = 'UPDATE_SCHEDULES_SALON';
export const UPDATE_SCHEDULES_SALON_SUCCESS = 'UPDATE_SCHEDULES_SALON_SUCCESS';
export const UPDATE_SCHEDULES_SALON_FAIL = 'UPDATE_SCHEDULES_SALON_FAIL';