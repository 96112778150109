import React from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Affix } from 'antd';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;

const MenuSiderLeft = ({menuItem}) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    
  return (
    <Affix offsetTop={66}>
      <Sider style={{ backgroundColor: '#fff', minHeight: document.documentElement.clientHeight }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[menuItem]}
          style={{ height: '100%', borderRight: 0, marginTop: 30 }}
        >
          <Menu.Item key="1" onClick={() => navigate(`/mysalon/staff-member`)}>{t('Staff Member')}</Menu.Item>
          <Menu.Item key="2" onClick={() => navigate(`/mysalon/staff-schedule`)}>{t('Staff Schedule')}</Menu.Item>
          <Menu.Item key="3" onClick={() => navigate(`/mysalon/staff-overview`)}>{t('Staff Overview')}</Menu.Item>
        </Menu>
      </Sider>
    </Affix>
    );
};

export default MenuSiderLeft;

