import React from 'react';
import { Form, Input, Col, Row, Button, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { categoryUpdate } from 'providers/CategoryProvider/actions';

const CategoryEditForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    category = {}
  } = useSelector((state) => state.category);

  return (
    <Form
        form={form}
        layout="vertical"
        name="categoryEditForm"
        onFinish={values => 
          dispatch(categoryUpdate({values, categoryId: category?.id})).then(()=> onCancel())
        }
        initialValues={category}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Category Name" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!'
                }
              ]}
              >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col lg={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item 
              name="order" 
              label="Order" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!'
                }
              ]}
              >
              <Input />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="description" 
              label="Category Description" 
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item name="active" label="Status" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default CategoryEditForm;