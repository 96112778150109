import {

  paymentCompletionFail,
  paymentCompletionSuccess,
  paymentDetailFail,
  paymentDetailSuccess,
  paymentListFail, paymentListSuccess, paymentRefundFail, paymentRefundSuccess, paymentSummaryFail, paymentSummarySuccess, paymentTipFail, paymentTipSuccess
} from './actions';
import {
  PAYMENT_COMPLETION,
  PAYMENT_DETAIL,
  PAYMENT_LIST, PAYMENT_REFUND, PAYMENT_SUMMARY, PAYMENT_TIPS
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

import _, { result } from 'lodash';

function* paymentList(action) {

  const { order = 'desc', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  try {
    let systemId = '';
    if (action.payload.systemId) {
      action.payload.systemId.forEach((item) => {
        systemId = `${item},${systemId}`
      })
    }

    let status = '';
    if (action.payload.status) {
      action.payload.status.forEach((item) => {
        status = `${item},${status}`
      })
    }

    const { data } = yield call(request, environment.api.payments,
      {
        page: action.payload.currentPage || 1,
        limit: action.payload.pageSize || 100000,
        sort: `${columnKey === 'createdAt' ? `createdAt ${order.replace("end", "")} ` : `name ${order.replace("end", "")}`}`,
        systemId: systemId.substr(0, systemId.length - 1),
        from: action.payload?.from,
        to: action.payload?.to,
        status: status.substr(0, status.length - 1),
        keyword: action.payload.searchValue

      }, 'GET');
    yield put(paymentListSuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentListFail('Happen erros'))
  }
}

function* paymentSummary(action) {

  try {
    const { data } = yield call(request, environment.api.paymentSummary,
      {
        from: action.payload?.from,
        to: action.payload?.to,
      }, 'GET');
    yield put(paymentSummarySuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentSummaryFail('Happen erros'))
  }
}

function* paymentCompletion(action) {
  try {
    const { id } = action.payload;
    yield call(request, `${environment.api.paymentCompletion}/${id}/completion`, 'POST');
    yield put(paymentCompletionSuccess({}, action.meta));
    Alert.success('The booking is charged!');
  } catch (error) {
    yield put(paymentCompletionFail('Happen erros'))
  }
}
function* paymentDetail(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentDetail}/${id}`, {}, 'GET');
    yield put(paymentDetailSuccess({ data }, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(paymentDetailFail('Happen erros'))
  }
}

function* paymentRefund(action) {
  try {
    const { values } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentRefund}/${values.paymentId}/refund`, {
      notes: values.notes,
      noteCategory: values.noteCategory
    }, 'POST');
    yield put(paymentRefundSuccess({ data }, action.meta));
    Alert.success('The booking is Refunded!');
  } catch (error) {
    yield put(paymentRefundFail('Happen erros'));
    Alert.error('The booking has been Refunded!');
  }
}

function* paymentTips(action) {
  console.log("function*paymentTips -> action", action)
  try {
    const { paymentId } = action.payload;
    const { data } = yield call(request, `${environment.api.paymentTips}/${paymentId}/tips`,
      {
        "tips": action.payload.tips
      }, 'PUT');
    yield put(paymentTipSuccess({ data }, action.meta));
  } catch (error) {
    // Alert.error("Please remove the tips value before adding new one!");
    yield put(paymentTipFail({}, action.meta));
  }
}


export default function* watchPayments() {
  yield takeEvery(PAYMENT_LIST, paymentList);
  yield takeEvery(PAYMENT_SUMMARY, paymentSummary);
  yield takeLeading(PAYMENT_COMPLETION, paymentCompletion);
  yield takeLeading(PAYMENT_DETAIL, paymentDetail);
  yield takeLeading(PAYMENT_REFUND, paymentRefund);
  yield takeLeading(PAYMENT_TIPS, paymentTips);

}