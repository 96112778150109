import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Col, Row, Select, InputNumber, Checkbox } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { serviceUpdate } from 'providers/ServiceProvider/actions';

const { Option } = Select;

const ServiceUpdateForm = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    service = {}
  } = useSelector((state) => state.service);
  const {
    categories = {}
  } = useSelector((state) => state.category);

  const [disabled, setDisabled] = useState(false)

  console.log('service: ', service)
  console.log('categories: ', categories)

  useEffect(() => {
    let cat = categories.find(item => item.id === service.categoryId)
    if (cat?.active === false) {
      setDisabled(true)
    }
  }, []);

  return (
    <Form
        form={form}
        layout="vertical"
        name="serviceUpdateForm"
        onFinish={values => 
          dispatch(serviceUpdate(
            {
              id: service.id, 
              values,
            })).then(()=> onCancel())
        }
        initialValues={service}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="categoryId"
              label="Service Category"
              rules={[{ required: true, message: 'Please select a Service Category!' }]}
            >
              <Select
                defaultValue={service.categoryId}
                showSearch
                placeholder="Service Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={
                  (value) => {
                    let cat = categories.find(item => item.id === value)
                    if (cat?.active === false) {
                      setDisabled(true)
                      form.setFields([
                        {
                          name: 'active',
                          value: false
                        },
                      ]);
                    } else {
                      setDisabled(false)
                    }
                  }
                }
              >
                {categories.map((item) => {
                  return (
                    <Option value={item.id}>{item.name}</Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Service Name" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Service Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="duration"
              label="Duration (min)"
              rules={[{ required: true, message: 'Please select a Duration!' }]}
            >
              <Select
                showSearch
                placeholder="Duration"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={30}>30</Option>
                <Option value={40}>40</Option>
                <Option value={50}>50</Option>
                <Option value={60}>60</Option>
                <Option value={70}>70</Option>
                <Option value={80}>80</Option>
                <Option value={90}>90</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="price" 
              label="Price" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Price!'
                }
              ]}>
              <InputNumber min={0} max={999999} style={{width:'100%'}} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item name="description" label="Description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item name="active" label="Status" valuePropName="checked">
              {disabled === true ?
                <Checkbox disabled>Active</Checkbox>
                :
                <Checkbox>Active</Checkbox>
              }
              
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default ServiceUpdateForm;