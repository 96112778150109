import {
  SALON_DETAIL,
  SALON_DETAIL_SUCCESS,
  SALON_DETAIL_FAIL,

  SALON_ACCOUNT_UPDATE,
  SALON_ACCOUNT_UPDATE_FAIL,
  SALON_ACCOUNT_UPDATE_SUCCESS,

  SALON_BANK_UPDATE,
  SALON_BANK_UPDATE_SUCCESS,
  SALON_BANK_UPDATE_FAIL,

  SALON_GET_BANK,
  SALON_GET_BANK_SUCCESS,
  SALON_GET_BANK_FAIL,

  SALON_GET_BANK_BRANCH,
  SALON_GET_BANK_BRANCH_SUCCESS,
  SALON_GET_BANK_BRANCH_FAIL,

  SALON_SCHEDULE_UPDATE,
  SALON_SCHEDULE_UPDATE_SUCCESS,
  SALON_SCHEDULE_UPDATE_FAIL

} from './types';

export function salonDetail(payload = {}) {
  return { type: SALON_DETAIL, payload, meta: { thunk: true } }
}
export function salonDetailSuccess(payload = {}, meta) {
  return { type: SALON_DETAIL_SUCCESS, payload, meta }
}
export function salonDetailFail(payload = {}, meta) {
  return { type: SALON_DETAIL_FAIL, payload, meta, error: true }
}

export function salonAccountUpdate(payload = {}) {
  return { type: SALON_ACCOUNT_UPDATE, payload, meta: { thunk: true } }
}
export function salonAccountUpdateSuccess(payload = {}, meta) {
  return { type: SALON_ACCOUNT_UPDATE_SUCCESS, payload, meta }
}
export function salonAccountUpdateFail(payload = {}, meta) {
  return { type: SALON_ACCOUNT_UPDATE_FAIL, payload, meta, error: true }
}

export function salonBankUpdate(payload = {}) {
  return { type: SALON_BANK_UPDATE, payload, meta: { thunk: true } }
}
export function salonBankUpdateSuccess(payload = {}, meta) {
  return { type: SALON_BANK_UPDATE_SUCCESS, payload, meta }
}
export function salonBankUpdateFail(payload = {}, meta) {
  return { type: SALON_BANK_UPDATE_FAIL, payload, meta, error: true }
}

export function salonGetBank(payload = {}) {
  return { type: SALON_GET_BANK, payload, meta: { thunk: true } }
}
export function salonGetBankSuccess(payload = {}, meta) {
  return { type: SALON_GET_BANK_SUCCESS, payload, meta }
}
export function salonGetBankFail(payload = {}, meta) {
  return { type: SALON_GET_BANK_FAIL, payload, meta, error: true }
}

export function salonGetBankBranch(payload = {}) {
  return { type: SALON_GET_BANK_BRANCH, payload, meta: { thunk: true } }
}
export function salonGetBankBranchSuccess(payload = {}, meta) {
  return { type: SALON_GET_BANK_BRANCH_SUCCESS, payload, meta }
}
export function salonGetBankBranchFail(payload = {}, meta) {
  return { type: SALON_GET_BANK_BRANCH_FAIL, payload, meta, error: true }
}

export function salonScheduleUpdate(payload = {}) {
  return { type: SALON_SCHEDULE_UPDATE, payload, meta: { thunk: true } }
}
export function salonScheduleUpdateSuccess(payload = {}, meta) {
  return { type: SALON_SCHEDULE_UPDATE_SUCCESS, payload, meta }
}
export function salonScheduleUpdateFail(payload = {}, meta) {
  return { type: SALON_SCHEDULE_UPDATE_FAIL, payload, meta, error: true }
}


