import {
  FETCH_STAFF_LIST,
  UPDATE_STAFF_PROFILE,
  ADD_STAFF,
  STAFF_DETAIL_CATEGORIES_SERVICES,
  STAFF_DETAIL_ADD_SERVICES,
  STAFF_SEND_INVITE,
  STAFF_CANCEL_INVITE,
  STAFF_DETAIL,
  STAFF_SCHEDULE_LIST,
  STAFF_SCHEDULE_UPDATE
} from './types';
import request from 'utils/request';
import { put, takeLeading, call } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import {
  fetchStaffListSuccess,
  updateStaffProfileSuccess,
  addStaffFail,
  addStaffSuccess,
  fetchStaffList as fetchStaffListAction,
  staffDetailCategoriesServicesSuccess,
  staffDetailCategoriesServicesFail,
  staffDetailAddServicesSuccess,
  staffDetailAddServicesFail,
  staffSendInviteFail,
  staffSendInviteSuccess,
  staffCancelInviteFail,
  staffCancelInviteSuccess,
  staffDetailSuccess,
  staffDetailFail,
  staffScheduleListFail,
  staffScheduleListSuccess,
  staffScheduleUpdateFail,
  staffScheduleUpdateSuccess,
} from './actions';
import environment from 'environment';
import { message as Alert } from 'antd';

function* fetchStaffList(action) {
  const { order = 'desc', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  const active = _.get(action.payload.filterStatus, 'active');
  const search = _.get(action.payload, 'searchValue');
  try {
    const { data } = yield call(request, environment.api.staffList,
      {
        page: action.payload.currentPage,
        limit: action.payload.pageSize,
        active: active && active.length === 1 ? active[0] : '',
        sort: `${columnKey === 'createdAt' ? `createdAt ${order.replace("end", "")} ` : `name ${order.replace("end", "")}`}`,
        keyword: search,


      }, 'GET');
    yield put(fetchStaffListSuccess({data:data},action.meta));
  } catch (error) {
    console.log('errors: ', error);
  }
}

function* staffCreate(action) {
  try {
    const { values } = action.payload;
    const { data } =yield call(request, environment.api.staffCreate,
      {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        gender: values.gender,
        birthday: !values.birthday ? undefined : moment(values.birthday).format('YYYY-MM-DD'),
        location: {
          postCode: values.postCode,
          prefecture: values.prefecture,
          cityOrTown: values.cityOrTown,
          address: values.address
        },
      }
    );

    yield put(addStaffSuccess({ data:data}, action.meta));
    Alert.success('Create successfully');
  } catch (e) {
    const errorData = _.get(e, 'data')
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);
    yield put(addStaffFail());
    console.log(e);
  }
}

function* updateStaffProfile(action) {
  try {
    const { values } = action.payload;
    const updateStaffProfile = yield call(request, `${environment.api.staffUpdate}${action.payload.id}`,
      {
        name: values.name,
        email: values.email,
        phoneNumber: values.phone,
        gender: values.gender,
        birthday: !values.birthday ? null : moment(values.birthday).format('YYYY-MM-DD'),
        active: values.active,
        location: {
          postCode: values.postCode ,
          prefecture: values.prefecture ,
          cityOrTown: values.cityOrTown ,
          address: values.address ,
        },
      }, 'PUT');
    yield put(updateStaffProfileSuccess({
      updateStaffProfile: updateStaffProfile
    }, action.meta));
    yield put(fetchStaffListAction());
    Alert.success('Update successfully');

  } catch (e) {
    const errorData = _.get(e, 'data')
    const messageError = errorData.find(error => error.message);
    const errorDetail = _.get(messageError, 'message');
    Alert.error(`${errorDetail}!`);
  }
}

function* staffDetailCategoriesServices(action) {
  try {
    const result = yield call(request, environment.api.categoryList, {}, 'GET');
    let categories = [];

    for (let i = 0; i < result.data.length; i++) {
      let services = [];
      for (let j = 0; j < result.data[i].services.length; j++) {
        const itemService = { ...result.data[i].services[j], checked: true }
        services.push(itemService)
      }

      const itemNew = { ...result.data[i], checked: true, services }
      categories.push(itemNew)
    }

    yield put(staffDetailCategoriesServicesSuccess(categories, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(staffDetailCategoriesServicesFail('Happen erros'))
  }
}

function* staffDetailAddServices(action) {
  try {
    const { staffId, services } = action.payload;
    yield call(request, `${environment.api.staffAddServices}/${staffId}/services`, { services });

    yield put(staffDetailAddServicesSuccess({}, action.meta));
    yield put(fetchStaffListAction());

    Alert.success(`Save successfully`);
  } catch (error) {
    console.log('error: ', error);
    yield put(staffDetailAddServicesFail(error.message, action.meta))
  }
}

function* staffSendInvite(action) {
  try {
    const { staffId, identity } = action.payload;
    yield call(request, `${environment.api.staffSendInvite}/${staffId}/invitation`, { identity });
    
    Alert.success('Send invitation successfully');
    yield put(staffSendInviteSuccess({}, action.meta));
  } catch (error) {
    Alert.error(error.message);
    yield put(staffSendInviteFail(error.message, action.meta));
  }
}

function* staffCancelInvite(action) {
  try {
    const { staffId } = action.payload;
    yield call(request, `${environment.api.staffCancelInvite}/${staffId}/cancellation`, {});
    
    Alert.success('Cancel the invitation successfully');
    yield put(staffCancelInviteSuccess({}, action.meta));
  } catch (error) {
    Alert.error(error.message);
    yield put(staffCancelInviteFail(error.message, action.meta));
  }
}

function* staffDetail(action) {
  const staffId = action.payload;
  try {
    const staff = yield call(request, `${environment.api.staffDetail}/${staffId}`, {}, 'GET');
    yield put(staffDetailSuccess(staff, action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(staffDetailFail(error, action.meta));
  }
}

function* staffScheduleList(action) {
  const { order = 'desc', columnKey = 'createdAt' } = action.payload.sortedInfo || {};
  const active = _.get(action.payload.filterStatus, 'active');
  const weekOfYear = _.get(action.payload, 'weekOfYear');
  console.log('saga.weekOfYear: ', weekOfYear)
  try {
    const { data } = yield call(request, environment.api.staffScheduleList,
      {
        page: action.payload.currentPage,
        limit: action.payload.pageSize,
        active: active && active.length === 1 ? active[0] : '',
        sort: `${columnKey === 'createdAt' ? `createdAt ${order.replace("end", "")} ` : `name ${order.replace("end", "")}`}`,
        weekOfYear: weekOfYear,
      }, 'GET');
    yield put(staffScheduleListSuccess({data:data},action.meta));
  } catch (error) {
    console.log('errors: ', error);
    yield put(staffScheduleListFail(error, action.meta));
  }
}

function* staffScheduleUpdate(action) {
  try {
    const { timezone, schedules, staffId } = action.payload;
    console.log('saga --> timezone, schedules, staffId: ', timezone, schedules, staffId)
    const salon = yield call(request, `${environment.api.staffScheduleUpdate}/${staffId}/schedules`,
      {
        timezone,
        schedules
      }, 'PUT');

    yield put(staffScheduleUpdateSuccess(salon, action.meta));
    
    Alert.success('Update successfully');
  } catch (error) {
    yield put(staffScheduleUpdateFail(error, action.meta));
  }
}

export default function* watchStaffList() {
  yield takeLeading(FETCH_STAFF_LIST, fetchStaffList);
  yield takeLeading(UPDATE_STAFF_PROFILE, updateStaffProfile);
  yield takeLeading(ADD_STAFF, staffCreate);
  yield takeLeading(STAFF_DETAIL_CATEGORIES_SERVICES, staffDetailCategoriesServices);
  yield takeLeading(STAFF_DETAIL_ADD_SERVICES, staffDetailAddServices);
  yield takeLeading(STAFF_SEND_INVITE, staffSendInvite);
  yield takeLeading(STAFF_CANCEL_INVITE, staffCancelInvite);
  yield takeLeading(STAFF_DETAIL, staffDetail);
  yield takeLeading(STAFF_SCHEDULE_LIST, staffScheduleList);
  yield takeLeading(STAFF_SCHEDULE_UPDATE, staffScheduleUpdate);
  
}