import React, { useState } from 'react';

import { Layout, Modal, Button, Breadcrumb, Avatar, Table, Tabs, Card, Row, Col, Tag, Divider } from 'antd';
import { EditOutlined, PictureOutlined } from '@ant-design/icons';

import _ from 'lodash';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { salonDetail } from 'providers/SalonProvider/actions';

import MenuSliderLeft from 'components/Desktop/Salon/MenuSliderLeft';
import SalonAccountUpdateForm from 'components/Desktop/Salon/SalonAccountUpdateForm';
import SalonBankUpdateForm from 'components/Desktop/Salon/SalonBankUpdateForm';
import ScheduleUpdateForm from 'components/Desktop/Salon/SalonScheduleUpdateForm';
import SalonCloseDateAddForm from 'components/Desktop/Salon/SalonCloseDateAddForm';
import SalonJapanPublicHolidaysAddForm from 'components/Desktop/Salon/SalonJapanPublicHolidaysAddForm';

import './styles.less';

const { TabPane } = Tabs;
const titleCase = (str) => {
  if (str === undefined) {
    return str;
  } else {
    return (str[0].toUpperCase() + str.slice(1, str.length))
  }
}

const closeDates = [
  {
    dates: {
      fromDate: '2020-01-02',
      toDate: '2020-01-02',
    },
    name: 'New Year Day',
    count: 1
  },
  {
    dates: {
      fromDate: '2020-01-02',
      toDate: '2020-01-05',
    },
    name: 'Woman Day',
    count: 4
  },
  {
    dates: {
      fromDate: '2020-01-09',
      toDate: '2020-01-09',
    },
    name: 'Japan Day',
    count: 1
  }
]

const SalonAccount = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState('1');

  const [isSalonAccountEditModal, setIsSalonAccountEditModal] = useState(false);
  const [isSalonBankEditModal, setIsSalonBankEditModal] = useState(false);
  const [isSalonSchedulEditModal, setIsSalonSchedulEditModal] = useState(false);
  const [isSalonCloseDateAddModal, setIsSalonCloseDateAddModal] = useState(false);
  const [isSalonJapanPublicHolidaysAdd, setIsSalonJapanPublicHolidaysAdd] = useState(false);

  const { user } = useSelector(state => state.auth);
  const { salon } = useSelector(state => state.salon);

  console.log('salon: ', salon)

  const workingTimesColumns = [
    {
      title: 'Monday',
      dataIndex: 'monday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Tuesday',
      dataIndex: 'tueday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Wednesday',
      dataIndex: 'wednesday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Thursday',
      dataIndex: 'thursday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Friday',
      dataIndex: 'friday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Saturday',
      dataIndex: 'saturday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    },
    {
      title: 'Sunday',
      dataIndex: 'sunday',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return item.map(i => {
          return (
            <span>{`${moment(i.startAt).format('HH:mm')} to ${moment(i.endAt).format('HH:mm')}`}</span>
          )
        })
      }
    }
  ];

  const closeDatesColumns = [
    {
      title: 'dates',
      dataIndex: 'dates',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        return (
          <span>{`${item.fromDate} to ${item.toDate}`}</span>
        )
      }
    },
    {
      title: 'name',
      dataIndex: 'name',
      width: '10%',
      fixed: 'left'
    },
    {
      title: 'count',
      dataIndex: 'count',
      width: '10%',
      fixed: 'left',
      render: (item) => {
        if (item > 1) {
          return (
            <span>{`${item} days`}</span>
          )
        } else {
          return (
            <span>{`${item} day`}</span>
          )
        }
        
      }
    },
    {
      title: '',
      width: '3%',
      render: (record) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button danger type="link" onClick={() => {
            //confirmServiceDelete(record.key)
          }}>Delete</Button>
          <Button type="link" 
            onClick={()=> 
              //openUpdateServiceModal(record)
              console.log('Edit')
            }>Edit</Button>
        </div>
      )
    }      
  ];

  return (
    <Layout>
      <MenuSliderLeft salon={salon} menuItem="4" />
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon">My Salon</a></Breadcrumb.Item>
          <Breadcrumb.Item><a href="/mysalon/settings">Settings</a></Breadcrumb.Item>
          <Breadcrumb.Item>Salon Account</Breadcrumb.Item>
        </Breadcrumb>
        <div className='salon-account-page'>
          <h2>Salon Account</h2>
          <Card style={{ marginBottom: 24 }}>
            <Tabs
              defaultActiveKey={tabIndex}
              activeKey={tabIndex}
              tabPosition="top"
              onChange={(key) => setTabIndex(key)}
              style={{marginTop: -20}}
            >
              <TabPane tab="Profile" key="1" >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{marginBottom: 30, marginTop: 10}}>
                  <Col span={3} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Row>
                      {(_.get(salon, 'logoUrl') === null || _.get(salon, 'logoUrl') === undefined) ?
                      <Avatar icon={<PictureOutlined />} shape="circle" size={84} style={{ marginBottom: 10 }} />
                      :
                      <Avatar src={_.get(salon, 'logoUrl')} shape="circle" size={84} style={{ marginBottom: 10 }} />
                      }
                    </Row>
                  </Col>
                  <Col span={17}>
                    <span style={{marginLeft: 10, fontWeight: 600}}>{_.get(salon, 'businessName')}</span><br />
                    <span style={{marginLeft: 10}}>{_.get(salon, 'operatorEmail')}</span><br />
                    <span style={{marginLeft: 10}}>{_.get(salon, 'operatorPhoneNumber')}</span>
                  </Col>
                  <Col span={4} style={{textAlign: 'right'}}>
                    <Button type="primary" icon={<EditOutlined />} onClick={() => setIsSalonAccountEditModal(true)}>Edit Account</Button>
                  </Col>
                </Row>
                <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <span className="title-left">Post Code: </span>
                    <span className="content">{_.get(salon, 'location.postCode')}</span>
                  </Col>
                  <Col span={12}>
                    <span className="title-right">Prefecture: </span>
                    <span className="content">{_.get(salon, 'location.prefecture')}</span>
                  </Col>
                </Row>
                <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <span className="title-left">City or Town: </span>
                    <span className="content">{_.get(salon, 'location.cityOrTown')}</span>
                  </Col>
                  <Col span={12}>
                    <span className="title-right">Building Name / Room Number: </span>
                    <span className="content">{_.get(salon, 'location.address')}</span>
                  </Col>
                </Row>
                <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <span className="title-left">Business Type: </span>
                    <span className="content">{_.get(salon, 'businessTypes') && _.get(salon, 'businessTypes').map(item => (
                      <Tag>{titleCase(item)}</Tag>
                    ))}</span>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Bank Info" key="2">
                {tabIndex === '2' && (
                  <div className="profile-dashboard">
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                      <Col span={10} style={{marginTop: 24}}>
                        <span className="title">Bank Name: </span>
                        <span className="content">{_.get(salon, 'bankInfo.bankName')}</span>
                      </Col>
                      <Col span={10} style={{marginTop: 24}}>
                        <span className="title">Branch Name: </span>
                        <span className="content">{_.get(salon, 'bankInfo.branchName')}</span>
                      </Col>
                      <Col className="gutter-row" span={4} style={{textAlign: 'right'}}>
                        <Button type="primary" icon={<EditOutlined />} onClick={() => setIsSalonBankEditModal(true)}>Edit Bank Info</Button>
                      </Col>
                    </Row>
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={10}>
                        <span className="title">Account Number: </span>
                        <span className="content">{_.get(salon, 'bankInfo.accountNumber')}</span>
                      </Col>
                      <Col span={10}>
                        <span className="title">Account Name: </span>
                        <span className="content">{_.get(salon, 'bankInfo.accountName')}</span>
                      </Col>
                    </Row>
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={10}>
                        <span className="title">Account Type:</span>
                        <span className="content">{titleCase(_.get(salon, 'bankInfo.accountType'))}</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Schedule" key="3">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{marginTop: 10}}>
                  <Col span={12}>
                    <h2>Working time</h2>
                  </Col>
                  <Col span={12} style={{textAlign: 'right'}}>
                    <Button type="primary" icon={<EditOutlined />} onClick={() => setIsSalonSchedulEditModal(true)}>Edit Working time</Button>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      columns={workingTimesColumns}
                      dataSource={[
                        {
                          monday: salon.schedules.filter(item => item.dayOfWeek === 1),
                          tueday: salon.schedules.filter(item => item.dayOfWeek === 2),
                          wednesday: salon.schedules.filter(item => item.dayOfWeek === 3),
                          thursday: salon.schedules.filter(item => item.dayOfWeek === 4),
                          friday: salon.schedules.filter(item => item.dayOfWeek === 5),
                          saturday: salon.schedules.filter(item => item.dayOfWeek === 6),
                          sunday: salon.schedules.filter(item => item.dayOfWeek === 0)
                        }
                      ]}
                      pagination={false}
                    />
                  </Col>
                </Row>
                {/* <Divider /> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{marginTop: 50}}>
                  <Col span={12}>
                    <h2>Close dates</h2>
                  </Col>
                  <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
                    <Button style={{marginRight: 8}} type="primary" ghost onClick={() => setIsSalonJapanPublicHolidaysAdd(true)}>Add Japan public holidays</Button>
                    <Button type="primary" onClick={() => setIsSalonCloseDateAddModal(true)}>Add new closed date</Button>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Table
                      columns={closeDatesColumns}
                      dataSource={closeDates}
                      showHeader={false}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>

          </Card>
        </div>

        <Modal
          visible={isSalonAccountEditModal}
          title="Edit Account Info"
          onCancel={() => setIsSalonAccountEditModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <SalonAccountUpdateForm
            salon={salon}
            onCallback={(salon) => {
              dispatch(salonDetail(_.get(user, '[https://user_metadata].salon_id')))
                .then(() => {
                  console.log('salon Account Update')
                })

              setIsSalonAccountEditModal(false)
            }}
          />
        </Modal>

        <Modal
          visible={isSalonBankEditModal}
          title="Update Bank Account Info"
          onCancel={() => setIsSalonBankEditModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <SalonBankUpdateForm
            salon={salon}
            onCancel={() => setIsSalonBankEditModal(false)}
            onCallback={(salon) => {
              dispatch(salonDetail(_.get(user, '[https://user_metadata].salon_id')))
                .then(() => {
                  console.log('salon Bank Update')
                })

              setIsSalonBankEditModal(false)
            }}
          />
        </Modal>

        <Modal
            visible={isSalonSchedulEditModal}
            title="Update Working time"
            onCancel={() => setIsSalonSchedulEditModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
          <ScheduleUpdateForm 
            salon={salon}
            onCancel={() => setIsSalonSchedulEditModal(false)}
            onCallback={(salon) => {
              dispatch(salonDetail(_.get(user, '[https://user_metadata].salon_id')))
                .then(() => {
                  console.log('salon Schedule Update')
                })

                setIsSalonSchedulEditModal(false)
            }}
          />
        </Modal>

        <Modal
            visible={isSalonCloseDateAddModal}
            title="Add new closed date"
            onCancel={() => setIsSalonCloseDateAddModal(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
          <SalonCloseDateAddForm 
            onCancel={() => setIsSalonCloseDateAddModal(false)} 
          />
        </Modal>

        <Modal
            visible={isSalonJapanPublicHolidaysAdd}
            title="Add new closed date"
            onCancel={() => setIsSalonJapanPublicHolidaysAdd(false)}
            destroyOnClose={true}
            width={980}
            centered
            footer={null}
          >  
          <SalonJapanPublicHolidaysAddForm 
            onCancel={() => setIsSalonJapanPublicHolidaysAdd(false)} 
          />
        </Modal>
        
      </Layout>
    </Layout>
  )
};

export default SalonAccount;