import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { formatYen } from 'utils/stringFormat';
import _, { set } from 'lodash';

import { Layout, Button, Breadcrumb, DatePicker, Input, Table, Menu, Dropdown } from 'antd';
import { Card, Row, Col, Form } from 'antd';
import { SearchOutlined, ArrowLeftOutlined, ExportOutlined } from '@ant-design/icons';

import './styles.less';
import Modal from 'antd/lib/modal/Modal';
import PaymentDetailForm from 'components/Desktop/Payment/PaymentDeatailForm';
import { paymentList } from 'providers/PaymentProvider/actions';
import { getApps, bookingDetail } from 'providers/BookingProvider/actions';
const defaultSort = {
  order: 'descend',
  columnKey: 'createdAt'
};

const PaymentLists = () => {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { RangePicker } = DatePicker;
  const [isViewDetail, SetIsViewDetail] = useState(false);
  const [isSearch, setIssearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [dates, setDates] = useState([]);

  const [currentPage, setCurrentPage] = useState();
  const [sortedInfo, setSortedInfo] = useState(defaultSort);
  const [filterSystem, setFilterSystem] = useState();
  const [filterStatus, setFilterStatus] = useState([-1]);
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, SetPageSize] = useState(10);
  const [total, setTotal] = useState('');
  const [systems, setSystems] = useState([]);
  const [booking, setBooking] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState([]);

  const exportFile = (
    <Menu>
      <Menu.Item key="1">Export to Excel</Menu.Item>
      <Menu.Item key="2">Export to PDF</Menu.Item>
      <Menu.Item key="3">Export to CSV </Menu.Item>
    </Menu>
  );


  useEffect(() => {
    dispatch(getApps())
      .then((result) => {
        setSystems(result.data)
      })
      .catch(err => console.log(err))
  }, []);


  const onSubmitSearch = () => {
    setLoading(true);
    let params = {};
    params['searchValue'] = _.trim(searchValue);
    if (dates && dates[0]) {
      params['from'] = moment(dates[0]).startOf('day').toISOString();
    }

    if (dates && dates[1]) {
      params['to'] = moment(dates[1]).endOf('day').toISOString();
    }

    params['page'] = 1;

    if (filterStatus?.[0] === -1|| isSearch === false ) {
      params['status'] = [];
      setFilterStatus([]);
    } else {
      params['status'] = filterStatus;
    }

    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    dispatch(paymentList(params))
      .then(result => {
        setLoading(false);
        const { data } = result.data;
        const { total } = result.data.pagination;
        setTotal(total);
        setPayments(data);

      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
    setCurrentPage(1)
    setIssearch(true);
  };


  useEffect(() => {
    let params = {};
    params['searchValue'] = _.trim(searchValue);
    if (dates && dates[0]) {
      params['from'] = moment(dates[0]).startOf('day').toISOString();
    }
    if (dates && dates[1]) {
      params['to'] = moment(dates[1]).endOf('day').toISOString();
    }
    params['isSearch'] = isSearch;
    params['page'] = currentPage;
    params['limit'] = pageSize;
    params['sortedInfo'] = sortedInfo;
    // if status default =-1 => filter status =>[1] waiting for payment
    if (filterStatus?.[0] === -1||isSearch===false) params['status'] = [1];
    else params['status'] = filterStatus;

    params['systemId'] = filterSystem;

    setLoading(true);
    dispatch(paymentList(params))
      .then(result => {
        setLoading(false);
        const { data } = result.data;
        const { total } = result.data.pagination;
        setTotal(total);
        setPayments(data);

      })
      .catch((error) => {
        setLoading(false);
        console.log('Oops!', error)
      })
  }, [currentPage, filterSystem, filterStatus, pageSize, sortedInfo.order, sortedInfo.columnKey]);


  const columns = [
    {
      title: 'Payment ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '10%',
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {moment(record.createdAt).format('HH:mm YYYY-MM-DD')}
          </div>
        );
      }
    },
    {
      title: 'Booking ID',
      dataIndex: 'bookingId',
      width: '10%'
    },
    {
      title: 'Customer name',
      dataIndex: 'customer',
      width: '10%',
      render: (record) => (
        <div>
          <span>{record.name}</span>
        </div>

      )
    },

    {
      title: 'Payment method',
      width: '10%',
      dataIndex: 'method',
      render: (record) => (
        <div>
          <span>{record.name}</span>
        </div>
      ),
      filterMultiple: true,
      filters: [
        {
          text: 'Cash',
          value: 'cash',
        },
        {
          text: 'Card',
          value: 'card',
        },
        {
          text: 'Other',
          value: 'other',
        }
      ]
    },
    {
      title: 'Target system',
      width: '10%',
      dataIndex: 'systemId',
      render: (text, record, index) => {
        if (record !== undefined) {
          let system = systems.find(item => item.id === record.systemId)
          return (
            <div>{system?.name}</div>
          );
        }
      },
      filters: _.uniqBy(systems, 'id').map(item => {
        let system = {
          text: item.name,
          value: item.id
        }

        return system;
      }),
    },
    {
      title: 'Customer pay',
      width: '10%',
      dataIndex: 'amount',
      render: (text, record, index) => {
        if (record['amount'] !== undefined) {
          return (
            <div>{formatYen(record['amount'])}</div>
          );
        }
      }
    }
  ];
  const Searchcolumns = [
    {
      title: 'Payment ID',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      key: 'id',
      width: '2%',
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '10%',
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {moment(record.createdAt).format('HH:mm YYYY-MM-DD')}
          </div>

        );
      }
    },
    {
      title: 'Booking ID',
      dataIndex: 'bookingId',
      width: '2%'
    },
    {
      title: 'Customer name',
      dataIndex: 'customer',
      width: '8%',
      render: (record) => (
        <div>
          <span>{record.name}</span>
        </div>

      )
    },

    {
      title: 'Payment method',
      width: '7%',
      dataIndex: 'method',
      render: (record) => (
        <div>
          <span>{record.name}</span>
        </div>
      ),
      filterMultiple: true,
      filters: [
        {
          text: 'Cash',
          value: 'cash',
        },
        {
          text: 'Card',
          value: 'card',
        },
        {
          text: 'Other',
          value: 'other',
        }
      ]
    },
    {
      title: 'Target system',
      width: '7%',
      dataIndex: 'systemId',
      render: (text, record, index) => {
        if (record !== undefined) {
          let system = systems.find(item => item.id === record.systemId)
          return (
            <div>{system?.name}</div>
          );
        }
      },
      filters: _.uniqBy(systems, 'id').map(item => {
        let system = {
          text: item.name,
          value: item.id
        }

        return system;
      }),
    },
    {
      title: 'Customer pay',
      width: '8%',
      dataIndex: 'amount',
      render: (text, record, index) => {
        if (record['amount'] !== undefined) {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>{formatYen(record['amount'])}</div>
          );
        }
      }
    },

    {
      title: 'Status update',
      dataIndex: 'updatedAt',
      key: 'id',
      width: '10%',
      render: (text, record, index) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {moment(record.updatedAt).format('HH:mm YYYY-MM-DD')}
          </div>

        );
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',

      render: (record) => {
        switch (record.id) {
          case 1:
            return (<div className="status-block wating">Wating for payment</div>);
            break;
          case 2:
            return (<div className="status-block succeed">Succeed</div>);
            break;
          case 3:
            return (<div className="status-block error">Error</div>);
            break;
          case 4:
            return (<div className="status-block canceled">Canceled</div>);
            break;
          case 5:
            return (<div className="status-block refunded">Refunded</div>);
            break;
          default:
            return (<div className="status-block wating">Wating for payment</div>);
        }
      },
      filters: [
        {
          text: 'Wating for payment',
          value: 1,
        },
        {
          text: 'Succceed',
          value: 2,
        },
        {
          text: 'Error',
          value: 3,
        },
        {
          text: 'Canceled',
          value: 4,
        },

        {
          text: 'Refunded',
          value: 5
        }

      ]

    }
  ];

  return (
    <Layout style={{ padding: '0 24px 24px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><a href="/mysalon">My Salon</a></Breadcrumb.Item>
        <Breadcrumb.Item>Payments</Breadcrumb.Item>
        {isSearch === true ?
          <Breadcrumb.Item> Search Result</Breadcrumb.Item>
          :
          <Breadcrumb.Item> Home</Breadcrumb.Item>
        }
      </Breadcrumb>
      <div className='payment-list-page'>
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={24}>
              {isSearch === false ?
                <h2>Payments </h2>
                :
                <h2><a onClick={() => {
                  setFilterStatus([1]);
                  setIssearch(false);
                }}><ArrowLeftOutlined /></a> Search Result</h2>
              }
            </Col>
          </Row>
          <Content>

            <Card style={{ marginBottom: 16, backgroundColor: '#F8F8F8' }}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                <Col span={20}>
                  <Row >
                    <Input
                      placeholder="Search Payment ID, BookingID, Customer name, Customer pay"
                      allowClear
                      onChange={e => setSearchValue(e.target.value)}
                      style={{ width: 450, marginRight: 8 }}
                    />
                    <RangePicker style={{ width: 250, marginRight: 8 }} placeholder={['From date', 'To date']}
                      onChange={value => setDates(value)}
                    />
                    <Button
                      type="primary"
                      onClickCapture={onSubmitSearch}
                      icon={<SearchOutlined />}
                    >
                      Search
                  </Button>
                  </Row>

                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                  {
                    isSearch &&
                    <Dropdown

                      overlay={exportFile}>
                      <Button
                        type="primary"
                        onClickCapture={onSubmitSearch}
                        icon={<ExportOutlined />}
                      >
                        Export
                  </Button>
                    </Dropdown>
                  }
                </Col>
              </Row>


            </Card>
            {isSearch === false && <div style={{ marginBottom: 12, fontSize: 16, color: 'black' }}>Wating for payment</div>}
            <Row >
              <Col span={24}>

                <Table
                  columns={isSearch == true? Searchcolumns : columns}
                  loading={loading}
                  bordered
                  dataSource={payments}
                  onChange={(pagination, filters, sorter) => {
                    if (sorter.order) {
                      setSortedInfo(sorter);
                    }
                    //Status
                    if (filters.status !== null) {
                      setFilterStatus(filters.status)
                    } else {
                      setFilterStatus(null)
                    }
                    if (filters.systemId !== null) {
                      setFilterSystem(filters.systemId)
                    } else {
                      setFilterSystem(null)
                    }
                    setCurrentPage(pagination.current);
                  }}

                  pagination={{
                    total,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                    pageSize: pageSize,
                    onShowSizeChange: (current, size) => { SetPageSize(size) },
                    current: currentPage

                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        setPaymentDetail(record);
                        console.log("PaymentLists -> record", record)
                        const { bookingId } = record;
                        dispatch(bookingDetail({ bookingId }))
                          .then(
                            result => {
                              setBooking(result.data);
                              SetIsViewDetail(true);
                          }
                          )
                      }
                    };
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Card>

      </div>
      <Modal
        visible={isViewDetail}
        title='Payment Detail'
        onCancel={() => {
          SetIsViewDetail(false)
        }}
        destroyOnClose={true}
        width={710}
        centered
        footer={null}
      >
        <PaymentDetailForm
          paymentDetail={paymentDetail}
          onCallback={() => {
            SetIsViewDetail(false);
            dispatch(paymentList({ page: 1, limit: 1000 }))
              .then(result => {
                setLoading(false);
                setIssearch(true);
                const { data } = result.data;
                const { total } = result.data.pagination;
                setTotal(total);
                setPayments(data);
              })
              .catch((error) => {
                console.log('Oops!', error)
              })
          }}
          booking={booking}
        />
      </Modal>
    </Layout>
  )
};

export default PaymentLists;