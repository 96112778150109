import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row, Divider, Card, Select, Input } from 'antd';
import { formatYen } from 'utils/stringFormat';
import PaymentBillingForm from './PaymentBillingForm';
import moment from 'moment';
import { ExclamationCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import './styles.less';
import { useDispatch } from 'react-redux';
import { paymentRefund } from 'providers/PaymentProvider/actions';

const PaymentDetailForm = ({ booking, paymentDetail, onCallback }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isOpenBillingModal, SetIsOpenBillingModal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isOpenRefundModal, setisOpenRefundModal] = useState(false);
  const [statusPayment, setStatusPayment] = useState(paymentDetail?.status?.status);
  const [notes, setNotes] = useState();
  const [noteCategory, setNoteCategory] = useState();
  const [statusRefund, setStatusRefund] = useState(paymentDetail?.status?.id);

  return (
    <Form
      form={form}
      name="paymentDetail"
      onFinish={() => {
        // onCallback()
      }}
    >
      <div className="payment-detail-form">
        <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginBottom:16,marginTop:-16}} >
            <Col span={11}>
              <div  style={{fontSize:14,fontWeight:'bold'}}>Payment info</div>
              <Row gutter={12}>
                <Col span={8} style={{ textAlign: "left", color: "#A8A8A8"}}>
                  <div>Payment ID</div>
                  <div>Booking ID</div>
                  <div>Status update</div>
                  <div>Status</div>
                </Col>
                <Col span={15} style={{ textAlign: "right"}}>
                  <div>{_.get(paymentDetail, 'id')}</div>
                  <div>{_.get(paymentDetail, 'bookingId')}</div>
                  <div>{`${moment(_.get(paymentDetail, 'updatedAt')).format('HH:mm YYYY-MM-DD')}`}</div>
                  <span className={`status-detail status-detail-${_.get(paymentDetail, 'status.id')}`}>{statusPayment}</span>
                </Col>
              </Row>
            </Col>
         
          <Col lg={{ span: 1 }} xs={{ span: 24 }}>
            <div style={{ width: 1, backgroundColor: '#f0f0f0', height: '122%', marginTop: -8 }} />
          </Col>
            <Col span={11} >
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>Customer Info</div>
              <Row gutter={12}>
              <Col span={10} style={{ textAlign: "left", color: "#A8A8A8" }}>
                  <div>Customer Name</div>
                  <div>Gender</div>
                  <div>Phone</div>
                  <div>Email</div>
                </Col>
              <Col span={14} style={{ textAlign: "left" }}>
                  <div>{_.get(paymentDetail, 'customer.name')}</div>
                  <div>{_.get(paymentDetail, 'customer.gender') === 0 ? 'Male' : 'Female'}</div>
                  <div>{_.get(paymentDetail, 'customer.phoneNumber')}</div>
                  <div>{_.get(paymentDetail, 'customer.email')}</div>
                </Col>
              </Row>
            </Col>
        </Row>
        <div style={{ height: 1, width: "100%", backgroundColor: '#D7D7D7', margin: '2px auto' }}></div>
        <div style={{fontWeight:'bold',fontSize:13, marginTop:8}}><MoneyCollectOutlined /> Cash payment</div>
        <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
          <Col span={12} style={{ textAlign: 'left', color: "#A8A8A8" }}>
            <div >Grand total</div>
            <div >Discount</div>
            <div >Tax</div>
          </Col>
          <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 13 }}>
            <div>{booking?.totalDuration}min/{formatYen(_.get(booking, 'totalPrice', ''))}</div>
            <div>{booking?.discount > 0 ? `-${formatYen(booking?.discount)}` : formatYen(0)}</div>
            <div>{formatYen(0)}</div>
          </Col>
         
        </Row >
    
        <div style={{ height: 1, width: "100%", backgroundColor: '#D7D7D7', margin: '2px auto' }}></div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <div style={{ fontWeight: 'bold' }}>Total</div>
          </Col>
          <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold' }}>
            <div>{(formatYen(_.get(booking, 'finalPrice', '')))}</div>
          </Col>
        </Row>

      
        {
          (booking?.status === 1  && booking?.cancellationFee !==0 )&&
          <> 
            <Row style={{ fontSize: 15 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} style={{ textAlign: 'left' }}>
                <div>
                  Cancellation fee ({booking?.cancellationFee == 0.5 ? "50%" : booking?.cancellationFee == 1 ? "100%" : ""})
                </div>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <div>{(formatYen(booking?.charge))}</div>
              </Col>
           
            </Row>
          </>
        }
        {
          (paymentDetail?.tipDetails) &&
          paymentDetail?.tipDetails.map((item, id) => {
            return (
              <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} style={{ textAlign: 'left' }}>
                  <div>
                    <span>Tip for {item?.staff?.name || "all staff"}</span>
                  </div>
                </Col>
                <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 13 }}>
                  <div>
                    <span>{formatYen(item?.amount)}</span>
                  </div>
                </Col>

              </Row>
            )
          })
        }

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>Customer Pay</div>
          </Col>
          <Col span={12} style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}>
            <div>{(formatYen(_.get(paymentDetail, 'amount', '')))}</div>
          </Col>
        </Row>

        {
          _.get(paymentDetail, 'notes') &&
          <Row style={{ marginTop: 8 }}>
            <Col span={12}>
              <span style={{ color: 'red', textAlign: "left", marginRight: 4 }}>
                Refund Reason:
              </span>
              <span style={{ color: 'red' }}>{_.get(paymentDetail, 'notes')}</span>
              <div> {_.get(paymentDetail, 'noteCategory')}</div>
            </Col>
          </Row>
        }
        {
          (notes || noteCategory) &&
          <Row style={{ marginTop: 8 }}>

            <Col span={12}>
              <div style={{ color: 'red', textAlign: "right", marginRight: 4 }}>
                Refund Reason:
              </div>
              <div style={{ color: 'red' }}>{notes}</div>
              <div>{noteCategory}</div>
            </Col>
          </Row>
        }
        {
          (_.get(paymentDetail, 'status.id') === 1) &&
          <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -16, marginBottom: -24, marginTop: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() =>
                SetIsOpenBillingModal(true)}
            >
              Process payment
          </Button>
          </div>
        }
        {
          (statusRefund === 2) &&
          <>
            <div style={{ height: 1, width: "107%", backgroundColor: '#D7D7D7',marginBottom:8,marginLeft:-24,marginTop:8 }}></div>
          <div style={{marginBottom:-4}} >
              <Button
                type="gost"
                style={{ color: 'red', borderColor: 'red' }}
                onClick={() => setisOpenRefundModal(true)}
              >
              Refund
          </Button>
          </div>
          </>
        }
        

      </div>
      <Modal
        visible={isOpenBillingModal}
        title='Billing'
        onCancel={() => {
          if (!isComplete) {
            Modal.confirm({
              title: 'Confirm',
              icon: <ExclamationCircleOutlined />,
              content: 'This billing  will be canceled?',
              okText: 'Sure',
              cancelText: 'No',
              onOk: () => {
                //onCallback();
                SetIsOpenBillingModal(false);
               
              }
            });
          }
          else {
            onCallback();
            SetIsOpenBillingModal(false);
          
          }

        }}
        destroyOnClose={true}
        width={830}
        centered
        footer={null}
      >
        <PaymentBillingForm
          isComplete={isComplete}
          setIsComplete={setIsComplete}
          booking={booking}
          paymentDetail={paymentDetail}
          onClose={() => SetIsOpenBillingModal(false)}
          onCallback={() => {
            onCallback()
            SetIsOpenBillingModal(false)
          }}
        />
      </Modal>
      <Modal
        visible={isOpenRefundModal}
        title='Refund reason'
        onCancel={() => {
          setisOpenRefundModal(false)
        }}
        destroyOnClose={true}
        width={480}
        centered
        footer={null}

      >
        <Form
          form={form}
          name="refundForm"
          onFinish={values => {
            values.paymentId = paymentDetail?.id;
            dispatch(paymentRefund({ values }))
              .then(result => {
                const {status}=result?.data?.status
                setStatusPayment(status);
                setNotes(result?.data?.notes);
                setNoteCategory(result?.data?.noteCategory);
                setStatusRefund();
                onCallback();
                setisOpenRefundModal(false)
            })   
          }}

        >
          <div className="refund-billing-form">
            <Form.Item
              label="Reason"
              name="notes"
              labelCol={{ span: 8, offset: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Please select refund reason!'
                }
              ]}

            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select refund reason!"
              >
                <Select.Option value={'Customer unsatisfied'}> Customer unsatisfied</Select.Option>
                <Select.Option value={'Bad design'}> Bad design</Select.Option>
                <Select.Option value={'Bad services'}> Bad services</Select.Option>
              </Select>

            </Form.Item>
            <Form.Item
              label="Note"
              name="noteCategory"
              labelCol={{ span: 8, offset: 0 }}
            >
              <Input.TextArea maxLength={200}/>
            </Form.Item>
            <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24, marginTop: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
              >
                OK
          </Button>
            </div>

          </div>
        </Form>
      </Modal>
    </Form >
  );
};

export default PaymentDetailForm;