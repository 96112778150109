import React from 'react';
import ReactDOM from 'react-dom';

import environment from 'environment';
import { Auth0Provider } from "@auth0/auth0-react";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Auth0Provider
    domain={environment.api.auth0Domain}
    clientId={environment.api.auth0ClientId}
    audience={environment.api.auth0Audience}
    redirectUri={environment.api.auth0RedirectUri}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,

  // <Auth0Provider
  //   domain="c2c-cb.us.auth0.com"
  //   clientId="q8MEhx7juknBJG1wT05VppQnbB2LCTOz"
  //   audience="https://c2c-cb.us.auth0.com/api/v2/"
  //   redirectUri={'http://localhost:3000/auth0'}
  // >
  //   <App />
  // </Auth0Provider>,
  // <Auth0Provider
  //   domain="c2c-cb.us.auth0.com"
  //   clientId="3Jttr8Yn5tA4I5e7CTZ6aMQazUt3q6M2"
  //   audience="https://c2c-cb.us.auth0.com/api/v2/"
  //   redirectUri={'https://slb-cb.scrum-dev.com/auth0'}
  //   >
  //   <App />
  // </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
