import React from 'react';
import { NavBar, Icon, List, InputItem, Button, WingBlank, WhiteSpace } from 'antd-mobile';
import './styles.less';
import logo from 'assets/images/logo-c2c.png';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'providers/AuthProvider/actions';
import 'antd-mobile/dist/antd-mobile.less'

const ForgotPasswordApp = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.auth);

  const onFinish = values => {
    dispatch(login(values))
    .then((result) => {
      navigate(`/mysalon`);
    }).catch((error) => {
      console.log('Oops!', error)
    })
  };

  return (
    <div className="forgot-app">
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          onLeftClick={() => navigate(`/login`)}
        >Forgot password</NavBar>
        <div className="forgot-logo">
          <img src={logo} alt="logo" style={{height: 46}} />
        </div>
        <div>
          <WingBlank>
            <List>
              <InputItem
                placeholder="Username or Email"
                data-seed="logId"
                autoHeight>Email</InputItem>
            </List>
            <WhiteSpace />
            <Button type="primary">Request new password</Button>
            <WhiteSpace />
            <div style={{ color: '#108ee9', textAlign: 'left' }}>
              <a href="/login">Back to login</a>
            </div>
          </WingBlank>
          </div>
    </div>
  );
};

export default ForgotPasswordApp;