import React, { useEffect, useState } from 'react';
import { NavBar, Icon, List, InputItem, Button, WingBlank, WhiteSpace } from 'antd-mobile';
import './styles.less';
import logo from 'assets/images/logo-c2c.png';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'providers/AuthProvider/actions';
import 'antd-mobile/dist/antd-mobile.less'

const LoginApp = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  
  const { loading } = useSelector(state => state.auth);

  const onLogin = () => {
    dispatch(login(values))
      .then((result) => {
        console.log('OkLogin')
        navigate(`/mysalon`);
      }).catch((error) => {
        console.log('Oops!', error)
      })
  };

  return (
    <div className="login-app">
        <NavBar mode="dark">Login</NavBar>
        <div className="login-logo">
          <img src={logo} alt="logo" style={{height: 46}} />
        </div>
        <div>
          <WingBlank>
          <List>
            <InputItem
              type="email"
              value={values.email}
              onChange={value => setValues({
                ...values, 
                email: value
              })}
              placeholder="Username or Email"
              data-seed="logId"
              autoHeight>Email</InputItem>
            <InputItem
              type="password"
              value={values.password}
              onChange={value => setValues({
                ...values, 
                password: value
              })}
              placeholder="Password"
              data-seed="logId"
              autoHeight>Password</InputItem>
          </List>
          <WhiteSpace />
          <div style={{ color: '#108ee9', textAlign: 'right' }}>
            <a href="/forgot-password">Forgot password</a>
          </div>
          <WhiteSpace />
          {loading === false ?
            <Button type="primary" onClick={()=> onLogin()}>Login</Button>
          :
            <Button type="primary" loading>Login</Button>
          }
          <WhiteSpace />
          <div style={{ color: '#108ee9', textAlign: 'center' }}>
            <a href="/request-account">Request account</a>
          </div>
          </WingBlank>
          </div>
    </div>
  );
};

export default LoginApp;