module.exports = {
  STATUS: {
    SUCCESS: 'success',
    ERROR: 'error',
    WAITING: 0,
    CONFIRM: 1,
    REJECT: -1,
    SUSPEND: -2
  },
  USER_GENDER: {
    FEMALE: 1,
    MALE: 2
  },
  TYPE: {
    PERSONAL: 'PERSONAL',
    COMPANY: 'COMPANY'
  },
  SALON_STATUS: {
    REQUESTING: 0,
    APPROVED: 1,
    INACTIVED: -1
  },
  BOOKING_STATUS: {
    REQUESTED: 'REQUESTED',
    DECLINED_REQUEST: 'DECLINED_REQUEST',
    CANCELED_REQUEST: 'CANCELED_REQUEST',
    EXPIRED: 'EXPIRED',
    DELETED: 'DELETED',
    CONFIRMED: 'CONFIRMED',
    DECLINED_RESERVATION: 'DECLINED_RESERVATION',
    CANCELED_RESERVATION: 'CANCELED_RESERVATION',
    CARD_ERROR_CANCEL: 'CARD_ERROR_CANCEL',
    WAITING_DONE: 'WAITING_DONE',
    DONE: 'DONE',
    UNDONE: 'UNDONE',
    NOVISIT: 'NOVISIT',
    NAILIST_CANCELED: 'NAILIST_CANCELED',
    NAILIE_CANCELED: 'NAILIE_CANCELED',
  }
};