import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Modal, Select } from 'antd';

import { useDispatch } from 'react-redux';
import { salonBankUpdate, salonGetBank, salonGetBankBranch } from 'providers/SalonProvider/actions';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const SalonBankUpdateForm = ({ salon, onCallback, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  const [banks, setBanks] = useState()
  const [bankBranch, setBankBranch] = useState()
  const [bankId, setBankId] = useState()

  const lang = {
    editBanking: t('Banking Account'),
    bankName: t('Bank name'),
    branchName: t('Branch name'),
    accountType: t('Account type'),
    accountNumber: t('Account number'),
    accountName: t('Account name'),
  };
  
  useEffect(() => {
    setLoading(true)
    dispatch(salonGetBank())
      .then(result => {
        console.log('banks: ', result.data)
        setBanks(result.data)

        if (salon.bankInfo.bankId) {
          dispatch(salonGetBankBranch(salon.bankInfo.bankId))
          .then(result => {
            console.log("Branch of banks: ", result)
            setBankBranch(result.data)
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      })
      .catch(err => setLoading(false))

  }, []);

  useEffect(() => {
    if (bankId) {
      dispatch(salonGetBankBranch(bankId))
      .then(result => {
        console.log("Branch of banks: ", result)
        setBankBranch(result.data)
      })
    }
    
  }, [bankId]);

  return (
    <Form
        form={form}
        layout="vertical"
        name="bankForm"
        onFinish={values => {
          dispatch(salonBankUpdate({
            ...values,
            salonId: _.get(salon, 'id')
          }))
          .then((result) => {
            onCallback(result)
          }).catch((error) => {
            console.log('Oops!', error)
          })
        }}
        onValuesChange={(_, values) => {
          if (values.business_hour_starttime && values.business_hour_endtime && values.business_hour_starttime >= values.business_hour_endtime) {
            Modal.warning({
              content: 'Business Hour - End Time must be greater than Start Time!!',
            });
          }
        }}
        initialValues={{
          bankId: _.get(salon, 'bankInfo.bankId'),
          bankNameLoading: '',
          branchId: _.get(salon, 'bankInfo.branchId'),
          branchNameLoading: '',
          accountType: _.get(salon, 'bankInfo.accountType'),
          accountNumber: _.get(salon, 'bankInfo.accountNumber'),
          accountName: _.get(salon, 'bankInfo.accountName'),
        }}
      >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        {loading === false ?
        <Form.Item
          name="bankId"
          label={lang.bankName}
          rules={[
            { 
              required: true, 
              message: 'Please select a Bank Name!' 
            }
          ]}
        >
        <Select 
            loading={loading}
            style={{ width: '100%' }}
            placeholder={lang.bankName}
            onChange={
              (value) => {
                setBankId(value)
                setBankBranch()
                form.setFields([
                  {
                    name: 'branchId',
                    value: ''
                  },
                ]);
              }
            }
          >
            {banks && banks.map((item) => <Select.Option value={item.id}>{item.bankName}</Select.Option>)}
        </Select>
      </Form.Item>
          :
          <Form.Item
          name="bankNameLoading"
          label={lang.bankName}
          rules={[
            { 
              required: true, 
            }
          ]}
        >
        <Select 
            loading={loading}
            style={{ width: '100%' }}
          >
            <Select.Option value={''}></Select.Option>
        </Select>
      </Form.Item>
          }       
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          {loading === false ?
            <Form.Item
              name="branchId"
              label={lang.branchName}
              rules={[
                { 
                  required: true, 
                  message: 'Please select a Branch Name!' 
                }
              ]}
            >
              <Select 
                style={{ width: '100%' }}
                placeholder={lang.branchName}
              >
                {bankBranch && bankBranch.map((item) => <Select.Option value={item.id}>{item.branchName}</Select.Option>)}
              </Select>
            </Form.Item>
            :
            <Form.Item
              label={lang.branchName}
              name={'branchNameLoading'}
              rules={[
                { 
                  required: true, 
                }
              ]}
            >
              <Select 
                style={{ width: '100%' }}
                loading={loading}
                placeholder={lang.branchName}
              >
                <Select.Option value={''}></Select.Option>
              </Select>
            </Form.Item>
          } 
          </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="accountType"
            label={lang.accountType}
            rules={[
              { 
                required: true, 
                message: 'Please select an Account Type!' 
              }
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder={lang.accountType}
            >
              <Select.Option value="personal">Personal</Select.Option>
              <Select.Option value="company">Company</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="accountNumber"
              label={lang.accountNumber}
              rules={[
                { 
                  required: true, 
                  message: 'Please input an Account Number!' 
                },
                {
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: "Not a valid Account Number"
                },
              ]}
            >
              <Input
                placeholder={lang.accountNumber}
                onKeyPress={(e) => {
                  const key = e.which || e.key;
                  console.error("key", key)
                  if (e.target.value.length < 7) {
                    if (key < 48 || key > 57)
                      e.preventDefault();
                  } else e.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="accountName"
            label={lang.accountName}
            rules={[
              { 
                required: true, 
                message: 'Please input an Account Name!' 
              }
            ]}
          >
            <Input
              placeholder={lang.accountName}
            />
          </Form.Item>
        </Col>
      </Row>
      
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update and Publish
              </Button>
            </Col>
          </Row>
        </div>
    </Form> 
  );
};

export default SalonBankUpdateForm;

