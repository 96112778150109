import moment from 'moment';

export const getPeriodDate = (inputDateTime) => {
    const date = moment(inputDateTime).get('date');
    let startDateTime;
    let EndDateTime;
    if (date < 16) {
      startDateTime = moment(inputDateTime).startOf('month').toDate();
      EndDateTime = moment(startDateTime).set('date', 15).endOf('day').toDate();
    } else {
      startDateTime = moment(inputDateTime).set('date', 16).startOf('day').toDate();
      EndDateTime = moment(startDateTime).endOf('month').toDate();
    }

    return [startDateTime, EndDateTime];
};

export const smartTrim = (str, length, delim, appendix) => {
  if (str.length <= length) return str;

  var trimmedStr = str.substr(0, length + delim.length);

  var lastDelimIndex = trimmedStr.lastIndexOf(delim);
  if (lastDelimIndex >= 0) trimmedStr = trimmedStr.substr(0, lastDelimIndex);

  if (trimmedStr) trimmedStr += appendix;
  return trimmedStr;
};

export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}