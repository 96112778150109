import React, { Fragment } from 'react';
import { Form, Input, Radio, DatePicker, Col, Row, Checkbox, Button } from 'antd';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import _ from 'lodash';
import { updateStaffProfile } from 'providers/StaffProvider/actions';
import { getAddressByPostCode } from 'providers/AuthProvider/actions';

const StaffUpdateForm = ({ onCancel, staffDetail, onCallback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const id = _.get(staffDetail, 'id');


  let birthday = _.get(staffDetail, 'birthday');
  const birthdayDefault = new moment();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const lang = {
    addStaff: t('Add Staff'),
    addDate: t('Add date'),
    staffName: t('Staff Name'),
    email: t('Email'),
    phone: t('Phone'),
    address: t('Address'),
    status: t('Status'),
    active: t('Active'),
    inActive: t('InActive'),
    gender: t('Gender'),
    area: t('Area'),
    prefectures: t('Prefectures'),
    city: t('City'),
    birthday: t('Birthday'),
  };


  return (

    <Fragment>
      {
        !staffDetail && <Navigate to="/mysalon/staff-member" replace={true} />
      }
      <Form
        form={form}
        {...layout}
        name="staffEditForm"
        onFinish={values => {
          if (values.postCode !== '' && values.postCode !== undefined) {
            dispatch(getAddressByPostCode({ postCode: values.postCode }))
              .then(result => {
                if (result.data.cityOrTown) {
                  values = {
                    ...values,
                    cityOrTown: result.data.cityOrTown
                  }
                }

                if (result.data.prefecture) {
                  values = {
                    ...values,
                    prefecture: result.data.prefecture
                  }
                }

                dispatch(updateStaffProfile({ values, id }))
                  .then(() => {
                    onCallback()
                  }).catch((error) => {
                    console.log('Oops!', error)
                  })
              })
          }
          else {
            dispatch(updateStaffProfile({ values, id }))
              .then(() => {
                onCallback()
              }).catch((error) => {
                console.log('Oops!', error)
              })
          }
        }}

        initialValues={{
          name: _.has(staffDetail, 'name') ? staffDetail.name : '',
          email: _.has(staffDetail, 'email') ? staffDetail.email : '',
          phone: _.has(staffDetail, 'phoneNumber') ? staffDetail.phoneNumber : '',
          gender: _.has(staffDetail, 'gender') ? staffDetail.gender : '',
          birthday: _.has(staffDetail, 'birthday') ? (birthday === null ? '' : moment(birthday)) : undefined,
          postCode: _.get(staffDetail,'location.postCode'),
          prefecture: _.get(staffDetail, 'location.prefecture'),
          cityOrTown: _.get(staffDetail, 'location.cityOrTown'),
          address: _.get(staffDetail, 'location.address'),
          active: _.has(staffDetail, 'active') ? staffDetail.active : ''
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label={lang.staffName}
              rules={[{ required: true, message: 'Please input your Staff name!' }]}
            >
              <Input placeholder={lang.staffName} autoFocus={true} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="gender"
              label={lang.gender}
            >
              <Radio.Group >
                <Radio value={0}>Male</Radio>
                <Radio value={1}>Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!'
                },
                {
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: "Not a valid Phone Number"
                },
                {
                  min: 10,
                  message: "Please type 10 or 11 numbers. Don't include (-)"
                }
              ]}
            >
              <Input
                placeholder="Phone number"
                style={{ width: '100%' }}
                onKeyPress={(e) => {
                  const key = e.which || e.keyCode;
                  if (e.target.value.length < 11) {
                    if (key < 48 || key > 57)
                      e.preventDefault();
                  } else e.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label={lang.email}
            >
              <Input placeholder={lang.email} disabled={true} />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="birthday"
              label={lang.birthday}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      const currentYear = new moment();
                      const ageOfBirth = currentYear.year() - value.year();
                      const monthOfBirth = (currentYear.month() + 1) - (value.month() + 1);
                      const dayOfBirth = currentYear.date() - value.date();

                      if ((ageOfBirth === 18 && ((monthOfBirth > 0) || ((monthOfBirth) === 0) && dayOfBirth >= 0)) || (ageOfBirth > 18)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The Birthday must be greater than 18 years!"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                popupStyle={{ transform: 'scale(1, 0.97)'}}
                showToday={false}
                style={{ width: '100%' }}
                placeholder="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="postCode"
              label="Post Code"
              validateTrigger={'onBlur'}
              rules={[
                {
                  min: 1,
                  message: "Please input more than 1 characters"
                },
                {
                  max: 7,
                  message: "Please input less than 7 characters"
                }
              ]}
            >
              <Input
                onKeyPress={(e) => {
                  const key = e.which || e.key;
                  if (e.target.value.length < 7) {
                    if (key < 48 || key > 57)
                      e.preventDefault();
                  } else e.preventDefault();
                }}
                onBlur={e => {
                  if (e.target.value !== '' && e.target.value !== undefined) {
                    dispatch(getAddressByPostCode({ postCode: e.target.value }))
                      .then(result => {
                        if (result.data.cityOrTown) {
                          form.setFieldsValue({ cityOrTown: result.data.cityOrTown })
                        }

                        if (result.data.prefecture) {
                          form.setFieldsValue({ prefecture: result.data.prefecture })
                        }
                      })
                      .catch(error => {
                        form.setFieldsValue({ cityOrTown: '' })
                        form.setFieldsValue({ prefecture: '' })
                        if (error.data.length > 0) {
                          let msg = '';
                          error.data.forEach((item) => {
                            msg = `${msg} ${item.message}. `
                          })
                          form.setFields([
                            {
                              name: 'postCode',
                              errors: [msg],
                            },
                          ]);

                        } else {
                          form.setFields([
                            {
                              name: 'postCode',
                              errors: [error.message],
                            },
                          ]);
                        }
                      })
                  }
                  if (e.target.value === '') {
                    form.setFieldsValue({ cityOrTown: '' })
                    form.setFieldsValue({ prefecture: '' })
                  }
                }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="prefecture"
              label="Prefectures"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="cityOrTown"
              label="City and Town"
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="address"
              className="two-line-label"
              label={<span>Building name/<br /> room number</span>}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={{ span: 12 }} xs={{ span: 24 }}>

            <Form.Item
              name="active"
              label={lang.status}
              valuePropName="checked"
            >
              <Checkbox>Active</Checkbox>

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">

        </Row>
        <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel}>
                Cancel
          </Button>
              <Button type="primary" htmlType="submit">
                Save
          </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Fragment>
  );
};

export default StaffUpdateForm;

