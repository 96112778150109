import {
  bookingGetStaffsByServiceFail,
  bookingGetStaffsByServiceSuccess,
  bookingListSuccess,
  bookingDetailFail,
  bookingDetailSuccess,
  bookingListFail,
  bookingCreateSuccess,
  bookingCreateFail,
  bookingUpdateSuccess,
  bookingUpdateFail,
  bookingCancelSuccess,
  bookingCancelFail,
  quickPaymentSuccess,
  quickPaymentFail,
  getAppsSuccess,
  getAppsFail,
  bookingTipsSuccess,
  bookingTipsFail,
  bookingDeleteTipsSuccess,
  bookingDeleteTipsFail,
  getPaymentByBookingIdSuccess,
  getPaymentByBookingIdFail,
} from './actions';
import {
  BOOKING_GET_STAFFS_BY_SERVICE,
  BOOKING_LIST,
  BOOKING_CREATE,
  BOOKING_UPDATE,
  BOOKING_DETAIL,
  BOOKING_CANCEL,
  QUICK_PAYMENT,
  GET_APPS,
  BOOKING_TIPS,
  BOOKING_DELETE_TIPS,
  GET_PAYMENT_BYBOOKINGID,
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';
import moment from 'moment'

function* bookingDetail(action) {
  try {
    const { bookingId } = action.payload;
    const booking = yield call(request, `${environment.api.getBooking}/${bookingId}`, {}, 'GET');

    yield put(bookingDetailSuccess(booking, action.meta));
  } catch (error) {
    yield put(bookingDetailFail(error.message, action.meta));
  }
}

function* bookingGetStaffsByService(action) {
  try {
    const { serviceId, startTime } = action.payload;
    const staffs = yield call(request, environment.api.getBookingStaff, { serviceId, startTime }, 'GET');

    yield put(bookingGetStaffsByServiceSuccess(staffs.data, action.meta));
  } catch (error) {
    yield put(bookingGetStaffsByServiceFail('Please check your account and login again later', action.meta));
  }
}

function* bookingList(action) {
  const { order = 'desc', columnKey = 'createdAt' } = action.payload.sortedInfo || {}
  try {
    let status = '';
    if (action.payload.status) {
      action.payload.status.forEach((item) => {
        status = `${item},${status}`
      })
    }

    let systemId = '';
    if (action.payload.systemId) {
      action.payload.systemId.forEach((item) => {
        systemId = `${item},${systemId}`
      })
    }

    console.log('from: ', action.payload?.from)
    console.log('to: ', action.payload?.to)

    const data = yield call(request, environment.api.getBookings,
      {
        page: action.payload.page,
        limit: action.payload.limit,
        status: status.substr(0, status.length - 1),
        systemId: systemId.substr(0, systemId.length - 1),
        from: action.payload?.from,
        to: action.payload?.to,
        sort: `startTime ${order.replace("end", "")}`,
        keyword: action.payload.keyword
      }, 'GET');

    yield put(bookingListSuccess({
      bookings: data.data,
    }));
  } catch (error) {
    yield put(bookingListFail());
  }
}

function* bookingCreate(action) {
  try {
    const { values, discount, removeKey } = action.payload;
    // console.log('bookingCreate.values: ', values)
    // console.log('bookingCreate.services: ', values.services.map((item, index) => {
    //   if (removeKey.includes(index) === false) {
    //     return ({
    //       ...item,
    //       startTime: moment(`${values.bookingDate.format("YYYY/MM/DD")} ${item.startTime.format("HH:mm")}`, "YYYY/MM/DD HH:mm").utc().format()
    //     })
    //   }
    // } 
    // ))

    const services = values.services.map((item, index) => {
      if (removeKey.includes(index) === false) {
        return ({
          ...item,
          startTime: moment(`${values.bookingDate.format("YYYY/MM/DD")} ${item.startTime.format("HH:mm")}`, "YYYY/MM/DD HH:mm").utc().format()
        })
      }
    }
    )

    //console.log('service: ', services.filter(item => item !== undefined))

    const booking = yield call(request, environment.api.bookingCreate,
      {
        ...values,
        services: services.filter(item => item !== undefined),
        discount
      });

    console.log('booking: ', booking)

    yield put(bookingCreateSuccess({}, action.meta));
    Alert.success('Create successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(bookingCreateFail());
  }
}

function* bookingUpdate(action) {
  try {
    const { booking, status, discount, totalPrice } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
      {
        ...booking,
        services: [],
        status,
        discount: discount === undefined ? 0 : discount,
        totalPrice,
      }, 'PUT');
    yield put(bookingUpdateSuccess({}, action.meta));
    Alert.success('Update successfully');

    // if (booking.systemId === undefined || booking.systemId === null) {
    //   console.log('MySalon')
    //   yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
    //   {
    //       ...booking, 
    //       services: [],
    //       status,
    //       discount: discount === undefined ? 0 : discount,
    //       totalPrice,
    //   }, 'PUT');
    //   yield put(bookingUpdateSuccess({}, action.meta));
    //   Alert.success('Update successfully');
    // } else {
    //   console.log('Nailie')
    //   yield call(request, `${environment.api.bookingSystemUpdate}/${booking.bookingId}`,
    //   {
    //       ...booking, 
    //       services: [],
    //       status,
    //       discount: discount === undefined ? 0 : discount,
    //       totalPrice,
    //   }, 'PUT');
    //   yield put(bookingUpdateSuccess({}, action.meta));
    //   Alert.warning('Update booking for Nailie is building. Pls waiting for later!');
    // }
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(bookingUpdateFail());
  }
}

function* bookingCancel(action) {
  try {
    const { bookingId, fee } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${bookingId}/cancellation`, { fee }, 'POST');

    yield put(bookingCancelSuccess({}, action.meta));
    Alert.success('Cancelled successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(bookingCancelFail());
  }
}

function* quickPayment(action) {
  try {
    const { booking, status } = action.payload;
    yield call(request, `${environment.api.bookingUpdate}/${booking.bookingId}`,
      {
        ...booking,
        services: [],
        status,
      }, 'PUT');

    yield put(quickPaymentSuccess({}, action.meta));
    // Alert.success('Payment successfully');
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(quickPaymentFail({}, action.meta));
  }
}

function* getApps(action) {
  try {
    const result = yield call(request, `${environment.api.getApps}`, {}, 'GET');
    yield put(getAppsSuccess({ data: result.data }, action.meta));
  } catch (error) {
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }

    yield put(getAppsFail({}, action.meta));
  }
}

function* bookingTips(action) {
  try {
    const { bookingId } = action.payload;
    const { data } = yield call(request, `${environment.api.bookingTips}/${bookingId}/tips`,
      {
        amount: action.payload.tip,
        staffId: action.payload.idStaff
      }, 'POST');
    yield put(bookingTipsSuccess({ data }, action.meta));
  } catch (error) {
    Alert.error("Please remove the tips value before adding new one!");
    yield put(bookingTipsFail({}, action.meta));
  }
}

function* bookingDeleteTips(action) {
  try {
    const { bookingId } = action.payload;
    const { id } = action.payload;
    const { data } = yield call(request, `${environment.api.bookingDeleteTips}/${bookingId}/tips/${id}`, {
      bookingId: action.payload.bookingId,
      tipId: action.payload.id
    }, 'DELETE');
    yield put(bookingDeleteTipsSuccess({ data }, action.meta));
  } catch (error) {
    yield put(bookingDeleteTipsFail({}, action.meta));
  }
}


function* getPaymentByBookingId(action) {
  try {
    const { bookingId } = action.payload;
    const {data}  = yield call(request, `${environment.api.getPaymentByBookingId}/${bookingId}/payments`, {
      
    }, 'GET');
    console.log("function*getPaymentByBookingId -> data", data)
    yield put(getPaymentByBookingIdSuccess({ data }, action.meta));
  } catch (error) {
    yield put(getPaymentByBookingIdFail({}, action.meta));
  }
}


export default function* watchNailist() {
  yield takeEvery(BOOKING_LIST, bookingList);
  yield takeEvery(BOOKING_GET_STAFFS_BY_SERVICE, bookingGetStaffsByService);
  yield takeLeading(BOOKING_CREATE, bookingCreate);
  yield takeLeading(BOOKING_UPDATE, bookingUpdate);
  yield takeLeading(QUICK_PAYMENT, quickPayment);
  yield takeEvery(BOOKING_DETAIL, bookingDetail);
  yield takeLeading(BOOKING_CANCEL, bookingCancel);
  yield takeLeading(GET_APPS, getApps);
  yield takeEvery(BOOKING_TIPS, bookingTips);
  yield takeEvery(BOOKING_DELETE_TIPS, bookingDeleteTips);
  yield takeLeading(GET_PAYMENT_BYBOOKINGID, getPaymentByBookingId)

}