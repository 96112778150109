import {
  loginSuccess,
  loginFail,
  logoutFail,
  logoutSuccess,
  setCurrentUser,
  fetchAreasSuccess,
  fetchCitySuccess,
  fetchPrefecturesSuccess,
  requestAccountSalonFail,
  requestAccountSalonSuccess,
  uploadImageFail,
  uploadImageSuccess,
  getAddressByPostCodeSuccess,
  getAddressByPostCodeFail,
  updateSchedulesSalonSuccess,
  updateSchedulesSalonFail
} from './actions';
import {
  LOGIN,
  LOGOUT,
  FETCH_AREAS,
  FETCH_PREFECTURES,
  FETCH_CITY,
  REQUESR_ACCOUNT_SALON,
  COMPLETED_PROFILE_SALON,
  UPLOAD_IMAGE,
  GET_ADDRESS_BY_POST_CODE,
  UPDATE_SCHEDULES_SALON
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';
import moment from 'moment';

function* login(action) {
  try {
    const { username, password } = action.payload;
    const { data } = yield call(request, environment.api.salonLogin, { username, password });

    if (!data.token) {
      return;
    }
    localStorage.setItem('__a_token', data.token.token);
    yield put(loginSuccess(data.token.token, action.meta));

    const user = yield call(request, environment.api.salonGetCurrentUser, {}, 'GET');
    console.log("function*login -> user", user)
    
    yield put(setCurrentUser(user));

    //Alert.success(`Login successfully`);
  } catch (error) {
    Alert.error(`Happen erros`);
    yield put(loginFail('Please check your account and login again later'));
  }
}

function* logout(action) {
  try {
    localStorage.removeItem('__a_token');
    yield put(logoutSuccess({}, action.meta));
  } catch (error) {
    Alert.error(`Happen erros`);
    yield put(logoutFail('Happen erros'));
  }
}
function* fetchListAreas(action) {
  try {
    const listAreas = yield call(request, environment.api.areasList, {}, 'GET');

    yield put(fetchAreasSuccess(listAreas));
  } catch (error) {
    console.log(error);
  }
}
function* fetchListPrefectures(action) {
  try {
    const listPrefectures = yield call(request, environment.api.prefecturesList, {}, 'GET');

    yield put(fetchPrefecturesSuccess(listPrefectures));
  } catch (error) {
    console.log(error);
  }
}

function* fetchListCities(action) {
  try {
    const listCities = yield call(request, environment.api.citiesList, {}, 'GET');

    yield put(fetchCitySuccess(listCities));
  } catch (error) {
    console.log(error);
  }
}

function* requesrSalonAccount(action) {
  try {
    const { businessName, email, phoneNumber } = action.payload;
    const resutl = yield call(request, environment.api.salonRequest, { businessName, email, phoneNumber });

    yield put(requestAccountSalonSuccess({}, action.meta));
    Alert.success(`Request account successfully`);
  } catch (error) {
    console.log(JSON.stringify(error,0,2))
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(requestAccountSalonFail('Please check your account and login again later'));
  }
}

function* completedProfileSalon(action) {
  try {
    const { businessName, schedules, salonId, postCode, prefecture, cityOrTown, address, logoUrl, timezone } = action.payload;
    const { working_days_is_mon,  working_days_is_tue, working_days_is_wed, working_days_is_thu, working_days_is_fri, working_days_is_sat, working_days_is_sun } = action.payload;
    const { building_types_is_nail,  building_types_is_hair, building_types_is_massage, building_types_is_spa } = action.payload;
    const { business_hour_starttime, business_hour_endtime } = action.payload;

    let businessTypes = [];
    if (building_types_is_nail === true) {
      businessTypes.push('nail')
    }

    if (building_types_is_hair === true) {
      businessTypes.push('hair')
    }

    if (building_types_is_massage === true) {
      businessTypes.push('massage')
    }

    if (building_types_is_spa === true) {
      businessTypes.push('spa')
    }

    let workingDaysUpdate = [];
    if (working_days_is_mon === true) {
      workingDaysUpdate.push('mon')
    }

    if (working_days_is_tue === true) {
      workingDaysUpdate.push('tue')
    }

    if (working_days_is_wed === true) {
      workingDaysUpdate.push('web')
    }

    if (working_days_is_thu === true) {
      workingDaysUpdate.push('thu')
    }

    if (working_days_is_fri === true) {
      workingDaysUpdate.push('fri')
    }

    if (working_days_is_sat === true) {
      workingDaysUpdate.push('sat')
    }

    if (working_days_is_sun === true) {
      workingDaysUpdate.push('sun')
    }

    yield call(request, `${environment.api.completedProfileSalon}/${salonId}`, {
      businessName,
      businessTypes,
      workingDays: workingDaysUpdate,
      location: {
        postCode,
        prefecture,
        cityOrTown,
        address
      },
      logoUrl,
      businessHour: {
        startAt: {
          hour: Math.floor(business_hour_starttime / 100),
          minute: business_hour_starttime % 100
        },
        endAt: {
          hour: Math.floor(business_hour_endtime / 100),
          minute: business_hour_endtime % 100
        }
      },
      timezone,
      schedules
    }, 'PATCH');  

    yield call(request, `${environment.api.updateScheduleSalon}/${salonId}/schedule`, {
      timezone,
      schedules
    }, 'PUT');
    
    yield put(requestAccountSalonSuccess({}, action.meta));
    Alert.success(`Save successfully`);
  } catch (error) {
    console.log(JSON.stringify(error,0,2))
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(requestAccountSalonFail('Please check your account and login again later'));
  }
}

function* uploadImage(action) {
  try {
    const {file} = action.payload;
    const signedKey = yield call(request, environment.api.uploadImage, {fileName: file.name});
    yield call(request, signedKey.data.url, file, 'PUT', {isNoHeader: true});
    yield put(uploadImageSuccess(signedKey.data.url, action.meta));
  } catch (error) {
    console.log('error: ', error);
    yield put(uploadImageFail(error, action.meta));
  }
}

function* getAddressByPostCode(action) {
  try {
    const { postCode } = action.payload;
    const result = yield call(request, environment.api.getAddressByPostCode, {postCode});

    yield put(getAddressByPostCodeSuccess(result, action.meta));
  } catch (error) {
    yield put(getAddressByPostCodeFail(error, action.meta));
  }
}

function* updateSchedulesSalon(action) {
  try {
    const { schedules, salon, timezone } = action.payload;

    console.log('updateSchedulesSalon.timezone: ', timezone)
    console.log('updateSchedulesSalon.schedules: ', schedules)
    console.log('updateSchedulesSalon.salon: ', salon)

    //Transfer From Slot to DateTime UTC String
    const result = yield call(request, `${environment.api.completedProfileSalon}/${salon.id}`, {
      timezone,
      schedules,
      businessTypes: salon.businessTypes,
      location: salon.location,
      businessName: salon.businessName,
    }, 'PATCH'); 
    console.log('result: ', result)

    yield put(updateSchedulesSalonSuccess({}, action.meta));
    Alert.success(`Update successfully`);
  } catch (error) {
    console.log(JSON.stringify(error,0,2))
    if (error.data.length > 0) {
      let msg = '';
      error.data.forEach((item) => {
        msg = `${msg} ${item.message}. `
      })
      Alert.error(msg);
    } else {
      Alert.error(error.message);
    }
    yield put(updateSchedulesSalonFail({}, action.meta));
  }
}

export default function* watchNailist() {
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(FETCH_AREAS, fetchListAreas);
  yield takeLeading(FETCH_PREFECTURES, fetchListPrefectures);
  yield takeLeading(FETCH_CITY, fetchListCities);
  yield takeLeading(REQUESR_ACCOUNT_SALON, requesrSalonAccount);
  yield takeLeading(COMPLETED_PROFILE_SALON, completedProfileSalon);
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
  yield takeEvery(GET_ADDRESS_BY_POST_CODE, getAddressByPostCode);
  yield takeEvery(UPDATE_SCHEDULES_SALON, updateSchedulesSalon);
}