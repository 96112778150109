import React from 'react';
import './styles.less';

import { Layout, Input, Button, Breadcrumb } from 'antd';
import {  Card, Row, Col, Form } from 'antd';
import { SearchOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';

import MenuSiderLeft from 'components/Desktop/Staff/MenuSliderLeft';
const { Content } = Layout;

const StaffOverview = () => {
  return (
    <Layout>
      
      <MenuSiderLeft menuItem="3" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><HomeOutlined /> Staff</Breadcrumb.Item>
          <Breadcrumb.Item>Staff Overview</Breadcrumb.Item>
        </Breadcrumb>
        <div className='staffmember-page'>
        <Card style={{marginBottom: 18}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>Staff Overview</h2>
              </Col>
              <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
                <Button type="primary" icon={<PlusOutlined />}>Thêm mới</Button>
              </Col>
            </Row>
        </Card>
        <Card style={{ marginBottom: 24 }}>
          <Content style={{}}>
            <Form layout="inline" onSubmit={() => {}}>
              <Form.Item>
                <Input
                  name="keyword"
                  placeholder="Search Booking ID, Customer, Nailist"
                  style={{ width: 200 }}
                />
              </Form.Item>
              
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />}>Search</Button>
              </Form.Item>
            </Form>
          </Content>
        </Card>
      </div>
    
      </Layout>
    </Layout>
  )
};

export default StaffOverview;