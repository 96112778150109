import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './styles.less';

import { Layout, Modal, Input, Button, Breadcrumb, Menu, Avatar, Checkbox, Select, Tabs, Card, Row, Col, Badge, Spin, Form } from 'antd';
import { EditOutlined, UploadOutlined, DownOutlined, RightOutlined, PictureOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import _, { result } from 'lodash';
import StaffUpdateForm from 'components/Desktop/Staff/StaffUpdateForm';
import { staffDetailCategoriesServices, staffDetailAddServices, staffSendInvite, staffCancelInvite, staffDetail } from 'providers/StaffProvider/actions';
import MenuSliderLeft from 'components/Desktop/Staff/MenuSliderLeft';

const { Option } = Select;
const { Sider } = Layout;
const { TabPane } = Tabs;

const StaffDetail = () => {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState('1');
  const [services, setServices] = useState([])

  const [staffIdOtherSystem, setStaffIdOtherSystem] = useState()
  const [loadingSentInvitation, setLoadingSentInvitation] = useState(false)

  const [isStaffUpdateModal, setIsStaffUpdateModal] = useState(false);

  //StaffService
  const [isCollapseCategory, setIsCollapseCategory] = useState(true);
  const [isCollapseSystemConnected, setIsCollapseSystemConnected] = useState(true);
  const [isCollapseSystemOther, setIsCollapseSystemOther] = useState(true);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState();
  const [categories, setCategories] = useState([])
  
  useEffect(() => {
    setLoading(true)
    dispatch(staffDetail(id))
    .then(result=> {
      setStaff(result.data)
      let services = result.data?.services.map(item => item.id)
      setServices(services)
      setLoading(false)
    })
    .catch(err => setLoading(false))
    
    dispatch(staffDetailCategoriesServices())
    .then(result => {
      setCategories(result)
    });
  }, []);

  const handleChangeSystem = (value) => {
    console.log(`selected ${value}`);
  }

  const systemName = (systemId) => {
    switch (systemId) {
      case "085094953333804":
        return "Nailie";
      
      default:
        return 'NA';
    }
  }

  const statusName = (status) => {
    switch (status) {
      case 0:
        return (
          <Badge count={'Waiting for confirm'} style={{ backgroundColor: '#06adef', marginLeft: 8 }} />
        )
      
      case 1:
        return (
          <Badge count={'Confirmed'} style={{ backgroundColor: '#C5EABF', marginLeft: 8 }} />
        )
      
      case 2:
        return (
          <Badge count={'Rejected'} style={{ backgroundColor: '#FF7FAC', marginLeft: 8 }} />
        )
      
      case 3:
        return (
          <Badge count={'Disconnected'} style={{ backgroundColor: '#d9d9d9', marginLeft: 8 }} />
        )

      case 4:
        return (
          <Badge count={'Expired'} style={{ backgroundColor: '#d9d9d9', marginLeft: 8 }} />
        )

      case 5:
        return (
          <Badge count={'Canceled'} style={{ backgroundColor: '#EFC4D3', marginLeft: 8 }} />
        )
      
      default:
        return (
          <Badge count={'NA'} style={{ marginLeft: 8 }} />
        )
    }
  }

  const onCancel = (staffId) => {
    dispatch(staffCancelInvite({staffId}))
    .then(() => {
      dispatch(staffDetail(staffId))
      .then(result => {
        setStaff(result.data)
      })
    })
    .catch(err => console.log('Error'))
  }

  const onResend = (staffId, identity) => {
    dispatch(staffSendInvite({staffId, identity}))
    .then(() => {
      dispatch(staffDetail(staffId))
      .then(result => {
        setStaff(result.data)
      })
    })
    .catch(err => console.log('Error'))
  }

  const statusAction = (connection) => {
    switch (connection.status) {
      case 0: //Waiting for confirm
        return (
          <Button type="primary" ghost onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: 'Do you want to cancel?',
              onOk() {
                onCancel(connection.staffId)
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>Cancel</Button>
        );
      
      case 1: //Confirmed
        return (
          <Button type="primary" ghost onClick={() => {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: 'Under Construction!',
              onOk() {
                console.log('OK');
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }}>Disconnect</Button>
        );
      
      case 2: //Rejected
        return (<></>);
      
      case 3: //Disconnected
        return (<></>);

      case 4: //Expired
        return (
          <>
            <Button type="primary" ghost onClick={() => {
              Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: 'Do you want to resend?',
                onOk() {
                  onResend(connection.staffId, connection.identity)
                },
                onCancel() {
                  console.log('Cancel');
                },
              });
            }}>Resend</Button>
          </>
        );

      case 5: //Canceled
        return (
          <></>
        );
      
      default:
        return 'NA';
    }
  }

  return (
    <Layout>
      <MenuSliderLeft menuItem="1" />

      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item><a href="/mysalon/staff-member">Staff</a></Breadcrumb.Item>
          <Breadcrumb.Item><a onClick={() => navigate(`/mysalon/staff-member`)} href>Staff Member</a></Breadcrumb.Item>
          <Breadcrumb.Item><strong>{staff?.name}</strong></Breadcrumb.Item>
        </Breadcrumb>
        <div className='staff-detail-page'>
          <Card style={{ marginBottom: 24, minHeight: 356 }}>
            {loading === false ?
            <Tabs
              defaultActiveKey={'1'}
              activeKey={tabIndex}
              tabPosition="top"
              onChange={(key) => setTabIndex(key)}
            >
              <TabPane tab="Persional Info" key="1" >
                <div className="profile-dashboard">
                    <div className="profile-content">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                        <Col className="gutter-row" span={20}>
                          <h3>Staff Profile </h3>
                        </Col>
                        <Col className="gutter-row" span={4}>
                          <Button type="primary" icon={<EditOutlined />} onClick={() => setIsStaffUpdateModal(true)}>Edit Profile</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{textAlign: 'center'}}>
                          {(staff?.avatar === null || staff?.avatar === undefined) ?
                            <Avatar src={_.get(staff, 'photo')} shape="circle" size={84} style={{ marginBottom: 10 }} />
                            :
                            <Avatar src={staff?.avatar} shape="circle" size={84} style={{ marginBottom: 10 }} />
                            }
                          {staff?.connections.filter(i => i.status === 1).length < 1 && (
                            <label htmlFor="photo" className="ant-btn" style={{ paddingTop: 5 }}><UploadOutlined /> Upload Photo</label>
                          )}
                        </Col>
                        <Col span={20}>
                          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                            <Col span={10}>
                              <span className="title">Staff Name: </span>
                              <span className="content">{staff?.name}</span>
                            </Col>
                            <Col span={14}>
                              <span className="title">Gender: </span>
                              <span className="content">{_.get(staff, 'gender') === 0 ? "Male" : "Female"} </span>
                            </Col>
                          </Row>

                          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                            <Col span={10}>
                              <span className="title">Phone: </span>
                              <span className="content">{staff?.phoneNumber}</span>
                            </Col>
                            <Col span={14}>
                              <span className="title">Email: </span>
                              <span className="content">{staff?.email}</span>
                            </Col>
                          </Row>

                          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                            <Col span={10}>
                              <span className="title">Birthday: </span>
                              <span className="content">{staff?.birthday}</span>
                            </Col>
                            <Col span={14}>
                              <span className="title">Post Code: </span>
                              <span className="content">{_.get(staff, 'location.postCode')}</span>
                            </Col>
                          </Row>

                          <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                            <Col span={10}>
                              <span className="title">Prefectures: </span>
                              <span className="content">{_.get(staff, 'location.prefecture')}</span>
                            </Col>
                            <Col span={14}>
                              <span className="title">City and Town: </span>
                              <span className="content" style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{_.get(staff, 'location.cityOrTown')}</span>
                            </Col>
                          </Row>

                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                            <Col span={10}>
                              <span className="title">Building name/<br/>room number: </span>
                              <span className="content">{_.get(staff, 'location.address')}</span>
                            </Col>
                            <Col span={14}>
                              <span className="title">Status: </span>
                              <span className="content">{_.get(staff, 'active') === true ? "Active" : "Inactive"} </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
              </TabPane>
              <TabPane tab="Banking Account" key="2">
                {tabIndex === '2' && (
                  <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>Banking Information ({id})</h3>
                      </Col>
                      <Col className="gutter-row" span={3}>
                        <Button type="primary" onClick={() => { }}>Edit Banking</Button>
                      </Col>
                    </Row>
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                      <Col span={8}>
                        <span className="title">Bank Name: </span>
                        <span className="content">株式会社み</span>
                      </Col>
                      <Col span={8}>
                        <span className="title">Bank branch: </span>
                        <span className="content">ずほ銀行</span>
                      </Col>
                      <Col span={8}>
                        <span className="title">Account Type:</span>
                        <span className="content">Company</span>
                      </Col>
                    </Row>
                    <Row className="gutter-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="start">
                      <Col span={8}>
                        <span className="title">Account Number: </span>
                        <span className="content">098753333667234</span>
                      </Col>
                      <Col span={8}>
                        <span className="title">Account Name: </span>
                        <span className="content">Hahahauajaiai</span>
                      </Col>
                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Service" key="3">
                {tabIndex === '3' && (
                  <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>Service </h3>
                      </Col>
                    </Row>
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      defaultValue={staff?.services && staff?.services.map(item => item.id)}
                      onChange={(checkedValues) => {
                        setServices(checkedValues)
                      }}
                    >
                      {categories.filter(i => i.active === true).map((category, index) => {
                        if (category?.services?.filter(i => i.active === true)?.length > 0) {
                          return (
                            <Row style={{ marginTop: 10 }} key={category.id}>
                              <Col span={24}>
                                <Row style={{ backgroundColor: '#f5f5f9', paddingTop: 20, paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
                                  <Col className="gutter-row" span={20}>
                                    <strong>{category.name}</strong>
                                  </Col>
                                  <Col className="gutter-row" span={4} style={{ textAlign: 'right' }}>
                                    {isCollapseCategory === true ?
                                      <DownOutlined onClick={() => setIsCollapseCategory(prevState => {
                                        return !prevState
                                      })} />
                                      :
                                      <RightOutlined onClick={() => setIsCollapseCategory(prevState => {
                                        return !prevState
                                      })} />
                                    }
                                  </Col>
                                </Row>
                              </Col>

                              {isCollapseCategory === true && (
                                <>
                                  {category.services.filter(i => i.active === true).map((service, index) => {
                                    if (index % 2 === 0) {
                                      return (
                                        <Col className="gutter-row" span={10} offset={2}>
                                          <Checkbox
                                            value={service.id}
                                          >
                                            {service.name}
                                          </Checkbox>
                                        </Col>
                                      )
                                    } else {
                                      return (
                                        <Col className="gutter-row" span={12}>
                                          <Checkbox
                                            value={service.id}
                                          >
                                            {service.name}
                                          </Checkbox>
                                        </Col>
                                      )
                                    }
                                  })}
                                </>
                              )}
                            </Row>
                          )
                        }
                      })}
                    </Checkbox.Group>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={3}>
                        <Button type="primary" 
                        onClick={() => {
                          Modal.confirm({
                            title: 'Confirm',
                            icon: <ExclamationCircleOutlined />,
                            content: 'The new staff services list will be apply for the Target system that you are connected. Are you sure?',
                            okText: 'Sure',
                            cancelText: 'No',
                            onOk: () => {
                              dispatch(staffDetailAddServices({
                                staffId: staff?.id,
                                services
                              }))
                              .then(() => {
                                //Refresh
                                dispatch(staffDetail(staff?.id))
                                .then(result=> {
                                  setStaff(result.data)
                                  let services = result.data?.services.map(item => item.id)
                                  setServices(services)
                                })
    
                              })

                            }
                          });
                        }}>Save</Button>
                      </Col>
                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane tab="System" key="4">
              <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>System </h3>
                        <p>Enter the Target system info to send the Salon invitation</p>
                      </Col>
                    </Row>
                    <Row style={{marginBottom: 24}}>
                      <Col span={24}>
                        <Form 
                          form={form}
                          layout="inline"
                          name="sendInvitationForm"
                          onFinish={values => {
                            setLoadingSentInvitation(true);
                            dispatch(staffSendInvite({staffId: staff.id, identity: values.systemKey}))
                              .then(() => {
                                dispatch(staffDetail(staff.id))
                                .then(result => {
                                  setStaff(result.data)
                                })
                                setLoadingSentInvitation(false)
                              })
                              .catch(err => setLoadingSentInvitation(false))
                          }}
                          initialValues={{}}
                        >
                          <Form.Item 
                            name="systemId"
                            label="Sytem"
                            rules={[
                              { 
                                required: true, 
                                message: 'Please select a system!' 
                              },
                            ]}
                          >
                            <Select style={{ width: 160 }} onChange={handleChangeSystem} placeholder="Select a system">
                              <Option value="085094953333804">Nailie</Option>
                              <Option value="hogugu" disabled>Hogugu</Option>
                              <Option value="hairlie" disabled>Hairlie</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item 
                            name="systemKey"
                            rules={[
                              { 
                                required: true, 
                                message: 'Please input a system key!' 
                              },
                            ]}
                          >
                            <Input value={staffIdOtherSystem} placeholder="System key" onChange={e => setStaffIdOtherSystem(e.target.value)} />
                          </Form.Item>
                          <Form.Item>
                            {loadingSentInvitation === false ?
                            <Button type="primary" htmlType="submit">Sent invitation</Button>
                            :
                            <Button type="primary" disabled loading >Sent invitation</Button>
                            }                   
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    {staff?.connections.filter(i => i.status === 1).map((item, index) => {
                        return (
                          <Row style={{ marginTop: 10 }} key={index}>
                            <Col span={24}>
                              <Row style={{ backgroundColor: '#f5f5f9', paddingTop: 20, paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
                                <Col className="gutter-row" span={20}>
                                  <strong>Connected ({staff?.connections.filter(i => i.status === 1).length})</strong>
                                </Col>
                                <Col className="gutter-row" span={4} style={{ textAlign: 'right' }}>
                                  {isCollapseSystemConnected === true ?
                                    <DownOutlined onClick={() => setIsCollapseSystemConnected(prevState => {
                                      return !prevState
                                    })} />
                                    :
                                    <RightOutlined onClick={() => setIsCollapseSystemConnected(prevState => {
                                      return !prevState
                                    })} />
                                  }
                                </Col>
                              </Row>
                            </Col>

                            {isCollapseSystemConnected === true && (
                              <>
                                <Col className="gutter-row" span={2} style={{textAlign: 'center'}}>
                                  <Avatar icon={<PictureOutlined />} shape="square" size={'large'} />
                                </Col>
                                <Col className="gutter-row" span={10}>
                                  <strong>{systemName(item.systemId)}</strong>
                                  {statusName(item.status)}<br />
                                  {item.identity}
                                </Col>
                                <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
                                  {statusAction(item)}
                                </Col>
                              </>
                            )}
                          </Row>
                        )
                      })}

                      {staff?.connections.filter(i => i.status !== 1).map((item, index) => {
                        return (
                          <Row style={{ marginTop: 10 }} key={index}>
                            <Col span={24}>
                              <Row style={{ backgroundColor: '#f5f5f9', paddingTop: 20, paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}>
                                <Col className="gutter-row" span={20}>
                                  <strong>Systems ({staff?.connections.filter(i => i.status !== 1).length})</strong>
                                </Col>
                                <Col className="gutter-row" span={4} style={{ textAlign: 'right' }}>
                                  {isCollapseSystemOther === true ?
                                    <DownOutlined onClick={() => setIsCollapseSystemOther(prevState => {
                                      return !prevState
                                    })} />
                                    :
                                    <RightOutlined onClick={() => setIsCollapseSystemOther(prevState => {
                                      return !prevState
                                    })} />
                                  }
                                </Col>
                              </Row>
                            </Col>

                            {isCollapseSystemOther === true && (
                              <>
                                <Col className="gutter-row" span={2} style={{textAlign: 'center'}}>
                                  <Avatar icon={<PictureOutlined />} shape="square" size={'large'} />
                                </Col>
                                <Col className="gutter-row" span={10}>
                                  <strong>{systemName(item.systemId)}</strong>
                                  {statusName(item.status)}<br />
                                  {item.identity}
                                </Col>
                                <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
                                  {statusAction(item)}
                                </Col>
                              </>
                            )}
                          </Row>
                        )
                      })}
                  </div>
              </TabPane>
              <TabPane tab="Rating" key="5">
                {tabIndex === '5' && (
                  <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>Rating </h3>
                      </Col>
                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Timesheet" key="6">
                {tabIndex === '6' && (
                  <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>Timesheet </h3>
                      </Col>

                    </Row>
                  </div>
                )}
              </TabPane>
              <TabPane tab="Salary" key="7">
                {tabIndex === '7' && (
                  <div className="profile-dashboard">
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
                      <Col className="gutter-row" span={21}>
                        <h3>Salary </h3>
                      </Col>

                    </Row>
                  </div>
                )}
              </TabPane>
            </Tabs>
            :
            <p style={{marginTop: 150, textAlign: 'center'}}>
              <Spin />
            </p>
            }
          </Card>
        </div>

        <Modal
          visible={isStaffUpdateModal}
          title="Persional Infomation"
          onCancel={() => setIsStaffUpdateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <StaffUpdateForm
            staffDetail={staff}
            onCancel={() => setIsStaffUpdateModal(false)}
            onCallback={(staff) => {
              dispatch(staffDetail(id))
                .then(result => {
                  setStaff(result.data)
                })
                .catch(err => console.log(err))

              setIsStaffUpdateModal(false)
            }}
          />
        </Modal>
      </Layout>
    </Layout>
  )
};

export default StaffDetail;