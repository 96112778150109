import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Modal } from 'antd';
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';

import { useAuth0 } from "@auth0/auth0-react";

import { useDispatch } from 'react-redux';
import { requestAccountSalon } from 'providers/AuthProvider/actions';

import './styles.less';

const RequestAccount = () => {
  const { loginWithRedirect } = useAuth0();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isRequestAccountSalonSuccess, setIsRequestAccountSalonSucces] = useState(false)

  const onFinish = values => {
    if (values.isAgreed !== true) {
      Modal.warning({
        content: 'Please check I agreed to Request Account!',
      });
    } else {
      dispatch(requestAccountSalon(values))
        .then((result) => {
         setIsRequestAccountSalonSucces(true)
        }).catch((error) => {
          console.log('Oops!', error)
        })
    }
  };

  

  return (
    <div className="request-page">
        <Row justify="space-between" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="request-page-row" >
          <Col xs={{ span: 24 }}>
            <div className="request-logo">
              <h3 className="request-title">Request Account</h3>
            </div>
            {isRequestAccountSalonSuccess === false? 
            <div>
              <Form
                  form={form}
                  onFinish={onFinish}
                  initialValues={{
                    businessName: '',
                    email: '',
                    phoneNumber: '',
                    isAgreed: true
                  }}
                >
                  <Form.Item
                    name="businessName"
                    validateTrigger={'onBlur'}
                    onKeyPress={(e) => {
                      if (e.target.value.length >= 50) {
                        e.preventDefault();
                      }
                    }}
                    rules={[
                      { 
                        required: true, 
                        message: 'Please input your Business Name!' 
                      },
                      {
                        min: 2,
                        message: "Please input more than 1 characters"
                      },
                      {
                        max: 50,
                        message: "Please input less than 50 characters"
                      }
                    ]}
                  >
                    <Input prefix={<UserOutlined />} placeholder="Business Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    validateTrigger={'onBlur'}
                    rules={[
                      {
                        required: true,
                        message: "Please input E-mail!"
                      },
                      {
                        pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                        message: "The input is not valid Email !"
                      },
                    ]}
                  >
                    <Input prefix={<MailOutlined />} placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    validateTrigger={'onBlur'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!'
                      },
                      {
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        message: "Not a valid Phone Number"
                      },
                      {
                        min: 10,
                        message: "Please type 10 or 11 numbers. Don't include (-)"
                      }
                    ]}
                  >
                    <Input prefix={<PhoneOutlined />}
                      placeholder="Phone number"
                      style={{ width: '100%' }}
                      onKeyPress={(e) => {
                        const key = e.which || e.key;
                        if (e.target.value.length < 11) {
                          if (key < 48 || key > 57)
                            e.preventDefault();
                        } else e.preventDefault();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="isAgreed"
                    valuePropName="checked"
                  >
                    <Checkbox>I agree to <a href="#">Terms of Services</a></Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="request-form-button">
                      Request Account
                    </Button> 
                  </Form.Item>
                  <Form.Item>
                    <span href="/login" style={{textAlign: 'center'}}>
                      Already have an account? <a onClick={() => loginWithRedirect()} className="login-form-button">Log in</a>
                    </span>
                  </Form.Item>
                </Form>
            </div>
            :
            <>
              <p>
                You will receive a confirmation email shortly.<br /><br />
                Please wait for the Salon System Manager to approve your request.
              </p>  
              <Form.Item>
                <a className="request-form-forgot" href="/login">
                  Back to login
                </a>
              </Form.Item>
            </>
            }
          </Col>
        </Row>
    </div>
  );
};

export default RequestAccount;