
import {
  FETCH_STAFF_LIST,
  FETCH_STAFF_LIST_SUCCESS,
  FETCH_STAFF_LIST_FAIL,
  UPDATE_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAIL,
  ADD_STAFF,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  STAFF_DETAIL_CATEGORIES_SERVICES,
  STAFF_DETAIL_CATEGORIES_SERVICES_FAIL,
  STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS,
  STAFF_DETAIL_ADD_SERVICES,
  STAFF_DETAIL_ADD_SERVICES_FAIL,
  STAFF_DETAIL_ADD_SERVICES_SUCCESS,
  STAFF_SEND_INVITE,
  STAFF_SEND_INVITE_SUCCESS,
  STAFF_SEND_INVITE_FAIL,
  STAFF_CANCEL_INVITE,
  STAFF_CANCEL_INVITE_FAIL,
  STAFF_CANCEL_INVITE_SUCCESS,
  STAFF_DETAIL,
  STAFF_DETAIL_SUCCESS,
  STAFF_DETAIL_FAIL,
  STAFF_SCHEDULE_LIST,
  STAFF_SCHEDULE_LIST_SUCCESS,
  STAFF_SCHEDULE_LIST_FAIL,
  STAFF_SCHEDULE_UPDATE,
  STAFF_SCHEDULE_UPDATE_SUCCESS,
  STAFF_SCHEDULE_UPDATE_FAIL
} from './types';
import _ from 'lodash';

const INITIAL_STATE = {
  categories: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STAFF_DETAIL: case STAFF_DETAIL_SUCCESS: case STAFF_DETAIL_FAIL:
    case ADD_STAFF: case ADD_STAFF_SUCCESS: case ADD_STAFF_FAIL:
    case FETCH_STAFF_LIST: case FETCH_STAFF_LIST_SUCCESS: case FETCH_STAFF_LIST_FAIL:
    case UPDATE_STAFF_PROFILE: case UPDATE_STAFF_PROFILE_SUCCESS: case UPDATE_STAFF_PROFILE_FAIL:
    case STAFF_SCHEDULE_LIST: case STAFF_SCHEDULE_LIST_SUCCESS: case STAFF_SCHEDULE_LIST_FAIL:
      return {
        ...state,
      };

    //STAFF DETAIL
    case STAFF_DETAIL_CATEGORIES_SERVICES:
      return {
        ...state,
        isLoading: true
      };
  
    case STAFF_DETAIL_CATEGORIES_SERVICES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        isLoading: false
      };
    case STAFF_DETAIL_CATEGORIES_SERVICES_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case STAFF_DETAIL_ADD_SERVICES:
      return {
        ...state,
        isLoading: true
      };
    
    case STAFF_DETAIL_ADD_SERVICES_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case STAFF_DETAIL_ADD_SERVICES_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case STAFF_SEND_INVITE:
      return {
        ...state,
      };
    case STAFF_SEND_INVITE_SUCCESS:
      return {
        ...state,
      };
    case STAFF_SEND_INVITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case STAFF_CANCEL_INVITE:
      return {
        ...state,
      };
    case STAFF_CANCEL_INVITE_SUCCESS:
      return {
        ...state,
      };
    case STAFF_CANCEL_INVITE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case STAFF_SCHEDULE_UPDATE:
    case STAFF_SCHEDULE_UPDATE_FAIL:
    case STAFF_SCHEDULE_UPDATE_SUCCESS:

    default:
      return state;
  }
};
