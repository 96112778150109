import {
  categoryListSuccess,
  categoryListFail,
  categoryCreateSuccess,
  categoryCreateFail,
  categoryUpdateSuccess,
  categoryUpdateFail,
  categoryOrderSuccess,
  categoryOrderFail,
  categoryDeleteSuccess,
  categoryDeleteFail,
  categoryList as categoryListAction,
} from './actions';
import {
  CATEGORY_LIST,
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
  CATEGORY_ORDER,
  CATEGORY_DELETE
} from './types';
import { put, takeLeading, call, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import environment from 'environment';
import { message as Alert } from 'antd';

function* categoryList() {
  try {
    const categories = yield call(request, environment.api.categoryList, {}, 'GET');
    //console.log('categories.saga: ', categories)
    yield put(categoryListSuccess({
      categories: categories.data,
    }));
  } catch (error) {
    console.log('error: ', error);
    yield put(categoryListFail('Happen erros'))
  }
}

function* categoryCreate(action) {
  try {
    const { values } = action.payload;
    yield call(request, environment.api.categoryCreate,values);
    yield put(categoryCreateSuccess({}, action.meta));
    yield put(categoryListAction());
    Alert.success('Create successfully');
  } catch (e) {
    Alert.error('Happen errors!');
    yield put(categoryCreateFail());
    console.log(e);
  }
}

function* categoryUpdate(action) {
  try {
    const { values, categoryId } = action.payload;
    console.log('categoryUpdate.values: ', values)
    console.log('categoryUpdate.categoryId: ', categoryId)
    yield call(request, `${environment.api.categoryUpdate}/${categoryId}`, values, 'PUT');
    yield put(categoryUpdateSuccess({}, action.meta));
    yield put(categoryListAction());
    Alert.success('Update successfully');
  } catch (e) {
    Alert.error('Happen errors!');
    yield put(categoryUpdateFail());
    console.log(e);
  }
}

function* categoryOrder(action) {
  try {
    const { oldItem, newItem } = action.payload;
    //console.log('categoryOrder.oldItem: ', oldItem)
  
    yield call(request, `${environment.api.categoryUpdate}/${oldItem.id}`, {
      description: oldItem.description,
      name: oldItem.name,
      order: newItem.order,
    }, 'PUT');

    //console.log('categoryOrder.newItem: ', newItem)
    yield call(request, `${environment.api.categoryUpdate}/${newItem.id}`, {
      description: newItem.description,
      name: newItem.name,
      order: oldItem.order,
    }, 'PUT');
    
    yield put(categoryOrderSuccess({}, action.meta));
    yield put(categoryListAction());
    //Alert.success('Order successfully');
  } catch (error) {
    Alert.error(error.message);
    yield put(categoryOrderFail());
  }
}

function* categoryDelete(action) {
  try {
    yield call(request, `${environment.api.categoryDelete}/${action.payload}`, {}, 'DELETE');
    yield put(categoryDeleteSuccess());
    yield put(categoryListAction());
    Alert.success('Delete successfully');
  } catch (error) {
    console.log(error)
    Alert.error(error.message);
    yield put(categoryDeleteFail());
  }
}

export default function* watchNailist() {
  yield takeEvery(CATEGORY_LIST, categoryList);
  yield takeLeading(CATEGORY_CREATE, categoryCreate);
  yield takeEvery(CATEGORY_UPDATE, categoryUpdate);
  yield takeEvery(CATEGORY_ORDER, categoryOrder);
  yield takeLeading(CATEGORY_DELETE, categoryDelete);
}